import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { UserDto, UserRole } from "../../store/models/user/UserDto";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import UserTableMenu from "./UserTableMenu";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getCurrentDateTimeAsString } from "../atoms/Utils";
import { getCurrentUsername } from "../../store/actions/LoginActions";
import PrintIcon from "@mui/icons-material/Print";
import { Button } from "@mui/material";
import { LightTooltip } from "../app/LightToolTip";
import { UserStatus } from "./UserStatus";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";

export interface UserRoleName {
  key: UserRole;
  value: string;
}

export const userRoleNames: UserRoleName[] = [
  { key: UserRole.SYSTEM_ADMINISTRATOR, value: "Systemadministration" },
  { key: UserRole.USER_ADMINISTRATOR, value: "Anwenderadminstration" },
  { key: UserRole.PROGRAM_ADMINISTRATOR, value: "Programmadministration" },
  { key: UserRole.PROGRAM_ANALYST, value: "Programmanalyse" },
  { key: UserRole.CUSTOMER_SERVICE_AGENT, value: "Konsumentenservice" },
  { key: UserRole.SALES_PERSON, value: "Verkaufsmitarbeiter" },
];
export const findRoleName = (userRole?: String) => {
  return userRoleNames.find((userRoleName) => userRoleName.key === userRole)?.value;
};

export const downloadPdf = (data: UserDto[], operationalUnits: OperationalUnitDto[]) => {
  return generatePdfContent(data, operationalUnits).save("admin-users.pdf");
}

export const generatePdfContent = (data: UserDto[], operationalUnits: OperationalUnitDto[]) => {
  const doc = new jsPDF();

  // Define table columns and rows
  const columns = ["Anwendername", "E-Mail", "Filiale", "Rollen", "Status", "Last Login"];
  const rows = data.map((item) => [
    item.userName ?? "-",
    item.mailAddress ?? "-",
    operationalUnits.find((operationalUnit) => operationalUnit.unitNumber === item.unitNumber)?.unitName ?? "-",
    item.userRoles.map((userRole) => findRoleName(userRole)).join("\n"),
    item.status ?? "-",
    item.lastAccess ?? "-",
  ]);

  // Add title
  doc.text("Anwender", 10, 10);

  // Add table
  autoTable(doc, {
    head: [columns],
    body: rows,
    startY: 20,
    theme: "striped",
    styles: { fontSize: 10, cellWidth: "wrap" },
    columnStyles: {
      0: { cellWidth: 30 }, // username
      1: { cellWidth: 40 }, // E-Mail
      2: { cellWidth: 25 }, // Filiale
      3: { cellWidth: 45 }, // Rollen
      4: { cellWidth: "auto" }, // Status
      5: { cellWidth: 22 }, // Last Login
    },
    tableWidth: "auto",
    rowPageBreak: "avoid",
    didDrawPage: (data) => {
      // Footer at the bottom of each page
      const pageCount = doc.getNumberOfPages();
      const pageNumber = doc.getCurrentPageInfo().pageNumber;

      const pageHeight = doc.internal.pageSize.height;
      const pageWidth = doc.internal.pageSize.width;
      doc.setFontSize(10);

      // Left footer: Generated by user and date
      doc.text(
        `Requested by ${getCurrentUsername()} at ${getCurrentDateTimeAsString()}`,
        data.settings.margin.left,
        pageHeight - 10
      );

      // Right footer: Page number
      doc.text(`Page ${pageNumber} of ${pageCount}`, pageWidth - data.settings.margin.right - 20, pageHeight - 10);
    },
  });

  // Save the PDF
  return doc;
};

const UserTable = (props: ThunkProps) => {
  const { users, operationalUnits } = props;
  const headCells: ImsHeadCell<UserDto>[] = [
    { val: (val) => val.userName, node: "Anwendername" },
    { val: (val) => val.mailAddress, node: "E-Mail" },
    { val: (val) => val.unitNumber, node: "Filiale" },
    { val: (val) => val.userRoles, node: "Rollen" },
    { val: (val) => val.status, node: "Status" },
    { val: (val) => val.lastAccess, node: "Letzte Anmeldung" },
  ];

  const rows: ImsTableRow<UserDto>[] = users.map((row) => ({
    value: row,
    nodes: [
      <b>{row.userName}</b>,
      row.mailAddress,
      operationalUnits.find((operationalUnit) => operationalUnit.unitNumber === row.unitNumber)?.unitName,
      row.userRoles.map((userRole) => <div key={userRole}>{findRoleName(userRole)}</div>),
      row.status === UserStatus.ACTIVE ? "aktiv" : "inaktiv",
      row.lastAccess ?? "-",

      <UserTableMenu user={row} />,
    ],
  }));

  return (
    <>
      <LightTooltip title="Anwenderliste ausgeben">
        <Button
          data-testid="download-admin-pdf"
          style={{ float: "right", marginBottom: "10px" }}
          onClick={() => downloadPdf(props.users, props.operationalUnits)}
        >
          <PrintIcon />
        </Button>
      </LightTooltip>
      <ImsTable hasMenu={true} headCells={headCells} rows={rows} />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const mapStateToProps = (state: IStore) => ({
  users: state.users.users,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(UserTable);
