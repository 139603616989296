import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Grid,
  Typography,
  FormLabel,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { Help } from "@mui/icons-material";
import imagePlaceholder from "../../appContent/imagePlaceholder.png";
import { Field } from "react-final-form";

interface ImageUploadProps {
  name: string;
  label: string;
  tooltipText: string;
  buttonText: string;
  processing: boolean;
}

const ImageUpload = ({ name, label, tooltipText, buttonText, processing }: ImageUploadProps) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormControl fullWidth={true} error={meta.touched && meta.error}>
          <FormLabel>
            {tooltipText ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" component="b">
                  {label}
                </Typography>
                <Tooltip title={tooltipText} placement="right-start">
                  <Help color="primary" style={{ marginLeft: 4 }} />
                </Tooltip>
              </div>
            ) : (
              <Typography variant="body1" component="b">
                {label}
              </Typography>
            )}
          </FormLabel>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Box position="relative" width="200px" height="200px" display="inline-block">
                  <img
                    src={input.value ? URL.createObjectURL(input.value) : imagePlaceholder}
                    alt={label}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                  {processing && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item>
                <Button variant="contained" component="label" color="primary">
                  {buttonText}
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(event) => {
                      if (event.target.files?.[0]) {
                        const file = event.target.files[0];
                        input.onChange(file);
                      }
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default ImageUpload;
