import { Button, Grid, Paper, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathMain } from "../../api/url";
import { IStore } from "../../store/IStore";
import MenuLayout from "../app/MenuLayout";
import { FormRadioButton } from "../atoms/FormRadioButton";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { CashbackStatus } from "../../store/models/cashback/CashbackStatus";
import { CashbackDto } from "../../store/models/cashback/CashbackDto";
import { thunkGetCashback, thunkUpdateCashback } from "../../store/actions/CashbackActions";
import { FormSelect } from "../atoms/FormSelect";
import { ValidationErrors, nameof, useMountEffect } from "../atoms/Utils";
import { CashbackCurrency } from "../../store/models/cashback/CashbackCurrency";
import CashbackRuleTable from "./CashbackRuleTable";
import { Help } from "@mui/icons-material";
import { FormInput } from "../atoms/FormInput";
import { CashbackExecutionPeriod } from "../../store/models/cashback/CashbackExecutionPeriod";
import { canMaintainCashBack, canReadCashBack } from "../../store/actions/LoginActions";

const statusOptions = [
  { value: CashbackStatus.ENABLED, label: "Aktivieren" },
  { value: CashbackStatus.DISABLED, label: "Deaktivieren" },
];

const executionOptions = [
  {
    value: CashbackExecutionPeriod.COLLECTION_PERIOD_BASED,
    label: "Sammelperioden-basiert",
  },
];

export const cashbackCurrencyOptions = [{ value: CashbackCurrency.TOTAL_REVENUE_EURO, label: "Umsatz in €" }];

interface CashbackForm extends CashbackDto {
  cashbackCirclesFlatterned?: string[];
  cashbackBonusCurrency?: string;
}

const CashbackPage = (props: ThunkProps) => {
  const [redirect, setRedirect] = useState(false);
  const theme = useTheme();

  const readOnly = !canMaintainCashBack();

  useMountEffect(() => {
    canReadCashBack() && props.thunkGetCashback();
  });

  if (!props.cashback) {
    return null;
  }

  const circleOptions = props.circles.map((circle) => ({
    value: circle.circleNumber,
    label: circle.circleName,
  }));

  const toCashBackForm = (cashbackDto: CashbackDto): CashbackForm => {
    const cashbackForm = {
      ...cashbackDto,
      cashbackCirclesFlatterned: cashbackDto.cashbackCircles.map((c) => c.circleNumber),
      cashbackBonusCurrency: "Statusbonuspunkte", // This is hard coded on purpose
      cashbackCurrency: CashbackCurrency.TOTAL_REVENUE_EURO, // This is hard coded on purpose
    };
    cashbackForm.cashbackRateRules = [...cashbackForm.cashbackRateRules];
    return cashbackForm;
  };

  const toCashbackDto = (cashbackForm: CashbackForm): CashbackDto => {
    const cashBackDto = { ...cashbackForm };
    cashBackDto.cashbackCircles =
      cashbackForm.cashbackCirclesFlatterned?.map((c) => ({
        circleNumber: c,
      })) ?? [];

    //Remove form specific data
    delete cashBackDto.cashbackCirclesFlatterned;
    delete cashBackDto.cashbackBonusCurrency;
    delete cashBackDto.validFrom;
    delete cashBackDto.validTill;
    return cashBackDto as CashbackDto;
  };

  const validateForm = (values: CashbackForm) => {
    const errors: ValidationErrors<CashbackForm> = {};
    const requiredText = "Pflichtfeld";
    if (!values.cashbackCirclesFlatterned?.length) {
      errors.cashbackCirclesFlatterned = requiredText;
    }
    if (isNaN(values.counterValue)) {
      errors.counterValue = requiredText;
    }
    return errors;
  };

  const initialValues: CashbackForm = toCashBackForm(props.cashback);

  if (redirect) {
    return <Navigate to={UrlPathMain} />;
  }

  return (
    <MenuLayout headline="Cashback Konfiguration">
      <Form
        onSubmit={async (cashback: CashbackForm) => {
          const cashbackDto = toCashbackDto(cashback);
          await props.thunkUpdateCashback(cashbackDto);
        }}
        initialValues={initialValues}
        validate={validateForm}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text="Allgemeine Einstellungen" />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item>
                      <FormRadioButton
                        label="Cashback Status"
                        name="cashbackStatus"
                        value={values.cashbackStatus}
                        options={statusOptions}
                        readOnly={readOnly}
                        row={true}
                      />
                    </Grid>
                    <Grid item xs={12} mb={4}></Grid>
                    <Grid item>
                      <FormRadioButton
                        label="Cashback Ausführungsmodus"
                        description="Definieren Sie, wann die Cashback-Berechnung für Ihre KundInnen ausgeführt werden soll."
                        name={nameof<CashbackForm>("executionPeriod")}
                        value={values.executionPeriod}
                        options={executionOptions}
                        readOnly={readOnly}
                        row={true}
                      />
                    </Grid>
                    <Grid item xs={12} mb={4}></Grid>
                    <Grid item xs={12}>
                      <strong>Circles</strong>
                    </Grid>
                    <Grid item xs={12}>
                      <p>Definieren Sie, Kunden aus welchen Circles Cashback erhalten sollen.</p>
                    </Grid>
                    <Grid item md={8}>
                      <FormSelect
                        readOnly={readOnly}
                        fullWidth
                        multiple
                        name={nameof<CashbackForm>("cashbackCirclesFlatterned")}
                        label="Circles"
                        options={circleOptions}
                        testId="circlesField"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text="Cashback-Rate ermitteln" />
                <Grid container>
                  <Grid item mt={2} mb={4}>
                    <strong>
                      Erstellen Sie Regeln, um die Cashback-Raten für die Berechnung der (voraussichtlichen)
                      Cashback-Summe zu ermitteln.
                    </strong>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={12} mb={3}>
                      <b>
                        Legen Sie die Bonuswährung fest, die für die Berechnung der Cashback-Rate berücksichtigt wird.
                      </b>
                    </Grid>
                    <Grid item md={3}>
                      <FormSelect
                        readOnly={true}
                        options={cashbackCurrencyOptions}
                        name={nameof<CashbackForm>("cashbackCurrency")}
                        label="Cashbackrate-Währung"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Tooltip
                        title={"Umsatz in € - der gesamte  Kundenumsatz innerhalb einer aktiven Sammelperiode"}
                        placement="right-start"
                        style={{ marginTop: 16, marginLeft: 10 }}
                      >
                        <Help color="primary" />
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12} mb={4}>
                      <CashbackRuleTable cashbackRateRuleList={values.cashbackRateRules} />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text="Berechnungseinstellungen" />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={12} mb={3}>
                      <b>
                        Legen Sie die Bonuswährung fest, die für die Berechnung der Cashback-Summe berücksichtigt wird.
                      </b>
                    </Grid>
                    <Grid item>
                      <FormInput
                        readOnly={true}
                        type="text"
                        name={nameof<CashbackForm>("cashbackBonusCurrency")}
                        label="Bonuswährung"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} mb={4}></Grid>
                    <Grid item md={12} mb={3}>
                      <b>
                        Definieren Sie den monetären Gegenwert in Cents für jede Einheit der gewählten Bonuswährung,
                        z.B. für jeden Rewardbonuspunkt erhält der Kunde 1ct.
                      </b>
                    </Grid>
                    <Grid item>
                      <FormInput
                        readOnly={readOnly}
                        multiline
                        type="text"
                        name={nameof<CashbackForm>("counterValue")}
                        label="Cent"
                        variant="outlined"
                        testId="counterValueField"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Abbrechen
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting || readOnly}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Speichern
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </MenuLayout>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetCashback, thunkUpdateCashback }, dispatch);

const mapStateToProps = (state: IStore) => ({
  circles: state.circles.circles,
  cashback: state.cashback.cashback,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CashbackPage);
