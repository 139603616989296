import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { CashbackDto } from "../models/cashback/CashbackDto";
import { CashbackExecutionPeriod } from "../models/cashback/CashbackExecutionPeriod";
import { CashbackStatus } from "../models/cashback/CashbackStatus";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";
import { v4 as uuid } from "uuid";

export const GET_CASHBACK = "GET_CASHBACK";
export const UPDATE_CASHBACK = "UPDATE_CASHBACK";

export const thunkGetCashback = (): BooleanThunk => async (dispatch) => {
  let cashback = {
    cashbackCurrency: "TOTAL_REVENUE_EURO",
    cashbackNumber: uuid(),
    cashbackStatus: CashbackStatus.DISABLED,
    cashbackRateRules: [],
    cashbackCircles: [],
    counterValue: 1,
    executionPeriod: CashbackExecutionPeriod.COLLECTION_PERIOD_BASED,
  } as CashbackDto;
  return Api.getCashback()
    .then((cashbackRes) => {
      dispatch({
        type: GET_CASHBACK,
        payload: cashbackRes,
      });
      return true;
    })
    .catch((e) => {
      dispatch({
        type: GET_CASHBACK,
        payload: cashback,
      });
      dispatch(thunkCreateErrorNotification("Fehler beim Laden der Cashback", e));
      return false;
    });
};
export const thunkUpdateCashback =
  (cashbackDto: CashbackDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const updatedCashback = await Api.updateCashback(cashbackDto);
      dispatch({
        type: UPDATE_CASHBACK,
        payload: updatedCashback,
      });
      dispatch(thunkCreateSuccessNotification("Cashback Konfiguration updated"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler beim Laden der Cashback", e));
      return false;
    }
  };
