import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { COLOR_GRAY_3, COLOR_GRAY_4 } from "./ImsMaterialTheme";

interface ImsOrder<T> {
  headCell: ImsHeadCell<T>;
  direction: ImsSortDirection;
}

export type ImsSortDirection = "asc" | "desc";

export interface ImsHeadCell<T> {
  node: ReactNode;
  val: (val: T) => any;
}

export interface ImsTableRow<T> {
  nodes: ReactNode[];
  value: T;
  disabled?: boolean;
}

export interface ImsTableProps<T> {
  headCells: ImsHeadCell<T>[];
  rows: ImsTableRow<T>[];
  sortColumnIndex?: number;
  sortDirection?: ImsSortDirection;
  hasMenu: boolean;
  disableSorting?: boolean;
}

export function ImsTable<T>(props: ImsTableProps<T>) {
  const { headCells, rows, sortColumnIndex, sortDirection, hasMenu, disableSorting = false } = props;
  const [order, setOrder] = useState<ImsOrder<T>>({
    headCell: headCells[sortColumnIndex || 0],
    direction: sortDirection || "asc",
  });
  const sortedRows = disableSorting ? rows : stableSort(rows, getComparator(order));

  return (
    <TableContainer component={Paper}>
      <Table>
        <ImsTableHead
          headCells={headCells}
          order={order}
          setOrder={setOrder}
          hasMenu={hasMenu}
          disableSorting={disableSorting}
        />
        <TableBody>
          {sortedRows.map((row, index) => (
            <TableRow key={index}>
              {row.nodes.map((value, index) => (
                <TableCell
                  style={row.disabled ? { color: COLOR_GRAY_4 } : {}}
                  align={getAlign(index, row.nodes.length, hasMenu)}
                  key={index}
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function getAlign(index: number, rowCount: number, hasMenu: boolean) {
  if (index === 0) return "left";
  if (hasMenu && index === rowCount - 1) return "right";
  return undefined;
}

interface ImsTableHeadProps<T> {
  headCells: ImsHeadCell<T>[];
  order: ImsOrder<T>;
  setOrder: Dispatch<SetStateAction<ImsOrder<T>>>;
  hasMenu: boolean;
  disableSorting?: boolean;
}

function ImsTableHead<T>(props: ImsTableHeadProps<T>) {
  const { headCells, order, setOrder, hasMenu, disableSorting } = props;
  return (
    <TableHead key={`TableHeadKey-${props.order}`} style={{ backgroundColor: COLOR_GRAY_3 }}>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={`TableCellKey-${index}`}
            sortDirection={order.headCell === headCell ? order.direction : false}
            align={getAlign(index, headCells.length, false)}
          >
            {disableSorting ? (
              headCell.node
            ) : (
              <TableSortLabel
                active={order.headCell === headCell}
                direction={order.headCell === headCell ? order.direction : undefined}
                style={index === 0 ? undefined : { paddingLeft: 26 }}
                onClick={() => onSortClick(order, setOrder, headCell)}
              >
                {headCell.node}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {hasMenu && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

const onSortClick = <T,>(
  order: ImsOrder<T>,
  setOrder: Dispatch<SetStateAction<ImsOrder<T>>>,
  headCell: ImsHeadCell<T>
) => {
  const flippedDirection = order.direction === "asc" ? "desc" : "asc";
  const direction = order.headCell !== headCell ? "asc" : flippedDirection;
  setOrder({
    headCell,
    direction,
  });
};

function stableSort<T>(rows: ImsTableRow<T>[], comparator: (a: T, b: T) => number): ImsTableRow<T>[] {
  const indexedRows = rows.map((row, index) => ({ row, index }));
  indexedRows.sort((a, b) => {
    const order = comparator(a.row.value, b.row.value);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return indexedRows.map((el) => el.row);
}

function getComparator<T>(order: ImsOrder<T>): (a: T, b: T) => number {
  return order.direction === "desc"
    ? (a, b) => descendingComparator(a, b, order.headCell.val)
    : (a, b) => -descendingComparator(a, b, order.headCell.val);
}

function descendingComparator<T>(a: T, b: T, val: (val: T) => any) {
  const valueOfA = typeof val(a) === "string" ? val(a).toUpperCase() : val(a);
  const valueOfB = typeof val(b) === "string" ? val(b).toUpperCase() : val(b);

  if (!valueOfA) return 1;
  if (!valueOfB) return -1;

  if (valueOfB < valueOfA) {
    return -1;
  }
  if (valueOfB > valueOfA) {
    return 1;
  }
  return 0;
}
