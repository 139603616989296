import { COLOR_GRAY_3 } from "./ImsMaterialTheme";

export const GrayLine = () => (
  <hr
    style={{
      border: 0,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: COLOR_GRAY_3,
    }}
  />
);
