import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { UserDto } from "../../store/models/user/UserDto";
import MenuLayout from "../app/MenuLayout";
import UserForm from "./UserForm";
import { UserStatus } from "./UserStatus";

const defaultUser: UserDto = {
  firstName: "",
  lastName: "",
  userRoles: [],
  status: UserStatus.ACTIVE,
};
const UserFormPage = (props: ThunkProps) => {
  const { users } = props;
  const { id } = useParams<"id">();
  const user = users.find((user) => user.userNumber === id);

  return (
    <MenuLayout headline={id ? "Anwender bearbeiten" : "Neuer Anwender"}>
      <UserForm user={user || defaultUser} />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  users: state.users.users,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(UserFormPage);
