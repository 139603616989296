import { createTheme } from "@mui/material";
import "./fonts/_fonts.scss";
import "./inputs.scss";
import "./muiTreeItems.scss";

export const KNISTR_BLUE = "#080d82";
export const KNISTR_BLACK = "#000000";
export const KNISTR_CYAN = "#75f0cf";
export const KNISTR_GREEN = "#00cd55";
export const KNISTR_YELLOW = "#f5e940";
export const KNISTR_ORANGE = "#ff8f1a";
export const KNISTR_RED = "#ff5047";
export const KNISTR_PLUM = "#f00e96";
export const KNISTR_GRAY = "#e0e0e0";

export const COLOR_WHITE = "#ffffff";
export const COLOR_BLACK = "#000000";
export const COLOR_GRAY_1 = "#eeeeee";
export const COLOR_GRAY_2 = "#666666";
export const COLOR_GRAY_3 = "#dddddd";
export const COLOR_GRAY_4 = "#999999";
export const COLOR_GRAY_5 = "#aeaeae";
export const COLOR_WARNING = "#ed6c02";
export const COLOR_INFO = "#0288d1";

export const MENU_WIDTH = 256;
export const MENU_HEADER_HEIGHT = 88;

export const ImsMaterialTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
          color: KNISTR_BLACK,
          backgroundColor: KNISTR_GRAY,
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        align: "center",
      },
      styleOverrides: {
        head: { fontSize: 16, fontWeight: "bold" },
        root: { padding: 12 },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "2px 2px 5px 0 rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        contained: {
          boxShadow: "2px 2px 5px 0 rgba(0, 0, 0, 0.2)",
        },
        containedPrimary: {
          backgroundColor: KNISTR_CYAN,
          color: KNISTR_BLUE,
          ":hover": {
            backgroundColor: KNISTR_BLUE,
            color: KNISTR_CYAN,
          },
        },
        outlinedPrimary: {
          backgroundColor: COLOR_WHITE,
          boxShadow: "2px 2px 5px 0 rgba(0, 0, 0, 0.2)",
          borderColor: KNISTR_PLUM,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: 18,
        },
        root: {
          fontSize: 24,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: COLOR_BLACK,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: COLOR_WHITE,
          "&.Mui-selected": {
            color: KNISTR_CYAN,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: KNISTR_BLUE,
        },
        root: {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          ":empty": {
            paddingTop: "0",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: KNISTR_BLUE,
      contrastText: COLOR_WHITE,
    },
    background: {
      default: COLOR_GRAY_1,
    },
    secondary: {
      main: KNISTR_GRAY,
    },
    info: {
      main: COLOR_INFO,
      light: COLOR_INFO,
    },
    success: {
      main: KNISTR_GREEN,
      light: KNISTR_GREEN,
    },
    warning: {
      main: COLOR_WARNING,
      light: COLOR_WARNING,
    },
    error: {
      main: KNISTR_RED,
      light: KNISTR_RED,
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    fontSize: 16,
    h1: {
      fontSize: 32,
      color: KNISTR_BLUE,
    },
    h2: {
      fontSize: 22,
    },
    h3: {
      fontSize: 22,
      fontFamily: "Gilroy-Bold",
    },
    h4: {
      fontSize: 16,
    },
    subtitle1: {
      fontSize: 16,
      color: COLOR_GRAY_5,
      lineHeight: undefined,
    },
    subtitle2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
      color: COLOR_GRAY_2,
    },
    button: {
      fontFamily: "Gilroy-Bold",
      fontSize: 16,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 16,
    },
  },
  shape: {
    borderRadius: 8,
  },
});
