import { Api } from "../../api/Api";
import { thunkCreateErrorNotification } from "./NotificationActions";
import { BooleanThunk } from "../IStore";

export const thunkDownloadPkPass =
  (passNumber: string, token: string, accessToken: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.downloadPkPass(passNumber, token, accessToken);
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Can not download PKPass", e));
      return false;
    }
  };

export const thunkDownloadPkPassForCustomerIdentificationNumber =
  (customerNumber: string, token: string, accessToken: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.downloadPassForCustomerIdentificationNumber(customerNumber, token, accessToken);
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Can not download PKPass", e));
      return false;
    }
  };
