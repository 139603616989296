import { ShoppingBasket } from "@mui/icons-material";
import { Grid, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { COLOR_BLACK, COLOR_GRAY_2, KNISTR_PLUM } from "../atoms/ImsMaterialTheme";
import { formatNumber } from "../atoms/Utils";

const CustomerRegistrationCount = (props: ThunkProps) => {
  const theme = useTheme();
  const { customerRegistrations } = props;
  if (!customerRegistrations) return null;
  const registrations = customerRegistrations.data;
  const registrationSum = customerRegistrations.data.reduce((prev, cur) => prev + Number(cur.CUSTOMER_CNT), 0);
  const registrationsToday =
    customerRegistrations.offset || !registrations.length
      ? undefined
      : registrations[registrations.length - 1].CUSTOMER_CNT;

  return (
    <>
      <Grid
        item
        md
        style={{
          borderLeftWidth: "2px",
          borderLeftStyle: "solid",
          borderLeftColor: KNISTR_PLUM,
          paddingLeft: theme.spacing(1),
        }}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>
          <ShoppingBasket fontSize="large" style={{ paddingLeft: theme.spacing(1) }} />
          <Typography style={{ paddingLeft: theme.spacing(1) }}>Registrierungen</Typography>
        </Grid>
        <Grid item md={12} style={{ display: "flex", alignItems: "baseline" }}>
          <Typography
            style={{
              color: COLOR_BLACK,
              paddingLeft: theme.spacing(1),
              fontWeight: "bold",
              fontSize: "32px",
              paddingRight: theme.spacing(1),
            }}
          >
            {formatNumber(registrationSum)}
          </Typography>
          <Typography variant="subtitle1" style={{ color: COLOR_GRAY_2, fontWeight: "bold" }}>
            Menschen
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="subtitle1" style={{ color: COLOR_GRAY_2, fontWeight: "bold" }}>
        {registrationsToday ? <>Heute: {formatNumber(registrationsToday)}</> : <>&nbsp;</>}
      </Typography>
    </>
  );
};
const mapStateToProps = (state: IStore) => ({
  customerRegistrations: state.dashboard.customerRegistrations,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerRegistrationCount);
