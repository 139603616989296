import { Grid, Paper, useTheme } from "@mui/material";

const RegistrationConfirmationPage = () => {
  const theme = useTheme();
  return (
    <Paper
      style={{
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
      }}
    >
      <Grid container>
        <Grid item md={12}>
          <p>Der neue Kunde wurde erfolgreich registriert.</p>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RegistrationConfirmationPage;
