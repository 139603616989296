import { combineReducers } from "redux";
import { bonusRules } from "./BonusRuleReducer";
import { bonusXpress } from "./BonusXpressReducer";
import { circles } from "./CircleReducer";
import { configGroups } from "./ConfigGroupReducer";
import { dashboard } from "./DashboardReducer";
import { login } from "./LoginReducer";
import { menu } from "./MenuReducer";
import { operationalUnits } from "./OperationalUnitReducer";
import { cashback } from "./CashbackReducer";
import { preferences } from "./PreferencesReducer";
import { bonusPremiums } from "./BonusPremiumReducer";
import { campaigns } from "./CampaignReducer";
import { customerCare } from "./CustomerCareReducer";
import { notifications } from "./NotificationsReducer";
import { users } from "./UserReducer";
import { identifications } from "./IdentificationReducer";
import { flows } from "./FlowReducer";
import { registration } from "./RegistrationReducer";
import { coupons } from "./CouponReducer";
import { interactionSearch } from "./InteractionSearchReducer";
import { gdpr } from "./GdprReducer";
import { einGeneratorTemplates } from "./EinGeneratorTemplateReducer";
import { appLinks } from "./AppLinkReducer";
import { carousels } from "./CarouselReducer";
import { passMediums } from "./PassMediumReducer";

const reducer = combineReducers({
  carousels,
  appLinks,
  preferences,
  bonusRules,
  bonusPremiums,
  bonusXpress,
  cashback,
  campaigns,
  customerCare,
  operationalUnits,
  notifications,
  users,
  circles,
  passMediums,
  identifications,
  flows,
  registration,
  coupons,
  dashboard,
  login,
  interactionSearch,
  gdpr,
  configGroups,
  menu,
  einGeneratorTemplates,
});

export default reducer;
export type State = ReturnType<typeof reducer>;
