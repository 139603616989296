import { AnyAction } from "redux";
import { SnowflakeTable } from "../../api/SnowflakeApi";
import {
  GET_CUSTOMER_ACTIVITIES,
  GET_CUSTOMER_ACTIVITIES_YEAR,
  GET_CUSTOMER_REGISTRATIONS,
  GET_CUSTOMER_STATUSES,
  GET_DASHBOARD_INTERACTION_KPI_DAY,
} from "../actions/DashboardActions";
import { CustomerActivityDto } from "../models/dashboard/CustomerActivityDto";
import { CustomerActivityYearDto } from "../models/dashboard/CustomerActivityYearDto";
import { CustomerRegistrationDto } from "../models/dashboard/CustomerRegistrationDto";
import { CustomerStatusDto } from "../models/dashboard/CustomerStatusDto";
import { InteractionKpiDayDto } from "../models/dashboard/InteractionKpiDayDto";

export interface IDashboardReducerState {
  interactionKpiDay?: InteractionKpiDayDto;
  customerRegistrations?: SnowflakeTable<CustomerRegistrationDto>;
  customerStatuses?: SnowflakeTable<CustomerStatusDto>;
  customerActivities?: SnowflakeTable<CustomerActivityDto>;
  customerActivitiesYear?: SnowflakeTable<CustomerActivityYearDto>;
}

export const dashboard = (state: IDashboardReducerState, action: AnyAction): IDashboardReducerState => {
  switch (action.type) {
    case GET_DASHBOARD_INTERACTION_KPI_DAY:
      return {
        ...state,
        interactionKpiDay: action.payload,
      };
    case GET_CUSTOMER_REGISTRATIONS:
      return {
        ...state,
        customerRegistrations: action.payload,
      };
    case GET_CUSTOMER_STATUSES:
      return {
        ...state,
        customerStatuses: action.payload,
      };
    case GET_CUSTOMER_ACTIVITIES:
      return {
        ...state,
        customerActivities: action.payload,
      };
    case GET_CUSTOMER_ACTIVITIES_YEAR:
      return {
        ...state,
        customerActivitiesYear: action.payload,
      };
    default:
      return state || {};
  }
};
