export enum AdjustmentType {
  EARN = "EARN",
  BURN = "BURN",
}

export const adjustmentTypeOptions = [
  { value: AdjustmentType.EARN, label: "Kulanzgutschrift" },
  { value: AdjustmentType.BURN, label: "Belastung" },
];

export interface AdjustmentDto {
  customerNumber: string;
  type: AdjustmentType;
  externalUnitNumber?: string;
  bonusPoints: string;
  note: string;
}
