import { v4 as uuid } from "uuid";
import { formatGermanDateTime } from "../../components/atoms/Utils";
import { VoidThunk } from "../IStore";
import { INotification, NotificationType } from "../models/INotification";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export const ENTER_DIALOG = "ENTER_DIALOG";
export const LEAVE_DIALOG = "LEAVE_DIALOG";

export const SHOW_INFO_SECONDS = 10;
export const SHOW_SUCCESS_SECONDS = 10;
export const SHOW_ERROR_SECONDS = 0; //LH-975 an alert message does not disappear on itself

export const thunkDismissNotification =
  (notification: INotification): VoidThunk =>
  async (dispatch) => {
    dispatch({
      type: DISMISS_NOTIFICATION,
      payload: notification,
    });
  };

export const thunkCreateErrorNotification =
  (message: string, e: any): VoidThunk =>
  async (dispatch) => {
    dispatch(thunkCreateNotification(`${message}\n${e}`, NotificationType.ERROR, SHOW_ERROR_SECONDS));
  };

export const thunkCreateWarningNotification =
  (message: string, e: any): VoidThunk =>
  async (dispatch) => {
    dispatch(thunkCreateNotification(`${message}\n${e}`, NotificationType.WARNING, SHOW_INFO_SECONDS));
  };

export const thunkCreateInfoNotification =
  (message: string): VoidThunk =>
  async (dispatch) => {
    dispatch(thunkCreateNotification(message, NotificationType.INFO, SHOW_INFO_SECONDS));
  };

export const thunkCreateSuccessNotification =
  (message: string): VoidThunk =>
  async (dispatch) => {
    dispatch(thunkCreateNotification(message, NotificationType.SUCCESS, SHOW_SUCCESS_SECONDS));
  };

export const thunkCreateNotification =
  (message: string, type: NotificationType, timeoutSeconds?: number): VoidThunk =>
  async (dispatch) => {
    const notification = {
      message,
      type,
      id: uuid(),
      timestamp: formatGermanDateTime(new Date()),
    };

    dispatch({
      type: CREATE_NOTIFICATION,
      payload: notification,
    });

    timeoutSeconds &&
      timeoutSeconds !== 0 &&
      setTimeout(() => dispatch({ type: DISMISS_NOTIFICATION, payload: notification }), timeoutSeconds * 1000);
  };

export const thunkEnterDialog = (): VoidThunk => async (dispatch) => {
  dispatch({
    type: ENTER_DIALOG,
  });
};

export const thunkLeaveDialog = (): VoidThunk => async (dispatch) => {
  dispatch({
    type: LEAVE_DIALOG,
  });
};
