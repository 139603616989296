import { AnyAction } from "redux";
import { FIND_COUPONS_BY_CUSTOMER_NUMBER } from "../actions/CouponActions";
import { CouponDto } from "../models/coupon/CouponDto";

export interface ICouponReducerState {
  coupons?: CouponDto[];
}

export const coupons = (state: ICouponReducerState, action: AnyAction): ICouponReducerState => {
  switch (action.type) {
    case FIND_COUPONS_BY_CUSTOMER_NUMBER:
      return {
        ...state,
        coupons: action.payload,
      };

    default:
      return state || {};
  }
};
