import { InteractionType } from "../customer/CustomerInteractionDto";

export enum InteractionProcessingType {
  EARN = "EARN",
  BURN = "BURN",
}

export const interactionProcessingTypeOptions = [
  { value: "", label: "---" },
  { value: InteractionProcessingType.EARN, label: "Earn" },
  { value: InteractionProcessingType.BURN, label: "Burn" },
];

export const interactionTypeOptions = [
  {
    value: "",
    label: "---",
  },
  {
    value: InteractionType.PAYMENT_INTERACTION,
    label: "Einkauf",
  },
  {
    value: InteractionType.REVERSAL_INTERACTION,
    label: "Storno",
  },
  {
    value: InteractionType.COUPON_INTERACTION,
    label: "Coupon",
  },
  {
    value: InteractionType.BONUS_XPRESS_INTERACTION,
    label: "Bonus XPress",
  },
  {
    value: InteractionType.BONUS_XPRESS_REVERSAL_INTERACTION,
    label: "Storno Bonus XPress",
  },
  {
    value: InteractionType.CUSTOMER_INTERACTION,
    label: "sonstige",
  },
];
