import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import React, { Key, PropsWithChildren, ReactNode } from "react";
import { COLOR_WHITE } from "./ImsMaterialTheme";

export interface ImsHeadCell {
  title: ReactNode;
  align: "left" | "center" | "right";
  headAlign?: "left" | "center" | "right";
  cellStyle?: React.CSSProperties;
}

export interface ImsTableRow {
  nodes: ReactNode[];
  key: Key;
}

export interface ImsTableProps extends PropsWithChildren<{}> {
  headCells: ImsHeadCell[];
  rows: ImsTableRow[];
  verticalAlign?: "top" | "auto";
  compact?: boolean;
}

const ImsTable = (props: ImsTableProps) => {
  const { headCells, rows, verticalAlign, compact } = props;
  const theme = useTheme();
  return (
    <TableContainer>
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell, index, array) => (
              <TableCell
                align={headCell.headAlign || headCell.align}
                style={{
                  background: COLOR_WHITE,
                  paddingLeft: !index ? theme.spacing(3) : "inherit",
                  paddingRight: index === array.length - 1 ? theme.spacing(3) : "inherit",
                }}
                key={index}
              >
                {headCell.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex, rowArray) => (
            <TableRow key={row.key}>
              {row.nodes.map((value, index, array) => (
                <TableCell
                  align={headCells[index].align}
                  key={index}
                  style={{
                    paddingTop: compact && rowIndex !== 0 ? theme.spacing(0.5) : undefined,
                    paddingBottom: compact ? theme.spacing(0.5) : undefined,
                    paddingLeft: index === 0 ? theme.spacing(3) : undefined,
                    paddingRight: index === array.length - 1 ? theme.spacing(3) : undefined,
                    borderBottom: rowIndex === rowArray.length - 1 || compact ? "none" : undefined,
                    ...headCells[index].cellStyle,
                    verticalAlign,
                  }}
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ImsTable;
