import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { BonusXpressDto } from "../models/bonusXpress/BonusXpressDto";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";

export const GET_BONUS_XPRESS = "GET_BONUS_XPRESS";
export const UPDATE_BONUS_XPRESS = "UPDATE_BONUS_XPRESS";

export const thunkGetBonusXpress = (): BooleanThunk => async (dispatch) => {
  try {
    const bonusXpress = await Api.getBonusXpress();
    dispatch({
      type: GET_BONUS_XPRESS,
      payload: bonusXpress,
    });
    return true;
  } catch (e) {
    if (e === "BonusXPress not found") {
      dispatch({
        type: GET_BONUS_XPRESS,
        payload: {},
      });
      return true;
    }
    dispatch(thunkCreateErrorNotification("Fehler beim Laden von Bonus XPress", e));
    return false;
  }
};

export const thunkUpdateBonusXpress =
  (bonusXpress: BonusXpressDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const updatedBonusXpress = await Api.updateBonusXpress(bonusXpress);
      dispatch({
        type: UPDATE_BONUS_XPRESS,
        payload: updatedBonusXpress,
      });
      dispatch(thunkCreateSuccessNotification("Bonus XPress wurde aktualisiert"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler beim Aktualisieren der Bonusprämie", e));
      return false;
    }
  };
