import { Box, Container, Typography, Button } from "@mui/material";
import { Block } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UrlPathMain } from "../../api/url";

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <Block color="primary" style={{ fontSize: 80 }} />
        <Typography variant="h3" component="h1" gutterBottom>
          403 Forbidden
        </Typography>
        <Typography variant="h6" component="p" gutterBottom>
          You do not have permission to access this resource.
        </Typography>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleGoBack}>
            Go Back
          </Button>
        </Box>
        <Box mt={2}>
          <Button variant="contained" color="primary" href={UrlPathMain}>
            Go to Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UnauthorizedPage;
