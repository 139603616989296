import { Grid } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { BonusRuleDto } from "../../store/models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../../store/models/bonusRule/BonusRuleStatus";
import { BonusRuleType } from "../../store/models/bonusRule/BonusRuleType";
import { BonusRuleValueType } from "../../store/models/bonusRule/BonusRuleValueType";
import { DateTag } from "../atoms/DateTag";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { interactionTypeOptions, typeOptions } from "./BonusRuleForm";
import BonusRuleMenu from "./BonusRuleMenu";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { BonusSearchCondition } from "./BonusRulePage";
import { getCurrentDateAsString } from "../atoms/Utils";

export function getCustomerInteractionDescription(row: BonusRuleDto): string {
  if (row.type === BonusRuleType.TRANSACTION)
    return typeOptions.filter((typeOption) => typeOption.value === BonusRuleType.TRANSACTION)[0].label;
  const interactionTypeOption = interactionTypeOptions.filter(
    (interactionTypeOption) => interactionTypeOption.value === row.customerInteractionType
  );
  return interactionTypeOption[0]?.label || "Eigene Aktivität";
}

export function getUnitNames(unitNumbers: string[], operationalUnits: OperationalUnitDto[]): string {
  const unitNames = operationalUnits
    .filter((unit) => unitNumbers.includes(unit.unitNumber))
    .slice(0, 5)
    .map((unit) => unit.unitName);

  if (unitNames.length > 0) {
    return unitNames.join(", ") + (unitNumbers.length > 5 ? "..." : "");
  }

  return "";
}

function getValueTypeDescription(valueType: BonusRuleValueType): string {
  const valueDescriptions = {
    [BonusRuleValueType.FIX]: "",
    [BonusRuleValueType.FACTOR]: " pro Euro",
    [BonusRuleValueType.PER_CENT]: " pro Cent",
  };
  return valueDescriptions[valueType] || "";
}

function isActualRunning(bonusRule: BonusRuleDto): boolean {
  if (bonusRule.validTill) {
    // if and end date is exist then it must be in the future
    return bonusRule.validTill >= getCurrentDateAsString();
  }
  return true;
}
function isActive(bonusRule: BonusRuleDto): boolean {
  return bonusRule.status !== BonusRuleStatus.DISABLED;
}

function isUnitNumberMatched(bonusRule: BonusRuleDto, conditionUnitNumbers: string[]): boolean {
  if (conditionUnitNumbers.length > 0) {
    return conditionUnitNumbers.some((unitNumber) => bonusRule.unitNumbers.includes(unitNumber));
  }

  //No given unit numbers to filter  => skip checking
  return true;
}
interface BonusRuleTableProps {
  searchCondition: BonusSearchCondition;
}

const BonusTable = (props: BonusRuleTableProps & ThunkProps) => {
  const headCells: ImsHeadCell<BonusRuleDto>[] = [
    { val: (val) => val.ruleName, node: "Name" },
    { val: (val) => val.validFrom, node: "Ausführung" },
    { val: (val) => val.unitNumbers, node: ["Filialen"] },
    { val: (val) => val.value, node: "Punkte" },
    { val: (val) => val.type, node: "Aktivität" },
    { val: (val) => val.status, node: "Status" },
  ];

  const rows: ImsTableRow<BonusRuleDto>[] = props.bonusRules
    .filter((row) =>
      props.searchCondition.name ? row.ruleName.toLowerCase().includes(props.searchCondition.name.toLowerCase()) : true
    )
    .filter((row) => (props.searchCondition.isRunning ? isActualRunning(row) : true))
    .filter((row) => (props.searchCondition.showInactive ? true : isActive(row)))
    .filter((row) =>
      props.searchCondition.unitNumbers ? isUnitNumberMatched(row, props.searchCondition.unitNumbers) : true
    )
    .map((row) => ({
      value: row,
      nodes: [
        <b>{row.ruleName}</b>,
        <>
          {!row.validTill ? " ab " : ""} <DateTag date={row.validFrom} />
          {row.validTill ? " bis " : ""} <DateTag date={row.validTill} />
        </>,
        getUnitNames(row.unitNumbers, props.operationalUnits),
        Number(row.value) + getValueTypeDescription(row.valueType),
        getCustomerInteractionDescription(row),
        row.status === BonusRuleStatus.ENABLED ? "aktiv" : "inaktiv",
        <BonusRuleMenu bonusRule={row} />,
      ],
      disabled: row.status === BonusRuleStatus.DISABLED,
    }));

  return (
    <Grid>
      <ImsTable hasMenu={true} headCells={headCells} rows={rows} />
    </Grid>
  );
};

const mapStateToProps = (state: IStore) => ({
  bonusRules: state.bonusRules.bonusRules,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusTable);
