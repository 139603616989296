import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import MenuLayout from "../../app/MenuLayout";
import PassMediumForm from "./PassMediumForm";
import { thunkUpdatePassMedium } from "../../../store/actions/PassMediumAction";
import { PassIdView } from "./PassIdView";
import { PassMediumDto, PassMediumType } from "../../../store/models/passmedium/PassMediumDto";

const defaultPassMedium: PassMediumDto = {
  motiv: "",
  type: PassMediumType.PKPASS,
  backgroundColor: "#FFFFFF",
  foregroundColor: "#000000",
  labelColor: "#000000",
  includeName: false,
  includeExternalConnectionId: false,
  includePassId: false,
  barcodeFormat: PassIdView.BARCODE,
};

const PassMediumFormPage = (props: ThunkProps) => {
  const { id } = useParams<"id">();
  const existedPassMedium = props.passMediums.find((passMedium) => passMedium.passMediumNumber === id);
  return (
    <MenuLayout headline={id ? "Pass Medium bearbeiten" : "Neues Pass Medium"}>
      <PassMediumForm passMedium={existedPassMedium || defaultPassMedium} />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  passMediums: state.passMediums.passMediums,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkUpdatePassMedium }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PassMediumFormPage);
