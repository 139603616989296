import { Button, Dialog, DialogContent, DialogTitle, Grid, useTheme } from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkChangePassword } from "../../store/actions/UserActions";
import { IStore } from "../../store/IStore";
import { ChangePasswordDto } from "../../store/models/user/ChangePasswordDto";
import { FormInput } from "../atoms/FormInput";
import { isBlank, ValidationErrors } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";
import { validatePassword } from "../atoms/FieldValidation";
import { FormPassword } from "../atoms/FormPassword";

interface ChangePasswordProps {
  setChangePasswordVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePassword = (props: ChangePasswordProps & ThunkProps) => {
  const theme = useTheme();
  const changePassword = async (changePassword: ChangePasswordDto) => {
    await props.thunkChangePassword(changePassword);
    props.setChangePasswordVisible(false);
  };

  const validateForm = (values: ChangePasswordDto) => {
    const errors: ValidationErrors<ChangePasswordDto> = {};
    if (isBlank(values.oldPassword)) {
      errors.oldPassword = "Pflichtfeld";
    }
    if (isBlank(values.newPassword)) {
      errors.newPassword = "Pflichtfeld";
    }
    if (values.newPassword && values.newPassword !== values.confirmNewPassword) {
      errors.confirmNewPassword = "Passwort muss identisch sein";
    }
    if (values.oldPassword === values.newPassword) {
      errors.newPassword = "Bitte geben Sie ein geändertes Passwort ein";
    }
    validatePassword(values.newPassword, errors, "newPassword");
    return errors;
  };
  return (
    <Form
      onSubmit={changePassword}
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      validate={validateForm}
      render={({ handleSubmit, submitting }) => (
        <Dialog onClose={() => props.setChangePasswordVisible(false)} open={true}>
          <NotificationList isDialog />
          <DialogTitle>Passwort ändern</DialogTitle>
          <DialogContent style={{ paddingBottom: theme.spacing(4) }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="password"
                    name="oldPassword"
                    label="altes Passwort"
                  />
                </Grid>
                <Grid item md={12}>
                  <FormPassword name="newPassword" label="neues Passwort" fullWidth />
                </Grid>
                <Grid item md={12}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="password"
                    name="confirmNewPassword"
                    label="Passwort wiederholen"
                  />
                </Grid>
                <Grid container item justifyContent="flex-end">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => props.setChangePasswordVisible(false)}
                    style={{ marginRight: theme.spacing(2) }}
                  >
                    Abbrechen
                  </Button>
                  <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                    Speichern
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    />
  );
};

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkChangePassword,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(ChangePassword);
