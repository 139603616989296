import { Alert } from "@mui/material";

import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkDismissNotification, thunkEnterDialog, thunkLeaveDialog } from "../../store/actions/NotificationActions";
import { IStore } from "../../store/IStore";
import { useMountEffect } from "../atoms/Utils";

interface NotificationListProps {
  isDialog?: boolean;
}

const NotificationList = (props: ThunkProps & NotificationListProps) => {
  const { notifications, thunkDismissNotification } = props;

  useMountEffect(() => {
    if (props.isDialog) {
      props.thunkEnterDialog();
      return () => {
        props.thunkLeaveDialog();
      };
    }
  });

  if (!notifications?.length) return null;

  return (
    <>
      {notifications.map((notification) => (
        <Alert
          severity={notification.type}
          key={notification.id}
          onClose={() => thunkDismissNotification(notification)}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {makeNotificationMessage(notification.time, notification.message)}
        </Alert>
      ))}
    </>
  );
};

/**
 * Join Time and Message with a space character. Not adding if one of them is missing.
 * @param time Notification time.
 * @param message Notification message.
 */
const makeNotificationMessage = (time: string, message: string) => {
  return [time, message]
    .filter((s) => s) // Remove falsy data
    .join(" ");
};

const mapStateToProps = (state: IStore) => ({
  notifications: state.notifications?.notifications,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkDismissNotification, thunkEnterDialog, thunkLeaveDialog }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(NotificationList);
