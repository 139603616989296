import { LoginDto } from "../store/models/user/LoginDto";
import { fetchWithTenant } from "./TenantUtils";

const REACT_APP_KEYCLOAK_URL = window.env.REACT_APP_KEYCLOAK_URL || process.env.REACT_APP_KEYCLOAK_URL;

export const KeycloakApi = {
  login: async (login: LoginDto): Promise<string> => {
    return fetchWithTenant(`${REACT_APP_KEYCLOAK_URL}/realms/{tenant}/protocol/openid-connect/token`, {
      method: "POST",
      body: new URLSearchParams({
        client_id: "react-admin",
        grant_type: "password",
        ...login,
      }),
    }).then(parseKeycloakResponse);
  },
  publicLogin: async (login: LoginDto): Promise<string> => {
    return fetchWithTenant(`${REACT_APP_KEYCLOAK_URL}/realms/{tenant}/protocol/openid-connect/token`, {
      method: "POST",
      body: new URLSearchParams({
        client_id: "react-admin",
        grant_type: "password",
        ...login,
      }),
    }).then(parseKeycloakResponse);
  },
  refresh: async (refresh_token: string): Promise<string> => {
    return fetchWithTenant(`${REACT_APP_KEYCLOAK_URL}/realms/{tenant}/protocol/openid-connect/token`, {
      method: "POST",
      body: new URLSearchParams({
        client_id: "react-admin",
        grant_type: "refresh_token",
        refresh_token,
      }),
    }).then(parseKeycloakResponse);
  },
};

export async function parseKeycloakResponse(response: Response) {
  const text = await response.text();
  let data;
  try {
    data = JSON.parse(text);
  } catch {
    if (response.status !== 200 && response.status !== 401) throw response.statusText;
  }
  if (data && data.error_description) {
    throw data.error_description;
  }
  return text;
}
