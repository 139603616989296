import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { AppLinkDto } from "../../../store/models/appcontent/AppLinkDto";
import ImsConfirmDialog from "../../atoms/ImsConfirmDialog";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { thunkDeleteAppLink } from "../../../store/actions/AppLinkActions";
import { UrlPathAppContentEdit } from "../../../api/url";
import { canMaintainAppContent } from "../../../store/actions/LoginActions";
import { useTranslation } from "react-i18next";

interface AppContentListMenuProps {
  appLink: AppLinkDto;
}

function AppContentListMenu(props: AppContentListMenuProps & ThunkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  return (
    <div>
      {confirmVisible && (
        <ImsConfirmDialog
          article="den"
          word="App Link"
          verb="löschen"
          onConfirm={() => props.thunkDeleteAppLink(props.appLink)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {canMaintainAppContent() && (
          <MenuItem onClick={handleClose}>
            <Link component={RouterLink} to={UrlPathAppContentEdit.replace(":id", props.appLink.appLinkNumber)}>
              {t("appContentPage.kebabMenuEdit")}
            </Link>
          </MenuItem>
        )}
        {canMaintainAppContent() && (
          <MenuItem
            onClick={(e) => {
              setConfirmVisible(true);
              handleClose(e);
            }}
          >
            {t("appContentPage.kebabMenuDelete")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkDeleteAppLink }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(AppContentListMenu);
