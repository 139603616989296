export interface InvitationDto {
  customerNumber: string;
  mailAddress: string;
  invitationType: InvitationType;
}

export enum InvitationType {
  INVITE_TO_FAMILY = "INVITE_TO_FAMILY",
  REFER_A_FRIEND = "REFER_A_FRIEND",
}
