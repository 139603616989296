import { DescriptionSharp, ShoppingCart } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { ReactNode, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkFindReceiptDetailsForInteraction } from "../../store/actions/CustomerCareActions";
import { IStore } from "../../store/IStore";
import { CouponAdvantageType } from "../../store/models/bonusPremium/CouponAdvantageType";
import { formatCustomerInteractionType } from "../../store/models/bonusRule/CustomerInteractionType";
import { AdvantageDto } from "../../store/models/coupon/CouponDto";
import { CustomerInteractionDto, InteractionType } from "../../store/models/customer/CustomerInteractionDto";
import { getCustomerStatusText } from "../../store/models/customer/CustomerStatusDto";
import { CustomerHistoryDto, EntityType, EventType, TransferType } from "../../store/models/history/HistoryDto";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { DateTag } from "../atoms/DateTag";
import ImsTable, { ImsHeadCell, ImsTableRow } from "../atoms/ImsEmbeddedTable";
import { formatEuro, formatGermanDate } from "../atoms/Utils";
import { formatBonusPoints } from "./KeyFigures";
import NoteDialog, { hasNoteDialog } from "./NoteDialog";
import ReceiptDialog from "./ReceiptDialog";

interface HistoryFeedTableProps {
  maxTableSize?: number;
}

interface GroupedHistory {
  action?: CustomerHistoryDto;
  reversal?: CustomerHistoryDto;
  bonus?: CustomerHistoryDto;
  coupon?: CustomerHistoryDto;
}

const HistoryFeedTable: React.FC<ThunkProps & HistoryFeedTableProps> = (props) => {
  const theme = useTheme();
  const { customerHistory, customerNumber, operationalUnits, maxTableSize, receiptDetails } = props;
  const [receiptDialogVisible, setReceiptDialogVisible] = useState(false);
  const [receiptInteraction, setReceiptInteraction] = useState({} as CustomerInteractionDto);
  const [noteDialogVisible, setNoteDialogVisible] = useState(false);
  const [note, setNote] = useState({} as CustomerHistoryDto);
  if (!customerNumber) return null;
  if (!customerHistory) return null;

  if (!customerHistory.length) return <p>keine Interaktionsdaten gefunden</p>;

  const headCells: ImsHeadCell[] = [
    { title: "Datum", align: "left" },
    { title: "Interaktion", align: "left", headAlign: "center" },
    {
      title: <span style={{ paddingRight: theme.spacing(2) }}>Betrag</span>,
      align: "right",
    },
    { title: "Punkte", align: "right" },
  ];
  const groupedHistory = groupHistory(customerHistory);

  const formatAction = (action: CustomerHistoryDto, operationalUnits: OperationalUnitDto[]) => (
    <>
      <b>
        {formatEntityType(action, operationalUnits)} {formatEventType(action)} {formatEventDetails(action)}
        {}
      </b>
      {(action.interaction?.interactionType === InteractionType.PAYMENT_INTERACTION ||
        action.interaction?.interactionType === InteractionType.REVERSAL_INTERACTION) && (
        <ShoppingCart
          style={{
            color: theme.palette.primary.main,
            fontSize: 21,
            marginBottom: -5,
          }}
          onClick={() => {
            props.thunkFindReceiptDetailsForInteraction(action.interaction!.interactionNumber);
            setReceiptDialogVisible(true);
            setReceiptInteraction(action.interaction!);
          }}
        />
      )}
      {hasNoteDialog(action) && (
        <DescriptionSharp
          style={{
            color: theme.palette.primary.main,
            fontSize: 21,
            marginBottom: -5,
          }}
          onClick={() => {
            setNoteDialogVisible(true);
            setNote(action);
          }}
        />
      )}
      <br />
    </>
  );

  const rows: ImsTableRow[] = groupedHistory.slice(0, maxTableSize).map((row) => ({
    key: (row.action || row.reversal || row.bonus || row.coupon)!.historyNumber,
    nodes: [
      <DateTag date={(row.action || row.reversal || row.bonus || row.coupon)!.eventTimestamp} />,
      <>
        {row.action && formatAction(row.action, operationalUnits)}
        {row.reversal && formatAction(row.reversal, operationalUnits)}
        {row.bonus && (
          <>
            {formatEntityType(row.bonus, operationalUnits)} {formatEventType(row.bonus)}
            <br />
          </>
        )}
        {row.coupon && (
          <>
            {formatCoupon(row.coupon.coupon?.advantage)}
            {formatEntityType(row.coupon, operationalUnits)}
          </>
        )}
      </>,
      <div>
        {formatAmount(row.action)}
        {formatAmount(row.reversal)}
        {formatAmount(row.bonus)}
        {formatAmount(row.coupon)}
      </div>,
      <div>
        {row.action && (
          <>
            {formatBonusPoints(row.action.interaction?.bonusPoints)}
            <br />
          </>
        )}
        {row.reversal && (
          <>
            {formatBonusPoints(row.reversal.interaction?.bonusPoints)}
            <br />
          </>
        )}
        {row.bonus && formatBonusPoints(row.bonus.interaction?.bonusPoints)}
      </div>,
    ],
  }));

  return (
    <>
      <ImsTable headCells={headCells} rows={rows} verticalAlign="top" />

      {receiptDialogVisible && (
        <ReceiptDialog
          interaction={receiptInteraction}
          receiptDetails={receiptDetails}
          setReceiptDialogVisible={setReceiptDialogVisible}
        />
      )}
      {noteDialogVisible && <NoteDialog note={note} setNoteDialogVisible={setNoteDialogVisible} />}
    </>
  );
};

function formatAmount(element: CustomerHistoryDto | undefined) {
  if (!element) return null;
  const amount =
    element.interaction?.paymentAmount ||
    element.interaction?.reversalAmount ||
    element.interaction?.cashValue ||
    element.interaction?.reversalCashValue ||
    element.coupon?.cashValue;
  if (!amount) return <br />;
  const negativeAmount =
    element.interaction?.reversalAmount || element.interaction?.cashValue || element.coupon?.redeemedBy;
  const value = Number(amount) * (Boolean(negativeAmount) ? -1 : 1);
  return (
    <>
      {formatEuro(value)}
      <br />
    </>
  );
}

function groupHistory(customerHistory: CustomerHistoryDto[]): GroupedHistory[] {
  const groups: GroupedHistory[] = [];

  customerHistory.forEach((history) => {
    const id = history.referenceId;
    let i = groups.findIndex(
      (group) =>
        id &&
        (id === group.action?.referenceId ||
          id === group.reversal?.referenceId ||
          id === group.bonus?.referenceId ||
          id === group.coupon?.referenceId)
    );
    if (i === -1) {
      i = groups.push({}) - 1;
    }
    if (
      history.interaction?.interactionType === InteractionType.BONUS_XPRESS_INTERACTION ||
      history.interaction?.interactionType === InteractionType.BONUS_XPRESS_REVERSAL_INTERACTION
    ) {
      groups[i].bonus = history;
    } else if (history.interaction?.interactionType === InteractionType.REVERSAL_INTERACTION) {
      groups[i].reversal = history;
    } else if (history.entityType === EntityType.COUPON) {
      groups[i].coupon = history;
    } else {
      groups[i].action = history;
    }
  });
  return groups;
}

function formatEntityType(row: CustomerHistoryDto, operationalUnits: OperationalUnitDto[]): ReactNode {
  switch (row.entityType) {
    case EntityType.ADDRESS:
      return "Adressdaten";
    case EntityType.CUSTOMER:
      return "Kundendaten";
    case EntityType.DSGVO:
      return "DSGVO erstellt";
    case EntityType.COUPON:
      if (row.eventType === EventType.CREATE && !row.coupon?.redeemedBy) return "Coupon aktiviert";
      if (row.eventType === EventType.UPDATE && row.coupon?.redeemedBy) return "Coupon eingelöst";
      if (row.eventType === EventType.UPDATE && !row.coupon?.redeemedBy) return "Coupon reaktiviert";
      return "Coupon";
    case EntityType.CUSTOMER_STATUS:
      return "Kundenstatus";
    case EntityType.PREFERENCE:
      return "Präferenzen";
    case EntityType.CUSTOMER_IDENTIFICATION:
      return row.eventType === EventType.REPLACEMENT ? "Ersatzkarte" : "Pass";
    case EntityType.CIRCLE:
      return "Circle";
    case EntityType.INTERACTION:
      const operationalUnit = operationalUnits.find(
        (unit) => unit.externalUnitNumber === row.interaction?.externalUnitNumber
      );
      const interaction = row.interaction!;
      switch (interaction.interactionType) {
        case InteractionType.CUSTOMER_INTERACTION:
          return formatCustomerInteractionType(interaction.customerInteractionType!);
        case InteractionType.PAYMENT_INTERACTION:
          return formatOperationalUnitLink("Einkauf", operationalUnit);
        case InteractionType.REVERSAL_INTERACTION:
          return formatOperationalUnitLink("Storno Einkauf", operationalUnit);
        case InteractionType.BONUS_XPRESS_REVERSAL_INTERACTION:
          return "Storno Bonus XPress";
        case InteractionType.COUPON_INTERACTION:
          return "Coupon gekauft";
        case InteractionType.BONUS_XPRESS_INTERACTION:
          return "Bonus XPress";
        case InteractionType.ADJUSTMENT_EARN_INTERACTION:
          return "Kulanzgutschrift";
        case InteractionType.ADJUSTMENT_BURN_INTERACTION:
          return "Belastung";
        case InteractionType.EXPIRED_REWARD_INTERACTION:
          return "Abzug verfallener Bonuspunkte";
        case InteractionType.CLEAR_BONUS_POINTS_INTERACTION:
          return "Löschung von Bonuspunkten nach Kündigung";
        case InteractionType.CASHBACK_BURN_INTERACTION:
          return "Abzug von Bonuspunkten nach der abgeschlossenen Cashback-Auszahlung";
        default:
          return interaction.interactionType;
      }
  }
}

const formatEventType = (row: CustomerHistoryDto): ReactNode => {
  if (row.entityType === EntityType.CUSTOMER_STATUS) {
    const executionMessage = row.executionDate ? ` zum ${formatGermanDate(row.executionDate)}` : "";
    return `geändert: ${getCustomerStatusText(row.customerStatus!)} ${executionMessage}`;
  }
  if (
    row.entityType !== EntityType.INTERACTION &&
    row.entityType !== EntityType.COUPON &&
    row.entityType !== EntityType.DSGVO
  )
    switch (row.eventType) {
      case EventType.CREATE:
        return "hinzugefügt";
      case EventType.UPDATE:
        return "aktualisiert";
      case EventType.DELETE:
        return "gelöscht";
      case EventType.REPLACEMENT:
        return "angefordert";
      case EventType.PRODUCE:
        return "wird produziert";
    }
};

const formatEventDetails = (row: CustomerHistoryDto): ReactNode => {
  if (!row.eventDetails) {
    return;
  }
  if (row.eventDetails.creationDetails) {
    return row.eventDetails.creationDetails.details;
  }
  if (row.eventDetails.updateDetails) {
    if (row.eventType === EventType.TRANSFER_OWNERSHIP) {
      if (row.eventDetails.updateDetails.to === TransferType.REMOVED) {
        return `${row.eventDetails.updateDetails.from} entfernt`;
      }
      return `${row.eventDetails.updateDetails.to} hinzugefügt`;
    }
    return `von ${row.eventDetails.updateDetails.from} nach ${row.eventDetails.updateDetails.to}`;
  }
};

function formatCoupon(advantage?: AdvantageDto) {
  if (!advantage) return;
  switch (advantage.advantageType) {
    case CouponAdvantageType.EURO:
      return `${advantage.advantageValue} € `;
    case CouponAdvantageType.PERCENT:
      return `${advantage.advantageValue} % `;
    case CouponAdvantageType.OFFER:
      return `${advantage.advantageText} `;
  }
}

function formatOperationalUnitLink(title: string, operationalUnit?: OperationalUnitDto): ReactNode {
  return (
    <>
      {title}
      {operationalUnit && (
        <>
          {" | "} {operationalUnit.unitName}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: IStore) => ({
  customerHistory: state.customerCare.customerHistory,
  customerNumber: state.customerCare.customer?.customerNumber,
  operationalUnits: state.operationalUnits.operationalUnits,
  receiptDetails: state.customerCare.receiptDetails,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkFindReceiptDetailsForInteraction }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(HistoryFeedTable);
