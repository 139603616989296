import { Button, Grid, Paper, Theme, useTheme } from "@mui/material";
import countries from "iso3166-2-db/countryList/dispute/UN/de.json";
import { ValidationErrors } from "final-form";
import { OptionHTMLAttributes, useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathOperationalUnit } from "../../api/url";
import { thunkCreateOperationalUnit, thunkUpdateOperationalUnit } from "../../store/actions/OperationalUnitActions";
import { IStore } from "../../store/IStore";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { OperationalUnitType } from "../../store/models/operationalUnit/OperationalUnitType";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { isBlank, getEuropeanCountries } from "../atoms/Utils";
import { validateEmail } from "../atoms/FieldValidation";

interface OperationalUnitFormProps {
  operationalUnit: OperationalUnitDto;
}

const unitTypeOptions = [
  { value: OperationalUnitType.TRADE_ASSOCIATION, label: "Unternehmerverband" },
  { value: OperationalUnitType.REGION, label: "Region" },
  { value: OperationalUnitType.DEPARTMENT, label: "Abteilung" },
  { value: OperationalUnitType.RETAILER, label: "Händler" },
  { value: OperationalUnitType.BRANCH_STORE, label: "Filiale" },
  { value: OperationalUnitType.CASH_TILL, label: "Kasse" },
];

const countryList = getEuropeanCountries(countries);

export const getOperationalUnitOptions = (
  operationalUnit: OperationalUnitDto | undefined,
  operationalUnits: OperationalUnitDto[],
  theme: Theme,
  required: boolean,
  externalUnitNumber: boolean
): OptionHTMLAttributes<HTMLOptionElement>[] => {
  const rootOperationalUnit = operationalUnits.find((operationalUnit) => !operationalUnit.parentUnitNumber)!;
  const hierarchy = getSubUnitHierarchy(operationalUnit, rootOperationalUnit, operationalUnits, 0);
  const result = hierarchy.map((operationalUnit) => ({
    value: externalUnitNumber ? operationalUnit.externalUnitNumber : operationalUnit.unitNumber,
    label: operationalUnit.unitName,
    style: {
      paddingLeft: theme.spacing(operationalUnit.depth * 4 + 2),
    },
    tabIndex: operationalUnit.depth,
  }));
  return required ? result : [{ value: "", label: "---" }].concat(result);
};

const getSubUnitHierarchy = (
  operationalUnit: OperationalUnitDto | undefined,
  rootOperationalUnit: OperationalUnitDto,
  operationalUnits: OperationalUnitDto[],
  depth: number
): (OperationalUnitDto & { depth: number })[] => {
  if (rootOperationalUnit === operationalUnit) return [];
  const subUnits = operationalUnits.filter((unit) => unit.parentUnitNumber === rootOperationalUnit.unitNumber);
  let result = [{ ...rootOperationalUnit, depth }];
  subUnits.forEach((subUnit) => {
    const subUnitHierarchy = getSubUnitHierarchy(operationalUnit, subUnit, operationalUnits, depth + 1);
    result = result.concat(subUnitHierarchy);
  });
  return result;
};

const OperationalUnitForm = (props: OperationalUnitFormProps & ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);

  const saveOperationalUnit = async (ouToSave: OperationalUnitDto) => {
    let result;
    if (ouToSave.unitNumber) {
      result = await props.thunkUpdateOperationalUnit(ouToSave);
    } else {
      result = await props.thunkCreateOperationalUnit(ouToSave);
    }
    if (result) setRedirect(true);
  };

  const validateForm = (values: OperationalUnitDto) => {
    const errors: ValidationErrors = {
      contact: {
        address: {},
      },
    };
    validateRequiredFields(["unitName", "externalUnitNumber", "unitType"], values, errors);
    if (values.parentUnitNumber && values.unitType === OperationalUnitType.TRADE_ASSOCIATION) {
      errors.unitType = "Es kann nur einen Unternehmerverband geben";
    }
    const phoneNumberRegex = new RegExp("^\\+?[\\d\\s]*$");
    if (values.contact?.address) {
      validateRequiredFields(
        ["streetName", "cityName", "zipCode", "country"],
        values.contact?.address,
        errors.contact.address
      );
    }
    if (values.contact?.phoneNumber && !phoneNumberRegex.test(values.contact?.phoneNumber)) {
      errors.contact.phoneNumber = "Bitte geben Sie eine valide Telefonnummer ein";
    }
    validateEmail(values.contact?.mailAddress, errors, "contact.mailAddress");
    return errors;
  };

  const validateRequiredFields = (fields: string[], values: any, errors: any) => {
    fields.forEach((field) => {
      if (isBlank(values[field])) {
        errors[field] = "Pflichtfeld";
      }
    });
  };

  const { operationalUnit, operationalUnits, operationalUnitTypes } = props;
  if (redirect) {
    return <Navigate to={UrlPathOperationalUnit} />;
  }

  if (!operationalUnits.length) return null;

  const operationalUnitOptions = getOperationalUnitOptions(operationalUnit, operationalUnits, theme, true, false);

  const allowedUnitTypeOptions = operationalUnitTypes.map((value) => ({
    value,
    label: unitTypeOptions.find((option) => option.value === value)?.label || value,
  }));
  return (
    <Form
      onSubmit={saveOperationalUnit}
      initialValues={operationalUnit}
      validate={validateForm}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Paper
            style={{
              padding: theme.spacing(3),
              marginTop: theme.spacing(6),
            }}
          >
            <ImsPaperHead text="Wie lauten die Filialdetails?" />
            <Grid container spacing={4}>
              <Grid item container spacing={2}>
                <Grid item md={12}>
                  <b>Übergeordnete Filiale/-gruppe</b>
                </Grid>
                <Grid item sm={6}>
                  <FormSelect name="parentUnitNumber" label="Filiale/-gruppe" options={operationalUnitOptions} />
                </Grid>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item md={12}>
                  <b>Filiale</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput variant="outlined" fullWidth={true} type="text" name="unitName" label="Name" />
                </Grid>
                <Grid item sm={3}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name="externalUnitNumber"
                    label="Externe ID"
                  />
                </Grid>
                <Grid item sm={3}>
                  <FormSelect
                    name="unitType"
                    fullWidth={true}
                    label="Filiale/-gruppentyp"
                    options={allowedUnitTypeOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            style={{
              padding: theme.spacing(3),
              margin: theme.spacing(6, 0, 3, 0),
            }}
          >
            <ImsPaperHead text="Kontaktperson" />
            <Grid container spacing={4}>
              <Grid item container spacing={2}>
                <Grid item md={12}>
                  <b>Kontaktperson</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput variant="outlined" fullWidth={true} type="text" name="contact.firstName" label="Vorname" />
                </Grid>
                <Grid item sm={4}>
                  <FormInput variant="outlined" fullWidth={true} type="text" name="contact.lastName" label="Nachname" />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name="contact.phoneNumber"
                    label="Telefon"
                  />
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name="contact.mailAddress"
                    label="E-Mail"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item md={12}>
                  <b>Adresse</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name="contact.address.streetName"
                    label="Straße"
                  />
                </Grid>
                <Grid item sm={2}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name="contact.address.houseNumber"
                    label="Hausnummer"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name="contact.address.zipCode"
                    label="Postleitzahl"
                  />
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name="contact.address.cityName"
                    label="Stadt"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item md={4}>
                  <FormSelect name="contact.address.country" label="Land" options={countryList} fullWidth={true} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(0, 0, 2, 2) }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(0, 0, 2, 2) }}
            >
              Speichern
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  operationalUnits: state.operationalUnits.operationalUnits,
  operationalUnitTypes: state.operationalUnits.operationalUnitTypes,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateOperationalUnit,
      thunkCreateOperationalUnit,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(OperationalUnitForm);
