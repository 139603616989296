import { Box, Button, CssBaseline, Grid, Paper, Theme, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathMain, UrlPathCustomerSearch } from "../../api/url";
import { thunkLogin } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { LoginDto } from "../../store/models/user/LoginDto";
import { FormInput } from "../atoms/FormInput";
// import harborBackground from "../atoms/images/harbor-background.png";
import harborNewYearBackground from "../atoms/images/harbor-new-year-background.png";
import { KNISTR_BLUE } from "../atoms/ImsMaterialTheme";
import { isBlank, nameof, ValidationErrors } from "../atoms/Utils";
import NotificationBar from "../notification/NotificationBar";
import { canReadDashboard } from "../../store/actions/LoginActions";

const validateForm = (values: LoginDto) => {
  const errors: ValidationErrors<LoginDto> = {};
  if (isBlank(values.username)) {
    errors.username = "Pflichtfeld";
  }
  if (isBlank(values.password)) {
    errors.password = "Pflichtfeld";
  }
  return errors;
};

const LoginPage = (props: ThunkProps) => {
  const theme = useTheme();
  const classes = styles(theme);
  const navigate = useNavigate();

  const login = async (loginDto: LoginDto) => {
    const result = await props.thunkLogin(loginDto);
    if (result) {
      if (canReadDashboard()) {
        navigate(UrlPathMain);
      } else {
        navigate(UrlPathCustomerSearch);
      }
    }
  };

  return (
    <>
      <NotificationBar />
      <CssBaseline />

      <Form
        onSubmit={login}
        validate={validateForm}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Paper sx={classes.login}>
              <Grid container spacing={3} style={{ padding: theme.spacing(2) }}>
                <Grid item style={{ fontWeight: "bold" }}>
                  <b>Anmelden</b>
                </Grid>
                <Grid item container>
                  <FormInput
                    fullWidth={true}
                    variant="outlined"
                    label="Benutzername"
                    type="text"
                    name={nameof<LoginDto>("username")}
                    autoComplete="username"
                  />
                </Grid>
                <Grid item container>
                  <FormInput
                    fullWidth={true}
                    variant="outlined"
                    label="Passwort"
                    type="password"
                    name={nameof<LoginDto>("password")}
                    autoComplete="current-password"
                  />
                </Grid>

                <Grid item container justifyContent="flex-end">
                  <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                    Anmelden
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      />
      <Box component="div" sx={classes.blueBackground}>
        <Box component="div" sx={classes.pictureBackground}></Box>
      </Box>
    </>
  );
};

const styles = (theme: Theme) => ({
  blueBackground: {
    backgroundColor: KNISTR_BLUE,
  },
  pictureBackground: {
    width: "100%",
    minHeight: "100vh",
    maxWidth: "100%",
    backgroundImage: `url(${harborNewYearBackground})`,
    backgroundSize: "cover",
    opacity: 0.35,
  },
  login: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "376px",
    marginTop: "-200px",
    marginLeft: "-188px",
    zIndex: 1001,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkLogin,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(LoginPage);
