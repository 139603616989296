import { Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { InteractionKpiDto } from "../../store/models/dashboard/InteractionKpiDayDto";
import { KNISTR_GREEN, KNISTR_PLUM } from "../atoms/ImsMaterialTheme";
import { formatGermanDate, formatGermanTime, nameof } from "../atoms/Utils";
import { isToday } from "./BonusPanel";
import { chartColors } from "./MainDashboardPage";

const BonusChart = (props: ThunkProps) => {
  const theme = useTheme();
  const { interactionKpiDay } = props;
  if (!interactionKpiDay) return null;
  return (
    <div style={{ width: "100%", height: 300 }}>
      <Typography variant="h4" style={{ paddingLeft: theme.spacing(8) }}>
        Interaktionen im Zeitverlauf für den {formatGermanDate(interactionKpiDay.date)}
        {isToday(interactionKpiDay.date) && <small> (Stand {formatGermanTime(new Date())})</small>}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={interactionKpiDay.kpiPerHour}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 20,
          }}
        >
          {chartColors()}
          <CartesianGrid strokeDasharray={3} />
          <XAxis dataKey="hour" padding={{ left: 10, right: 10 }} alignmentBaseline="before-edge" />
          <YAxis type="number" allowDataOverflow />
          <Tooltip />
          <Legend />
          <Bar
            name="gesammelte Punkte"
            dataKey={nameof<InteractionKpiDto>("bonusPointsEarned")}
            stroke={KNISTR_GREEN}
            fill={KNISTR_GREEN + "A0"}
          />
          <Bar
            name="verbrannte Punkte"
            dataKey={nameof<InteractionKpiDto>("bonusPointsBurned")}
            stroke={KNISTR_PLUM}
            fill={KNISTR_PLUM + "A0"}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  interactionKpiDay: state.dashboard.interactionKpiDay,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusChart);
