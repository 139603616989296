import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathConfigValueEdit } from "../../../api/url";
import { thunkDeleteConfigValue } from "../../../store/actions/ConfigGroupActions";
import { canMaintainConfig } from "../../../store/actions/LoginActions";
import { IStore } from "../../../store/IStore";
import { ConfigValueDto } from "../../../store/models/config/ConfigDto";
import ImsConfirmDialog from "../../atoms/ImsConfirmDialog";
import { getUrlByTab } from "../Setup";
import { ConfigValueProps } from "./ConfigValueFormPage";

interface ConfigValueTableMenuProps {
  configValue: ConfigValueDto;
}

function ConfigValueTableMenu(props: ConfigValueTableMenuProps & ConfigValueProps & ThunkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  if (!canMaintainConfig()) return null;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  return (
    <div>
      {confirmVisible && (
        <ImsConfirmDialog
          article="den"
          word={`Datensatz für ${props.entityName}`}
          verb="löschen"
          onConfirm={() => props.thunkDeleteConfigValue(props.configValue, props.configGroupKey)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link
            component={RouterLink}
            to={UrlPathConfigValueEdit(getUrlByTab(props.entityName)).replace(":id", props.configValue.key)}
          >
            Bearbeiten
          </Link>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setConfirmVisible(true);
            handleClose(e);
          }}
        >
          Löschen
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkDeleteConfigValue }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(ConfigValueTableMenu);
