import { AnyAction } from "redux";
import { LOGIN_STATE } from "../actions/LoginActions";

export interface ILoginReducerState {
  loginState?: boolean;
}

export const login = (state: ILoginReducerState, action: AnyAction): ILoginReducerState => {
  switch (action.type) {
    case LOGIN_STATE:
      return {
        ...state,
        loginState: action.payload!,
      };
    default:
      return state || {};
  }
};
