import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UrlAppPrefix } from "../../../api/url";

/**
 * Custom handle for highlighting menu
 *
 * The setup and appcontent menu contains many kinds of page. If the current page is belongs to them,
 * then the menu must be highlighted.
 *
 * @param to The target link of the menu
 */
export default function useMenuMatch(to: string) {
  const [isMenuMatch, setIsMenuMatch] = useState(false);
  const currentPath = useLocation().pathname;

  useEffect(() => {
    //The next line remove the "/loyalty/" prefix. E.g: /loyalty/dashboard => dashboard
    let removedPrefixCurrentPath = currentPath.substring(UrlAppPrefix.length);
    //Get the first path. E.g: setup/circle/create => setup
    const firstPath = removedPrefixCurrentPath.split("/")[0];

    if (to === UrlAppPrefix && to === currentPath) {
      //We're in the homepage => set the default "/" as highlighted
      setIsMenuMatch(true);
    } else if (
      to
        .split("/")
        .filter((path) => path.length !== 0)
        .includes(firstPath)
    ) {
      setIsMenuMatch(true);
    } else {
      setIsMenuMatch(false);
    }
  }, [currentPath, to]);

  return isMenuMatch;
}
