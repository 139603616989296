export const dialogStyles = {
  "@global": {
    "@keyframes scrollIn": {
      from: {
        transform: "rotateY(90deg)",
      },
      to: {
        transform: "perspective(1200px) rotateY(0deg)",
      },
    },
  },
  scrollIn: {
    animation: "$scrollIn .3s ease-in-out",
  },
};
