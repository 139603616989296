import { AnyAction } from "redux";
import { GET_GDPR_TEMPLATES, UPDATE_GDPR_TEMPLATES } from "../actions/GdprActions";
import { GdprTemplates } from "../models/gdpr/GdprTemplate";

export interface IGdprReducerState {
  gdprTemplates: GdprTemplates;
}

export const gdpr = (state: IGdprReducerState, action: AnyAction): IGdprReducerState => {
  switch (action.type) {
    case GET_GDPR_TEMPLATES:
    case UPDATE_GDPR_TEMPLATES:
      return {
        ...state,
        gdprTemplates: action.payload,
      };
    default:
      return state || {};
  }
};
