import { BooleanThunk } from "../IStore";

export const MENU_STATE = "MENU_STATE";

export const thunkToggleMenu = (): BooleanThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch({
      type: MENU_STATE,
      payload: !state.menu.open,
    });
    return true;
  } catch (e) {
    return false;
  }
};
