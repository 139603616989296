import { Dialog, DialogContent, DialogTitle, Divider, Grid, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkClearReceiptDetails } from "../../store/actions/CustomerCareActions";
import { IStore } from "../../store/IStore";
import { CustomerInteractionDto, InteractionType } from "../../store/models/customer/CustomerInteractionDto";
import { ReceiptDetailsDto } from "../../store/models/customer/ReceiptDetailsDto";
import { dialogStyles } from "../atoms/DialogStyle";
import ImsTable, { ImsHeadCell, ImsTableRow } from "../atoms/ImsEmbeddedTable";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import { formatEuro, formatGermanDate, formatGermanDateTime } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";

interface ReceiptDialogProps extends ThunkProps {
  interaction: CustomerInteractionDto;
  receiptDetails?: ReceiptDetailsDto;
  setReceiptDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReceiptDialog = (props: ReceiptDialogProps) => {
  const theme = useTheme();
  const getOperationalUnitName = (externalUnitNumber?: string) =>
    props.operationalUnits.find((operationalUnit) => operationalUnit.externalUnitNumber === externalUnitNumber)
      ?.unitName;

  const bonusHeadCells: ImsHeadCell[] = [
    { title: "Bonusregel", align: "left" },
    { title: "verfügbar ab", align: "left" },
    { title: "Punkte", align: "right", headAlign: "right" },
  ];

  const bonusRows: ImsTableRow[] | undefined = props.interaction.rewardedInteractions?.map((row, index) => ({
    key: index,
    nodes: [<>{row.bonusReason}</>, <>{formatGermanDate(row.validFrom)}</>, <>{row.bonusPoints}</>],
  }));

  const bonusSum = props.interaction.rewardedInteractions
    ?.map((rewardedInteraction) => Number(rewardedInteraction.bonusPoints))
    .reduce((a, b) => a + b, 0);

  const headCells: ImsHeadCell[] = [
    { title: "Artikelbeschreibung", align: "left" },
    { title: "Anzahl", align: "right" },
    { title: "Preis", align: "right", headAlign: "right" },
  ];

  const rows: ImsTableRow[] | undefined = props.receiptDetails?.receiptDetails.map((row, index) => ({
    key: index,
    nodes: [
      row.itemInfoList
        .filter((itemInfo) => itemInfo.value)
        .map((itemInfo) => (
          <Grid container>
            <Grid item sm={7}>
              <Typography variant="subtitle1">{itemInfo.description}</Typography>
            </Grid>
            <Grid item sm={5}>
              {itemInfo.value}
            </Grid>
          </Grid>
        )),
      <>{Number(row.quantity)}</>,
      <>{formatEuro(row.salesPrice)}</>,
    ],
  }));

  return (
    <Dialog
      onClose={() => {
        props.thunkClearReceiptDetails();
        props.setReceiptDialogVisible(false);
      }}
      open={true}
      fullWidth={false}
      maxWidth="md"
      PaperProps={{
        sx: dialogStyles.scrollIn,
      }}
    >
      <NotificationList isDialog />
      <DialogTitle style={{ padding: theme.spacing(0) }}>
        <Grid container style={{ padding: theme.spacing(3) }}>
          {props.interaction?.interactionType === InteractionType.REVERSAL_INTERACTION ? (
            <b>Details zur Rückgabe</b>
          ) : (
            <b>Details zum Einkauf</b>
          )}
        </Grid>

        <Divider
          style={{
            color: COLOR_GRAY_1,
            height: "2px",
          }}
          light={true}
        />

        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item sm={6}>
            <Typography variant="subtitle1">Datum / Uhrzeit</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body1">
              {props.interaction.interactionTimestamp &&
                formatGermanDateTime(new Date(props.interaction.interactionTimestamp))}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="subtitle1">Filiale</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body1">{getOperationalUnitName(props.interaction.externalUnitNumber)}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="subtitle1">Belegnummer</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body1">{props.interaction.externalReceiptId}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="subtitle1">Pass-ID</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body1">{props.interaction.externalIdentificationNumber}</Typography>
          </Grid>
          {!rows ? (
            <Grid container>
              <Grid item sm={6}>
                <Typography variant="subtitle1">Umsatz</Typography>
              </Grid>
              <Grid item sm={6}>
                {formatAmount(props.interaction)}
              </Grid>
              <p style={{ paddingLeft: theme.spacing(20) }}>Es sind keine Bondaten vorhanden</p>
            </Grid>
          ) : (
            <Divider
              style={{
                color: COLOR_GRAY_1,
                height: "2px",
              }}
              light={true}
            />
          )}
        </Grid>
      </DialogTitle>

      {props.interaction && bonusRows?.length ? (
        <>
          <DialogContent
            style={{
              padding: theme.spacing(0),
              overflow: "hidden",
              display: "table",
            }}
          >
            <ImsTable headCells={bonusHeadCells} rows={bonusRows} verticalAlign="top" compact />
          </DialogContent>
          {bonusSum && (
            <Grid
              container
              justifyContent="flex-end"
              style={{
                padding: theme.spacing(2, 3),
              }}
            >
              <b>Summe: {bonusSum}</b>
            </Grid>
          )}
          <Divider style={{ color: COLOR_GRAY_1, height: "2px" }} light={true} />
        </>
      ) : null}

      {rows && (
        <>
          <DialogContent
            style={{
              padding: theme.spacing(0),
              overflow: "hidden",
              display: "flex",
            }}
          >
            <ImsTable headCells={headCells} rows={rows} verticalAlign="top" />
          </DialogContent>
          <Divider style={{ color: COLOR_GRAY_1, height: "2px" }} light={true} />

          <Grid
            container
            justifyContent="flex-end"
            style={{
              padding: theme.spacing(2),
            }}
          >
            {props.interaction?.interactionType === InteractionType.REVERSAL_INTERACTION ? (
              <b> Summe: {formatEuro(props.interaction.reversalAmount)} </b>
            ) : (
              <b> Summe: {formatEuro(props.interaction.paymentAmount)} </b>
            )}
          </Grid>
        </>
      )}
    </Dialog>
  );
};

function formatAmount(interaction: CustomerInteractionDto) {
  const amount = Number(interaction.paymentAmount) || Number(interaction.reversalAmount) * -1;
  return formatEuro(amount);
}

const mapStateToProps = (state: IStore) => ({
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkClearReceiptDetails }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(ReceiptDialog);
