import { DateTimeFormatter, LocalDate, LocalDateTime, nativeJs, ZonedDateTime } from "@js-joda/core";
import { EffectCallback, useEffect } from "react";

export const GERMAN_DATE_FORMAT = "dd.MM.yyyy";
export const GERMAN_TIME_FORMAT = "HH:mm:ss";

const apiDateFormatter = DateTimeFormatter.ofPattern("yyyy-MM-dd");
const apiDateTimeFormatter = DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss");
const germanDateFormatter = DateTimeFormatter.ofPattern(GERMAN_DATE_FORMAT);
const germanDateTimeFormatter = DateTimeFormatter.ofPattern(`${GERMAN_DATE_FORMAT} ${GERMAN_TIME_FORMAT}`);
const germanTimeFormatter = DateTimeFormatter.ofPattern(GERMAN_TIME_FORMAT);

export const formatGermanDate = (dateString?: string) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return LocalDate.from(nativeJs(date)).format(germanDateFormatter);
};

export const formatGermanDateTime = (date: Date) => {
  return ZonedDateTime.from(nativeJs(date)).format(germanDateTimeFormatter);
};

export const formatGermanTime = (date: Date) => {
  return ZonedDateTime.from(nativeJs(date)).format(germanTimeFormatter);
};

export const formatApiDate = (date: Date) => {
  if (!date || isNaN(date.valueOf())) return;
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return LocalDate.from(nativeJs(date)).format(apiDateFormatter);
};

export const formatIsoDate = (date: Date) => date.toISOString();

export const getCurrentDateAsString = (): string => apiDateFormatter.format(LocalDate.now());
export const getCurrentDateTimeAsString = (): string => apiDateTimeFormatter.format(LocalDateTime.now());

export const getTomorrowDateAsString = (): string => apiDateFormatter.format(LocalDate.now().plusDays(1));

export const getToday = (): Date => {
  const date = new Date();
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const getYearFromTimestamp = (timestamp: string): number | undefined => {
  const year = new Date(timestamp).getFullYear();
  return year ? year : undefined;
};

export const getCurrentYear = (): number => {
  return new Date().getFullYear();
};

export const getCurrentMonth = (): number => {
  return new Date().getMonth() + 1;
};

export const getTomorrow = (): Date => {
  const date = new Date();
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 1));
};

export const isBlankOrEmpty = (str: string | undefined | null): boolean => {
  return str == null || (typeof str === "string" && str.trim().length === 0);
};

export const isPositiveInteger = (str: string | number | undefined | null) => {
  const n = Math.floor(Number(str));
  return n !== Infinity && (String(n) === str || n === str) && n > 0;
};

export const isValidMailAddress = (mailAddress?: string) => {
  const mailAddressRegex = /^[a-zA-ZÀ-ÿ0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}/;
  return !!mailAddress && mailAddressRegex.test(mailAddress);
};

export const isValidZipCode = (zipCode?: string) => {
  const zipCodeRegex = /.{4,}/;
  return !!zipCode && zipCodeRegex.test(zipCode);
};

export const formatNumber = (value: any): string => {
  if (typeof value === "string") value = value.replace(",", ".");
  return (typeof value === "number" && !isNaN(value)) || !isNaN(parseFloat(value))
    ? numberFormatter.format(parseFloat(value)).replace(/,/g, ".")
    : "";
};

// we use english locale because german locale is sometimes missing
const numberFormatter = new Intl.NumberFormat("en", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true,
});

export const formatNumberOrDefault = (value?: string, defaultValue: string = "-"): string => {
  return !isBlankOrEmpty(value) ? formatNumber(value) : defaultValue;
};

export const formatEuroOrDefault = (value?: string, defaultValue: string = "-"): string => {
  return !isBlankOrEmpty(value) ? formatEuro(value) : defaultValue;
};

export const formatEuro = (value: any): string => {
  if (typeof value === "string") value = value.replace(",", ".");
  return (typeof value === "number" && !isNaN(value)) || !isNaN(parseFloat(value))
    ? germanSeparators(euroFormatter.format(parseFloat(value))) + "\xA0€"
    : "";
};

export function germanSeparators(str: string) {
  str = str.replace(/,/g, ".");
  return str.replace(/\.([^.]*)$/, ",$1");
}

// we use english locale because german locale is sometimes missing
export const euroFormatter = new Intl.NumberFormat("en", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
});

export const nameof = <T>(name: keyof T) => name;
export const nameof2 = <T1, T2>(name1: keyof T1, name2: keyof T2) => String(name1) + "." + String(name2);
// https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once/56767883#56767883
export const useMountEffect = (fun: EffectCallback) => useEffect(fun, []); // eslint-disable-line react-hooks/exhaustive-deps
export type ValidationErrors<T> = {
  [P in keyof T]?: string;
};

export const isBlank = (value?: string) => !value || (typeof value === "string" && !value.trim());

const europeanCountriesCode = [
  "AL",
  "AD",
  "AT",
  "BE",
  "BA",
  "BG",
  "HR",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IS",
  "IE",
  "IT",
  "LV",
  "LI",
  "LT",
  "LU",
  "MT",
  "MD",
  "MC",
  "ME",
  "NL",
  "MK",
  "NO",
  "PL",
  "PT",
  "RO",
  "SM",
  "RS",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "GB",
];

export const getEuropeanCountries = (countries: unknown) =>
  Object.entries(countries as { k: string; name: any })
    .map(([k, v]) => ({
      value: k,
      label: v.name,
    }))
    .filter((e) => europeanCountriesCode.includes(e.value))
    .sort((a, b) => a.label.localeCompare(b.label));

export interface Options {
  value: string | number;
  label: string;
}
