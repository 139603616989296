import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { DateTag } from "../atoms/DateTag";
import ImsEmbeddedTable, { ImsHeadCell, ImsTableRow } from "../atoms/ImsEmbeddedTable";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import { formatEuro, formatNumber } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";
import { getTotalBonusPoints } from "./Bonus";
import { formatBonusPoints } from "./KeyFigures";
import { formatCustomerName, formatInteractionCol, PaymentModel } from "./PaymentModel";

interface BonusDialogProps extends ThunkProps {
  closeDialog: () => void;
  bonusPayments: PaymentModel[];
}

const BonusDialog = (props: BonusDialogProps) => {
  const theme = useTheme();
  const headCells: ImsHeadCell[] = [
    {
      title: "Datum",
      align: "left",
      cellStyle: { verticalAlign: "top" },
    },
    {
      title: "Interaktion",
      align: "left",
      cellStyle: { verticalAlign: "top" },
    },
    {
      title: "Mitglied",
      align: "center",
      cellStyle: { verticalAlign: "top" },
    },
    {
      title: "Betrag",
      align: "center",
      cellStyle: { verticalAlign: "bottom" },
    },
    {
      title: "Punkte",
      align: "center",
      cellStyle: { verticalAlign: "bottom" },
    },
    {
      title: "Neue Summe",
      align: "center",
      cellStyle: { verticalAlign: "bottom" },
    },
  ];

  const rows: ImsTableRow[] = props.bonusPayments.map((row, index) => ({
    value: row,
    nodes: [
      <DateTag date={row.interactionTimestamp} />,
      formatInteractionCol(row),
      formatCustomerName(row.customerNumber, props.family),
      <>{formatEuro(row.displayedPaymentAmount || row.displayedCashValue)}</>,
      formatBonusPoints(row.bonusPoints),
      row.totalBonusPoints,
    ],
    key: index,
  }));

  return (
    <Dialog onClose={props.closeDialog} open={true} fullWidth={true} maxWidth="lg">
      <NotificationList isDialog />
      <DialogTitle style={{ padding: theme.spacing(3) }}>
        <Grid container>
          <Grid item md={6}>
            <b>Bonuspunkte</b>
          </Grid>
          <Grid item md={6}>
            <Typography align="right" variant="h2">
              {formatNumber(getTotalBonusPoints(props.bonusPayments))}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider style={{ color: COLOR_GRAY_1, height: "2px" }} light={true} />
      <DialogContent
        style={{
          padding: theme.spacing(0),
          overflow: "hidden",
          display: "flex",
        }}
      >
        {props.bonusPayments.length ? (
          <ImsEmbeddedTable headCells={headCells} rows={rows} />
        ) : (
          <p>keine Interaktionsdaten gefunden</p>
        )}
      </DialogContent>
      <Divider style={{ color: COLOR_GRAY_1, height: "2px" }} light={true} />
      <Grid
        container
        justifyContent="flex-end"
        style={{
          padding: theme.spacing(2),
        }}
      >
        <Button
          id="btndone"
          color="primary"
          type="button"
          variant="contained"
          style={{ cursor: "pointer" }}
          onClick={props.closeDialog}
        >
          FERTIG
        </Button>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({
  family: state.customerCare.family,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusDialog);
