import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../IStore";
import { PassMediumDto } from "../models/passmedium/PassMediumDto";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";

export const GET_PASS_MEDIUM_BY_NUMBER = "GET_PASS_MEDIUM_BY_NUMBER";
export const GET_ALL_PASS_MEDIUMS = "GET_ALL_PASS_MEDIUMS";
export const DELETE_PASS_MEDIUM = "DELETE_PASS_MEDIUM";

export const thunkGetAllPassMediums = (): BooleanThunk => async (dispatch) => {
  try {
    const passMediums = await Api.getAllPassMediums();
    dispatch({
      type: GET_ALL_PASS_MEDIUMS,
      payload: passMediums,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler beim Laden der Pass Mediums", e));
    return false;
  }
};

export const thunkGetPassMediumByNumber =
  (passMediumNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const passMedium = await Api.getPassMediumByNumber(passMediumNumber);
      dispatch({
        type: GET_PASS_MEDIUM_BY_NUMBER,
        payload: passMedium,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler beim Laden des Pass Mediums", e));
      return false;
    }
  };

export const thunkCreatePassMedium =
  (passMediumToCreate: PassMediumDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createPassMedium(passMediumToCreate);
      dispatch(thunkGetAllPassMediums() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Das Pass Medium wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler bei der Erstellung des Pass Mediums", e));
      return false;
    }
  };

export const thunkUpdatePassMedium =
  (passMedium: PassMediumDto, passMediumNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updatePassMedium(passMedium, passMediumNumber);
      dispatch(thunkGetAllPassMediums() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Das Pass Medium wurde aktualisiert"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler beim Aktualisieren des Pass Mediums", e));
      return false;
    }
  };

export const thunkDeletePassMedium =
  (passMedium: PassMediumDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deletePassMedium(passMedium);
      dispatch({
        type: DELETE_PASS_MEDIUM,
        payload: passMedium,
      });
      dispatch(thunkCreateSuccessNotification("Das Pass Medium wurde gelöscht"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler beim Löschen des Pass Mediums", e));
      return false;
    }
  };
