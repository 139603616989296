import { Button, Grid, Paper, useTheme } from "@mui/material";
import countries from "iso3166-2-db/countryList/dispute/UN/de.json";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { utils, write } from "xlsx";
import { getReport } from "../../api/SnowflakeApi";
import { IStore } from "../../store/IStore";
import { ReportsDto, ReportTranslation } from "../../store/models/reports/ReportsDto";
import MenuLayout from "../app/MenuLayout";
import { FormDate } from "../atoms/FormDate";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { Options, ValidationErrors } from "../atoms/Utils";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";

export const view: string = "V_APPL_REP_KPIS";

export const ReportPage = (props: ReportPageThunkProps) => {
  const theme = useTheme();
  const { operationalUnits } = props;
  const [branchObject, setBranchObject] = useState({});
  const [countryList, setCountryList] = useState<Options[]>([]);
  const [csvOrExcel, setCsvOrExcel] = useState(true);
  const [formVal, setFormVal] = useState<ReportsDto>({
    metric: ["all"],
    branch: [],
  });

  useEffect(() => {
    reportingSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationalUnits]);

  const getMetrics = (): Options[] => {
    let metrics: Options[] = Object.keys(ReportTranslation).map((key) => {
      return {
        label: ReportTranslation[key as keyof typeof ReportTranslation],
        value: key,
      };
    });
    metrics.unshift({
      label: "Alle Metriken",
      value: "all",
    });
    return metrics;
  };

  const reportingSetup = () => {
    let branchObj: {
      [key: string]: Options;
    } = {};
    let countryObj: {
      [key: string]: Options;
    } = {};
    operationalUnits.forEach((branch) => {
      branchObj[branch.unitNumber] = {
        value: branch.unitNumber,
        label: branch.unitName,
      };

      if (branch?.contact?.address?.country) {
        countryObj[branch.contact.address.country] = {
          value: branch.contact.address.country,
          label: branch.contact.address.country,
        };
      }
    });

    setBranchObject(branchObj);

    setCountryList(
      Object.keys(countryObj).map((key) => {
        return {
          value: countryObj[key].value,
          label: countries[key as keyof typeof countries].name,
        };
      })
    );
  };

  const formatDatesForApi = (requestReport: ReportsDto): ReportsDto => {
    requestReport.from = requestReport?.from?.replace("-", "");
    requestReport.from = requestReport?.from?.replace("-", "");
    requestReport.to = requestReport?.to?.replace("-", "");
    requestReport.to = requestReport?.to?.replace("-", "");
    return requestReport;
  };

  const exportToExcel = async (requestReport: ReportsDto) => {
    requestReport = formatDatesForApi(requestReport);
    const report = await getReport(
      view,
      requestReport.metric,
      requestReport.branch,
      requestReport.country,
      requestReport.from,
      requestReport.to,
      9999,
      {},
      branchObject
    );
    const worksheet = utils.json_to_sheet(report.data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Report");
    const data = write(workbook, { type: "base64" });
    const element = document.createElement("a");
    element.setAttribute("href", "data:application/xlsx;base64," + data);
    element.setAttribute("download", "report.xlsx");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const exportToCsv = async (requestReport: ReportsDto) => {
    requestReport = formatDatesForApi(requestReport);
    const report = await getReport(
      view,
      requestReport.metric,
      requestReport.branch,
      requestReport.country,
      requestReport.from,
      requestReport.to,
      9999,
      {},
      branchObject
    );
    const worksheet = utils.json_to_sheet(report.data);
    const data = utils.sheet_to_csv(worksheet);
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
    const element = document.createElement("a");
    element.setAttribute("href", URL.createObjectURL(blob));
    element.setAttribute("download", "report.csv");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const onSubmit = async (requestReport: ReportsDto) => {
    setFormVal(requestReport);
    if (csvOrExcel) {
      exportToCsv(requestReport);
    } else {
      exportToExcel(requestReport);
    }
  };

  const downloadCsv = () => {
    setCsvOrExcel(true);
  };

  const downloadExcel = () => {
    setCsvOrExcel(false);
  };

  const validateForm = (values: ReportsDto) => {
    const errors: ValidationErrors<ReportsDto> = {};

    if (!values.from) {
      errors.from = "Es muss ein Datum gewählt werden";
      return errors;
    }
    if (!values.to) {
      errors.to = "Es muss ein Datum gewählt werden";
      return errors;
    }
    if (new Date(Date.parse(values.from)) > new Date(Date.parse(values.to))) {
      errors.from = "Von muss vor Bis liegen";
      errors.to = "Bis muss nach Vor liegen";
    }
    return errors;
  };

  return (
    <MenuLayout headline="Reports">
      <Form
        onSubmit={onSubmit}
        validate={validateForm}
        initialValues={formVal}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                id="reportTable"
                style={{
                  padding: theme.spacing(3),
                  margin: theme.spacing(6, 0, 3, 0),
                }}
              >
                <ImsPaperHead text="Report konfigurieren" />
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: theme.spacing(0),
                  }}
                >
                  <Grid item md={2}>
                    <FormSelect name={"metric"} label="Metrik" multiple={true} fullWidth options={getMetrics()} />
                  </Grid>
                  <Grid item md={2}>
                    <FormSelect
                      name={"branch"}
                      label="Filiale"
                      multiple={true}
                      fullWidth
                      options={getOperationalUnitOptions(undefined, operationalUnits, theme, false, false)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormSelect name={"country"} label="Land" fullWidth options={countryList} />
                  </Grid>
                  <Grid item md={2}>
                    <FormDate name={"from"} label="Von" disableFuture={false} />
                  </Grid>
                  <Grid item md={2}>
                    <FormDate name={"to"} label="Bis" disableFuture={false} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{
                    paddingBottom: theme.spacing(2),
                    paddingTop: theme.spacing(2),
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    name={"action"}
                    style={{ marginRight: theme.spacing(2) }}
                    onMouseEnter={downloadExcel}
                  >
                    Excel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    name={"action"}
                    value={"test"}
                    variant="contained"
                    onMouseEnter={downloadCsv}
                  >
                    CSV
                  </Button>
                </Grid>
              </Paper>
            </form>
          );
        }}
      />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReportPageThunkProps = ConnectedProps<typeof connector>;
export default connector(ReportPage);
