import { PassMediumDto } from "../models/passmedium/PassMediumDto";
import { AnyAction } from "redux";
import { DELETE_PASS_MEDIUM, GET_ALL_PASS_MEDIUMS, GET_PASS_MEDIUM_BY_NUMBER } from "../actions/PassMediumAction";

export interface IPassMediumReducerState {
  passMediums: PassMediumDto[];
}

export const passMediums = (state: IPassMediumReducerState, action: AnyAction): IPassMediumReducerState => {
  switch (action.type) {
    case GET_PASS_MEDIUM_BY_NUMBER:
      const getPassMedium = action.payload as PassMediumDto;
      return {
        ...state,
        passMediums: state.passMediums.map((passMedium) =>
          passMedium.passMediumNumber === getPassMedium.passMediumNumber ? getPassMedium : passMedium
        ),
      };
    case GET_ALL_PASS_MEDIUMS:
      return {
        ...state,
        passMediums: action.payload,
      };
    case DELETE_PASS_MEDIUM:
      return {
        ...state,
        passMediums: state.passMediums.filter(
          (passMedium) => passMedium.passMediumNumber !== action.payload.passMediumNumber
        ),
      };
    default:
      return state || { passMediums: [] };
  }
};
