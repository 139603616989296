import { Button, Grid } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { CashbackRateRuleDto } from "../../store/models/cashback/CashbackRateRuleDto";
import { useState } from "react";
import CashbackRateRuleDialog, { RuleRateDialogMode } from "./CashbackRateRuleDialog";
import { formatEuro } from "../atoms/Utils";
import { Delete, Edit } from "@mui/icons-material";
import { v4 as uuid } from "uuid";

interface CashbackRuleTableProps {
  cashbackRateRuleList: CashbackRateRuleDto[];
}

const CashbackRuleTable = (props: CashbackRuleTableProps & ThunkProps) => {
  const [ruleRateDialogMode, setRuleRateDialogMode] = useState<RuleRateDialogMode>(RuleRateDialogMode.HIDDEN);
  const [ruleEditCashbackRateRule, setEditCashbackRateRule] = useState<CashbackRateRuleDto>();

  const updateCashbackRateRule = async (cashbackRateRule: CashbackRateRuleDto) => {
    const index = props.cashbackRateRuleList.findIndex(
      (r) => r.cashbackRateRuleNumber === cashbackRateRule.cashbackRateRuleNumber
    );
    if (index !== -1) {
      props.cashbackRateRuleList[index] = cashbackRateRule;
    } else {
      cashbackRateRule.cashbackRateRuleNumber = uuid();
      props.cashbackRateRuleList.push(cashbackRateRule);
    }
    return true;
  };

  const deleteCashbackRateRule = async (cashbackRateRule: CashbackRateRuleDto) => {
    const index = props.cashbackRateRuleList.findIndex(
      (r) => r.cashbackRateRuleNumber === cashbackRateRule.cashbackRateRuleNumber
    );
    if (index !== -1) {
      props.cashbackRateRuleList.splice(index, 1);
    }
    return true;
  };

  const headCells: ImsHeadCell<CashbackRateRuleDto>[] = [
    { val: (val) => val.cashbackRatePercent, node: "Cashback-Rate" },
    { val: (val) => val.minimumEarnAmount, node: "Minimale Summe" },
    { val: (val) => val.maximumEarnAmount, node: "Maximale Summe" },
    { val: (val) => val.cashbackRateRuleNumber, node: "" },
  ];

  const rows: ImsTableRow<CashbackRateRuleDto>[] = props.cashbackRateRuleList.map((row) => ({
    value: row,
    nodes: [
      <b>{row.cashbackRatePercent} %</b>,
      formatEuro(row.minimumEarnAmount),
      formatEuro(row.maximumEarnAmount),
      <>
        <Button
          onClick={() => {
            setEditCashbackRateRule(row);
            setRuleRateDialogMode(RuleRateDialogMode.EDIT);
          }}
        >
          <Edit />
        </Button>
        <Button
          color="error"
          onClick={() => {
            setEditCashbackRateRule(row);
            setRuleRateDialogMode(RuleRateDialogMode.DELETE);
          }}
        >
          <Delete />
        </Button>
      </>,
    ],
    disabled: false,
  }));

  return (
    <Grid>
      <Grid container item justifyContent="flex-end" alignItems="baseline" mb={4}>
        <Button
          startIcon="+"
          variant="contained"
          color="primary"
          onClick={() => {
            setEditCashbackRateRule(undefined);
            setRuleRateDialogMode(RuleRateDialogMode.NEW);
          }}
        >
          Neue Regel
        </Button>
      </Grid>

      {ruleRateDialogMode !== RuleRateDialogMode.HIDDEN && (
        <CashbackRateRuleDialog
          mode={ruleRateDialogMode}
          cashbackRateRule={ruleEditCashbackRateRule}
          onCloseDialog={() => setRuleRateDialogMode(RuleRateDialogMode.HIDDEN)}
          updateCashbackRateRule={updateCashbackRateRule}
          deleteCashbackRateRule={deleteCashbackRateRule}
        />
      )}

      <ImsTable hasMenu={true} headCells={headCells} rows={rows} />
    </Grid>
  );
};

const mapStateToProps = (state: IStore) => ({
  // cashbackRateRuleList: state.cashback.cashback?.cashbackRateRuleList,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CashbackRuleTable);
