import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { AdjustmentDto } from "../models/adjustment/AdjustmentDto";
import { reloadCustomerHistory } from "./HistoryActions";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";

export const thunkCreateAdjustmentEarn =
  (adjustment: AdjustmentDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createAdjustmentEarn(adjustment);
      dispatch(thunkCreateSuccessNotification("Die Kulanzgutschrift wurde erstellt."));
      reloadCustomerHistory(dispatch, adjustment.customerNumber);
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Die Kulanzgutschrift konnte nicht erstellt werden", e));
      return false;
    }
  };

export const thunkCreateAdjustmentBurn =
  (adjustment: AdjustmentDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createAdjustmentBurn(adjustment);
      dispatch(thunkCreateSuccessNotification("Die Belastung wurde erstellt."));
      reloadCustomerHistory(dispatch, adjustment.customerNumber);
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Die Belastung konnte nicht erstellt werden", e));
      return false;
    }
  };
