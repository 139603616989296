import { CouponDetailsDto } from "./CouponDetailsDto";

export interface BonusPremiumDto {
  premiumNumber?: string;
  premiumName?: string;
  premiumDescription?: string;
  premiumStatus: BonusPremiumStatus;
  unitNumber: string;
  startDate?: string;
  endDate?: string;
  couponDetails: BonusPremiumCouponDetailsDto;
}

export enum BonusPremiumStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export interface BonusPremiumCouponDetailsDto extends CouponDetailsDto {
  maxCoupons?: number;
  requiredBonusPoints?: number;
}
