import { Search } from "@mui/icons-material";
import { Button, Grid, Link, Paper, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerDetails } from "../../api/url";
import { thunkInteractionSearch } from "../../store/actions/InteractionActions";
import { IStore } from "../../store/IStore";
import {
  CustomerInteractionType,
  formatCustomerInteractionType,
} from "../../store/models/bonusRule/CustomerInteractionType";
import { InteractionType } from "../../store/models/customer/CustomerInteractionDto";
import { InteractionSearchDto } from "../../store/models/interactionSearch/InteractionSearchDto";
import { InteractionSearchResponseDto } from "../../store/models/interactionSearch/InteractionSearchResponseDto";
import {
  InteractionProcessingType,
  interactionProcessingTypeOptions,
  interactionTypeOptions,
} from "../../store/models/interactionSearch/Options";
import MenuLayout from "../app/MenuLayout";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { formatEuro, formatGermanDateTime, nameof } from "../atoms/Utils";
import { formatBonusPoints } from "../customerCare/KeyFigures";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";

const InteractionSearchPage = (props: ThunkProps) => {
  const theme = useTheme();
  const { operationalUnits, thunkInteractionSearch, interactions } = props;

  const operationalUnitOptions = getOperationalUnitOptions(undefined, operationalUnits, theme, false, true);

  const getInteractionTypeLabel = (val: InteractionSearchResponseDto) => {
    if (val.interactionType === InteractionType.CUSTOMER_INTERACTION) {
      return getCustomerInteractionLabel(val.customerInteractionType);
    }
    return interactionTypeOptions.find((option) => option.value === val.interactionType)?.label;
  };

  const getInteractionProcessingTypeLabel = (val: InteractionProcessingType) => {
    return interactionProcessingTypeOptions.find((option) => option.value === val)?.label;
  };

  const getCustomerInteractionLabel = (val: CustomerInteractionType) => {
    return formatCustomerInteractionType(val) || val;
  };

  const headCells: ImsHeadCell<InteractionSearchResponseDto>[] = [
    { val: (val) => val.interactionTimestamp, node: "Datum" },
    { val: (val) => val.lastName, node: "Name" },
    { val: (val) => val.externalCustomerId, node: "Kundennummer" },
    { val: (val) => val.externalUnitNumber, node: "Filiale" },
    { val: (val) => val.interactionType, node: "Interaktion" },
    { val: (val) => val.interactionProcessingType, node: "Typ" },
    { val: (val) => val.amount, node: "Betrag" },
    { val: (val) => val.points, node: "Punkte" },
  ];

  const rows: ImsTableRow<InteractionSearchResponseDto>[] | undefined = interactions?.map((row) => ({
    value: row,
    nodes: [
      <>{formatGermanDateTime(new Date(row.interactionTimestamp))}</>,
      `${row.firstName || ""} ${row.lastName || ""}`,
      <Link component={RouterLink} to={UrlPathCustomerDetails.replace(":id", row.customerNumber)}>
        {row.externalCustomerId}
      </Link>,
      <>
        {
          operationalUnits.find((operationalUnit) => operationalUnit.externalUnitNumber === row.externalUnitNumber)
            ?.unitName
        }
      </>,
      <>{getInteractionTypeLabel(row)}</>,
      <>{getInteractionProcessingTypeLabel(row.interactionProcessingType)}</>,
      <>{formatEuro(row.amount)}</>,
      <>{formatBonusPoints(row.points)}</>,
    ],
  }));

  return (
    <MenuLayout headline={"Interaktionen"}>
      <Form
        onSubmit={thunkInteractionSearch}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3, 3, 3, 3),
                  margin: theme.spacing(3, 0, 3, 0),
                }}
              >
                <ImsPaperHead
                  text="Interaktionsrecherche"
                  style={{
                    marginBottom: theme.spacing(2),
                  }}
                />

                <Grid container spacing={2}>
                  <Grid container item spacing={2}>
                    <Grid item md={3}>
                      <FormDate
                        name={nameof<InteractionSearchDto>("interactionDateFrom")}
                        label="Datum"
                        prefix="Von"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FormDate
                        name={nameof<InteractionSearchDto>("interactionDateTo")}
                        label="Datum"
                        prefix="Bis"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid container item spacing={2}>
                    <Grid item md={3}>
                      <FormInput
                        prefix="Von"
                        type="number"
                        fullWidth={true}
                        name={nameof<InteractionSearchDto>("amountFrom")}
                        label="Eurobetrag"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FormInput
                        prefix="Bis"
                        type="number"
                        fullWidth={true}
                        name={nameof<InteractionSearchDto>("amountTo")}
                        label="Eurobetrag"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={3}>
                      <FormInput
                        prefix="Von"
                        type="number"
                        fullWidth={true}
                        name={nameof<InteractionSearchDto>("pointsFrom")}
                        label="Bonuspunkte"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FormInput
                        prefix="Bis"
                        type="number"
                        fullWidth={true}
                        name={nameof<InteractionSearchDto>("pointsTo")}
                        label="Bonuspunkte"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={3}>
                      <FormInput
                        type="text"
                        fullWidth={true}
                        name={nameof<InteractionSearchDto>("receiptDetails")}
                        label="Beleginfo"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={3}>
                      <FormSelect
                        label="Filiale/Filialgruppe"
                        name={nameof<InteractionSearchDto>("externalUnitNumber")}
                        options={operationalUnitOptions}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={3}>
                      <FormSelect
                        label="Interaktionstyp"
                        name={nameof<InteractionSearchDto>("interactionType")}
                        options={interactionTypeOptions}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FormSelect
                        label="Earn/Burn"
                        name={nameof<InteractionSearchDto>("interactionProcessingType")}
                        options={interactionProcessingTypeOptions}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container style={{ marginBottom: theme.spacing(3) }} justifyContent="flex-end">
                <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                  <Search />
                  Suchen
                </Button>
              </Grid>
            </form>
          );
        }}
      />
      {rows &&
        (rows.length ? (
          <ImsTable hasMenu={false} headCells={headCells} rows={rows} sortColumnIndex={0} sortDirection="desc" />
        ) : (
          <p>keine Ergebnisse</p>
        ))}
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  operationalUnits: state.operationalUnits.operationalUnits,
  interactions: state.interactionSearch.interactions,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkInteractionSearch }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(InteractionSearchPage);
