import { Backdrop, CircularProgress } from "@mui/material";

interface BackdropProps {
  processing: boolean;
}

export const BackdropProcessing = (props: BackdropProps) => {
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.processing}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
