import { AnyAction } from "redux";
import { DELETE_FLOW, GET_ALL_FLOWS } from "../actions/FlowActions";
import { FlowGroupDto } from "../models/flow/FlowDto";

export interface IFlowReducerState {
  flows: FlowGroupDto[];
}

export const flows = (state: IFlowReducerState, action: AnyAction): IFlowReducerState => {
  switch (action.type) {
    case GET_ALL_FLOWS:
      return {
        ...state,
        flows: action.payload,
      };
    case DELETE_FLOW:
      const deletedFlow = action.payload as FlowGroupDto;
      return {
        ...state,
        flows: state.flows.filter((flow) => flow.flowGroupNumber !== deletedFlow.flowGroupNumber),
      };
    default:
      return state || { flows: [] };
  }
};
