import { AnyAction } from "redux";
import {
  ACTIVATE_BONUS_RULE,
  ADD_BONUS_RULE,
  ADD_BONUS_RULE_FILTER,
  DEACTIVATE_BONUS_RULE,
  DELETE_BONUS_RULE,
  DELETE_BONUS_RULE_FILTER,
  GET_BONUS_RULES,
  UPDATE_BONUS_RULE,
  UPDATE_BONUS_RULE_FILTER,
} from "../actions/BonusRuleActions";
import { FIND_PROPERTY_GROUP_VALUES } from "../actions/PropertyGroupActions";
import { BonusRuleDto, FilterDto, RuleFilterCustomerPropertyType } from "../models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../models/bonusRule/BonusRuleStatus";

export interface IBonusRuleReducerState {
  bonusRules: BonusRuleDto[];
}

export const bonusRules = (state: IBonusRuleReducerState, action: AnyAction): IBonusRuleReducerState => {
  switch (action.type) {
    case GET_BONUS_RULES:
      return {
        ...state,
        bonusRules: filterExpiredFilters(action.payload as BonusRuleDto[]),
      };
    case ADD_BONUS_RULE:
      return {
        ...state,
        bonusRules: [...state.bonusRules, action.payload],
      };
    case UPDATE_BONUS_RULE:
      return {
        ...state,
        bonusRules: updateRule(state.bonusRules, action.payload as BonusRuleDto),
      };
    case ADD_BONUS_RULE_FILTER:
      return {
        ...state,
        bonusRules: addFilter(state.bonusRules, action.ruleNumber, action.payload),
      };
    case UPDATE_BONUS_RULE_FILTER:
      return {
        ...state,
        bonusRules: updateFilter(state.bonusRules, action.filterNumber, action.payload),
      };
    case DELETE_BONUS_RULE_FILTER:
      return {
        ...state,
        bonusRules: deleteFilter(state.bonusRules, action.payload),
      };
    case DELETE_BONUS_RULE:
      return {
        ...state,
        bonusRules: deleteBonusRule(state.bonusRules, action.payload.ruleNumber),
      };
    case ACTIVATE_BONUS_RULE:
      return {
        ...state,
        bonusRules: activateBonusRule(state.bonusRules, action.payload.ruleNumber),
      };
    case DEACTIVATE_BONUS_RULE:
      return {
        ...state,
        bonusRules: deactivateBonusRule(state.bonusRules, action.payload.ruleNumber),
      };
    case FIND_PROPERTY_GROUP_VALUES:
      return {
        ...state,
        bonusRules: findPropertyGroupValues(state.bonusRules, action.propertyNumber, action.payload),
      };
    default:
      return state || { bonusRules: [] };
  }
};

function filterExpiredFilters(bonusRules: BonusRuleDto[]): BonusRuleDto[] {
  return bonusRules.map((rule) => ({
    ...rule,
    ruleFilters: rule.ruleFilters?.filter((ruleFilter) => !ruleFilter.hasOwnProperty("validTill")),
  }));
}

function updateRule(bonusRules: BonusRuleDto[], newRule: BonusRuleDto): BonusRuleDto[] {
  return bonusRules.map((br) =>
    br.ruleNumber === newRule.ruleNumber
      ? {
          ...newRule,
          ruleFilters: newRule.ruleFilters
            ?.filter((ruleFilter) => !ruleFilter.hasOwnProperty("validTill"))
            .map((ruleFilter) =>
              ruleFilter.ruleFilterPropertyName === RuleFilterCustomerPropertyType.CIRCLE_NUMBER
                ? { ...ruleFilter, ruleFilterValues: [] }
                : ruleFilter
            ),
        }
      : br
  );
}

function addFilter(bonusRules: BonusRuleDto[], ruleNumber: string, newFilter: FilterDto): BonusRuleDto[] {
  return bonusRules.map((rule) =>
    rule.ruleNumber === ruleNumber ? { ...rule, ruleFilters: [...rule.ruleFilters, newFilter] } : rule
  );
}

function updateFilter(bonusRules: BonusRuleDto[], filterNumber: string, newFilter: FilterDto): BonusRuleDto[] {
  return bonusRules.map((rule) => ({
    ...rule,
    ruleFilters: rule.ruleFilters?.map((ruleFilter) =>
      ruleFilter.ruleFilterNumber === filterNumber ? newFilter : ruleFilter
    ),
  }));
}

function deleteFilter(bonusRules: BonusRuleDto[], filterNumber: string): BonusRuleDto[] {
  return bonusRules.map((rule) => ({
    ...rule,
    ruleFilters: rule.ruleFilters?.filter((ruleFilter) => ruleFilter.ruleFilterNumber !== filterNumber),
  }));
}

function deleteBonusRule(bonusRules: BonusRuleDto[], ruleNumber: string): BonusRuleDto[] {
  return bonusRules.filter((rule) => rule.ruleNumber !== ruleNumber);
}

function activateBonusRule(bonusRules: BonusRuleDto[], ruleNumber: string): BonusRuleDto[] {
  return bonusRules.map((rule) =>
    rule.ruleNumber !== ruleNumber ? rule : { ...rule, status: BonusRuleStatus.ENABLED }
  );
}

function deactivateBonusRule(bonusRules: BonusRuleDto[], ruleNumber: string): BonusRuleDto[] {
  return bonusRules.map((rule) =>
    rule.ruleNumber !== ruleNumber ? rule : { ...rule, status: BonusRuleStatus.DISABLED }
  );
}

function findPropertyGroupValues(
  bonusRules: BonusRuleDto[],
  propertyNumber: string,
  ruleFilterValues: string[]
): BonusRuleDto[] {
  return bonusRules.map((rule) => ({
    ...rule,
    ruleFilters: rule.ruleFilters?.map((ruleFilter) =>
      ruleFilter.ruleFilterPropertyGroupNumber === propertyNumber
        ? { ...ruleFilter, ruleFilterValues: ruleFilterValues }
        : ruleFilter
    ),
  }));
}
