import { Button, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerSearch } from "../../api/url";
import {
  thunkGetBranchStoreList,
  thunkGetRegistrationDisplayConfig,
  thunkGetRegistrationFieldConfig,
  thunkRegistration,
} from "../../store/actions/RegistrationActions";
import { IStore } from "../../store/IStore";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import { RegistrationDto } from "../../store/models/registration/RegistrationDto";
import {
  isFieldMandatory,
  validateDateOfBirth,
  validateEmail,
  validateName,
  validateRegistrationFields,
  validateZipCode,
} from "../atoms/FieldValidation";
import { FormCheckbox } from "../atoms/FormCheckbox";
import { nameof, useMountEffect, ValidationErrors } from "../atoms/Utils";
import Contact from "./Contact";
import PersonalData from "./PersonalData";
import Registration from "./Registration";
import RegistrationConfirmationPage from "./RegistrationConfirmationPage";
import { BackdropProcessing } from "../app/BackdropProcessing";

export const getLabelText = (labelName: string, fieldName: string, registrationFieldConfig: FieldConfigDto[]) =>
  labelName + (isFieldMandatory(fieldName, registrationFieldConfig) ? " *" : "");

const RegistrationForm = (props: RegistrationFormThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const [processing, setProcessing] = useState<boolean>(true);

  useMountEffect(() => {
    const loadData = async () => {
      if (!registrationDisplayConfig?.length) await props.thunkGetRegistrationDisplayConfig();
      const { branchStores, registrationFieldConfig } = props;
      if (!registrationFieldConfig?.length) await props.thunkGetRegistrationFieldConfig();
      if (!branchStores?.length) {
        await props.thunkGetBranchStoreList();
      }
    };
    loadData()
      .catch(() => "Loading data failed")
      .finally(() => setProcessing(false));
  });

  const { registration, registrationDisplayConfig, registrationFieldConfig, customer } = props;
  if (customer) {
    return <RegistrationConfirmationPage />;
  }
  if (redirect) {
    return <Navigate to={UrlPathCustomerSearch} />;
  }
  const validateForm = (registration: RegistrationDto) => {
    const errors: ValidationErrors<RegistrationDto> = {};
    validateRegistrationFields(errors, registration, registrationFieldConfig);
    validateName(errors, registration.firstName, registration.lastName);
    validateDateOfBirth(errors, registration.dateOfBirth);
    validateEmail(registration.login?.username, errors, "login.username");
    validateZipCode(registration.address?.zipCode, errors, "address.zipCode");
    return errors;
  };
  return (
    <>
      <BackdropProcessing processing={processing}></BackdropProcessing>

      {!processing && (
        <Form
          onSubmit={props.thunkRegistration}
          initialValues={registration}
          validate={validateForm}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Registration {...props} />
              <PersonalData {...props} />
              <Contact {...props} />
              <Grid
                container
                justifyContent="flex-end"
                style={{
                  paddingBottom: theme.spacing(2),
                }}
              >
                <Grid item md={6}>
                  <FormCheckbox
                    name={nameof<RegistrationDto>("acceptPrivacyPolicy")}
                    label={getLabelText(
                      "Der Kunde wurde auf die Datenschutzbestimmungen hingewiesen und akzeptiert diese.",
                      nameof<RegistrationDto>("acceptPrivacyPolicy"),
                      registrationFieldConfig
                    )}
                    fieldConfig={registrationFieldConfig}
                  />

                  <FormCheckbox
                    name={nameof<RegistrationDto>("acceptTermsOfUse")}
                    label={getLabelText(
                      "Der Kunde wurde auf die AGBs hingewiesen und akzeptiert diese.",
                      nameof<RegistrationDto>("acceptTermsOfUse"),
                      registrationFieldConfig
                    )}
                    fieldConfig={registrationFieldConfig}
                  />
                  <FormCheckbox
                    name={nameof<RegistrationDto>("emailCorrespondenceAllowed")}
                    label={getLabelText(
                      "Der Kunde hat die Einwilligung zum Newsletter erteilt.",
                      nameof<RegistrationDto>("emailCorrespondenceAllowed"),
                      registrationFieldConfig
                    )}
                    displayConfig={registrationDisplayConfig}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end" style={{ paddingBottom: theme.spacing(2) }}>
                <Button
                  color="secondary"
                  onClick={() => setRedirect(true)}
                  style={{ marginRight: theme.spacing(2) }}
                  variant="contained"
                >
                  ABBRECHEN
                </Button>
                <Button disabled={submitting} variant="contained" color="primary" type="submit">
                  SPEICHERN
                </Button>
              </Grid>
            </form>
          )}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  registration: state.registration.registration,
  customer: state.registration.customer,
  branchStores: state.registration.branchStores,
  registrationDisplayConfig: state.registration.registrationDisplayConfig,
  registrationFieldConfig: state.registration.registrationFieldConfig,
  circles: state.circles.circles,
  einGeneratorTemplates: state.einGeneratorTemplates.einGeneratorTemplates,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkRegistration,
      thunkGetBranchStoreList,
      thunkGetRegistrationDisplayConfig,
      thunkGetRegistrationFieldConfig,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
export type RegistrationFormThunkProps = ConnectedProps<typeof connector>;
export default connector(RegistrationForm);
