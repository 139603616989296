import { Brightness1 } from "@mui/icons-material";

import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { CircleDto } from "../../../store/models/circle/CircleDto";
import { KNISTR_GREEN } from "../../atoms/ImsMaterialTheme";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../../atoms/ImsTable";
import CircleTableMenu from "./CircleTableMenu";

const CircleTable = (props: ThunkProps) => {
  const { circles } = props;
  const headCells: ImsHeadCell<CircleDto>[] = [
    { val: (val) => val.circleExternalNumber, node: "Circle-ID" },
    { val: (val) => val.circleName, node: "Bezeichnung" },
    { val: (val) => val.defaultCircle, node: "Standard für Neukunden" },
  ];

  const rows: ImsTableRow<CircleDto>[] = circles.map((row) => ({
    value: row,
    nodes: [
      row.circleExternalNumber,
      row.circleName,
      row.defaultCircle && (
        <Brightness1
          style={{
            fill: KNISTR_GREEN,
            paddingTop: 4,
          }}
        />
      ),
      <CircleTableMenu circle={row} />,
    ],
  }));
  return rows.length ? (
    <ImsTable hasMenu={true} headCells={headCells} rows={rows} sortColumnIndex={1} sortDirection="desc" />
  ) : (
    <>Es sind keine Daten vorhanden</>
  );
};

const mapStateToProps = (state: IStore) => ({
  circles: state.circles.circles,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CircleTable);
