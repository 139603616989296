import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { UrlPathBonusPremiumCreate } from "../../api/url";
import { canMaintainBonusPremiums } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import BonusPremiumTable from "./BonusPremiumTable";

export const BonusPremiumPage = () => (
  <MenuLayout headline="Bonusprämien">
    <Grid container spacing={2}>
      {canMaintainBonusPremiums() && (
        <Grid container item justifyContent="flex-end">
          <Button component={Link} to={UrlPathBonusPremiumCreate} startIcon="+" variant="contained" color="primary">
            Neue Prämie
          </Button>
        </Grid>
      )}
      <Grid item md={12}>
        <BonusPremiumTable />
      </Grid>
    </Grid>
  </MenuLayout>
);
