import { FormControl, Grid, TextField, IconButton, InputAdornment, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Field } from "react-final-form";
import { useState } from "react";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import { isFieldNeeded } from "./FieldValidation";

interface PasswordInputProps {
  name: string;
  label?: string;
  readOnly?: boolean;
  prefix?: string;
  helperText?: string;
  tooltip?: string;
  testId?: string;
  fullWidth?: boolean;
  fieldConfig?: FieldConfigDto[];
}

export const FormPassword = (props: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (props.fieldConfig && !isFieldNeeded(props.name, props.fieldConfig)) return null;

  return (
    <Field name={props.name} parse={(value) => value.trim()}>
      {({ input, meta }) => {
        const hasErrors = Boolean(meta.error && meta.touched);
        const helperText = hasErrors ? meta.error : props.helperText;
        const containerSpacing = props.prefix || props.tooltip ? 2 : 0;
        const itemSpacing = props.prefix || props.tooltip ? 9 : 12;
        return (
          <FormControl fullWidth={props.fullWidth}>
            <Grid container spacing={containerSpacing}>
              {props.prefix && (
                <Grid item style={{ margin: "auto" }}>
                  <Typography>{props.prefix}</Typography>
                </Grid>
              )}
              <Grid item xs={itemSpacing}>
                <TextField
                  {...input}
                  label={props.label}
                  type={showPassword ? "text" : "password"}
                  disabled={props.readOnly}
                  data-testid={props.testId ?? "passwordInput-" + props.name}
                  fullWidth={props.fullWidth}
                  error={hasErrors}
                  helperText={helperText}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          aria-label={showPassword ? "Hide password" : "Show password"}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
        );
      }}
    </Field>
  );
};
