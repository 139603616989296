import { Box, CssBaseline, Theme, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IStore } from "../../store/IStore";
import { MENU_WIDTH } from "../atoms/ImsMaterialTheme";
import NotificationBar from "../notification/NotificationBar";
import DrawerMenu from "./DrawerMenu";
import Header from "./Header";

interface MenuLayoutProps extends PropsWithChildren<{}> {
  headline?: string;
  subHeadline?: string;
}

const MenuLayout = (props: ThunkProps) => {
  const theme = useTheme();
  const classes = styles(theme, props.isMenuOpen);

  return (
    <>
      <CssBaseline />
      <Box component="div" sx={classes.root}>
        <DrawerMenu />
        <Box component="div" sx={classes.page}>
          <NotificationBar />
          <Header headline={props.headline || ""} subHeadline={props.subHeadline} />

          <Box component="main" sx={classes.main}>
            <Box component="div" sx={classes.content}>
              {props.children}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const styles = (theme: Theme, isMenuOpen: boolean) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  page: {
    minHeight: "100vh",
    maxWidth: `100%`,
    width: `calc(100% - ${theme.spacing(7)} + 1px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin", "max-width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isMenuOpen && {
      width: `calc(100% - ${MENU_WIDTH}px)`,
      maxWidth: `calc(100% - ${MENU_WIDTH}px)`,
      transition: theme.transitions.create(["width", "margin", "max-width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  main: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  content: {
    flexGrow: 1,
  },
});

const mapStateToProps = (state: IStore, ownProps: MenuLayoutProps) => ({
  isMenuOpen: state.menu?.open,
  ...ownProps,
});

const connector = connect(mapStateToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(MenuLayout);
