import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathPreferences } from "../../api/url";
import { canMaintainPreferences } from "../../store/actions/LoginActions";
import { thunkDeleteQuestion } from "../../store/actions/PreferenceActions";
import { IStore } from "../../store/IStore";
import { QuestionDto } from "../../store/models/preferences/QuestionDto";
import ImsConfirmDialog from "../atoms/ImsConfirmDialog";

interface PreferencesTableMenuProps {
  question: QuestionDto;
}

const PreferencesTableMenu = (props: PreferencesTableMenuProps & ThunkProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  if (!canMaintainPreferences()) return null;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <div>
      {confirmVisible && (
        <ImsConfirmDialog
          article="die"
          word="Präferenzfrage"
          verb="löschen"
          onConfirm={() => props.thunkDeleteQuestion(props.question)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setConfirmVisible(true);
            handleClose();
          }}
        >
          Löschen
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link component={RouterLink} to={`${UrlPathPreferences}/${props.question.questionNumber}/edit`}>
            Bearbeiten
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkDeleteQuestion }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PreferencesTableMenu);
