import { Button, Grid, Link, Paper, useTheme } from "@mui/material";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerPreferences } from "../../api/url";
import { canMaintainCustomerPreferences } from "../../store/actions/LoginActions";
import { thunkGetCustomerPreferences } from "../../store/actions/PreferenceActions";
import { IStore } from "../../store/IStore";
import { questionTypeOptions } from "../../store/models/preferences/QuestionType";
import ImsTable, { ImsHeadCell, ImsTableRow } from "../atoms/ImsEmbeddedTable";
import ImsPaperHead from "../atoms/ImsPaperHead";

const Preferences = (props: ThunkProps) => {
  const theme = useTheme();
  const { customerPreferences, customerNumber } = props;
  if (!customerNumber) return null;

  if (!customerPreferences) {
    props.thunkGetCustomerPreferences(customerNumber);
    return null;
  }

  const questions = customerPreferences.questions
    .filter((q) => q.options.some((o) => o.selected))
    .sort((a, b) => a.position - b.position);

  const headCells: ImsHeadCell[] = [
    { title: "Frage", align: "left", headAlign: "center" },
    { title: "Frageart", align: "center", headAlign: "center" },
    {
      title: "Antwort",
      align: "center",
      headAlign: "center",
    },
  ];

  const rows: ImsTableRow[] = questions.map((question) => ({
    key: question.questionNumber,
    nodes: [
      question.label,
      questionTypeOptions.find((type) => type.value === question.type)?.label,
      <b>
        {question.options
          .filter((option) => option.selected)
          .map((option) => (
            <React.Fragment key={option.optionNumber}>
              {option.label}
              <br />
            </React.Fragment>
          ))}
      </b>,
    ],
  }));

  return (
    <Grid height={"100%"}>
      <Paper
        style={{
          padding: theme.spacing(3),
          height: "100%",
        }}
      >
        <ImsPaperHead text="Präferenzfragen" />
        {questions.length ? <ImsTable headCells={headCells} rows={rows} /> : <p>keine Präferenzfragen beantwortet</p>}
        {canMaintainCustomerPreferences() && (
          <Grid container justifyContent="flex-end" style={{ marginTop: theme.spacing(2) }}>
            <Link component={RouterLink} to={UrlPathCustomerPreferences.replace(":id", customerNumber)}>
              <Button color="primary" type="button" variant="contained">
                Bearbeiten
              </Button>
            </Link>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerPreferences: state.preferences.customerPreferences,
  customerNumber: state.customerCare.customer?.customerNumber,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetCustomerPreferences }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Preferences);
