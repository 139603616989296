import { useTranslation } from "react-i18next";

export interface AppLinkDto {
  appLinkNumber: string;
  url: string;
  text: string;
  position: string;
  order: number;
}

export enum AppLinkPosition {
  FOOTER = "FOOTER",
  LINKLIST = "LINKLIST",
}

export const useAppLinkPositionOptions = () => {
  const { t } = useTranslation();

  return [
    {
      label: t("appContentLinkEdit.inputValueFooter"),
      value: AppLinkPosition.FOOTER,
    },
    {
      label: t(`appContentLinkEdit.inputValueLinkList`),
      value: AppLinkPosition.LINKLIST,
    },
  ];
};