import { Button, Grid, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCircle } from "../../../api/url";
import { thunkCreateCircle, thunkUpdateCircle } from "../../../store/actions/CircleActions";
import { IStore } from "../../../store/IStore";
import { CircleDto } from "../../../store/models/circle/CircleDto";
import { FormCheckbox } from "../../atoms/FormCheckbox";
import { FormInput } from "../../atoms/FormInput";
import ImsPaperHead from "../../atoms/ImsPaperHead";
import { isBlank, nameof, ValidationErrors } from "../../atoms/Utils";

interface CircleFormProps {
  circle: CircleDto;
}

const CircleForm = (props: CircleFormProps & ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);

  const saveCircle = async (circleToSave: CircleDto) => {
    if (circleToSave.circleNumber) {
      await props.thunkUpdateCircle(circleToSave);
    } else {
      await props.thunkCreateCircle(circleToSave);
    }
    setRedirect(true);
  };

  const { circle } = props;
  if (redirect) {
    return <Navigate to={UrlPathCircle} />;
  }

  const validateForm = (values: CircleDto) => {
    const errors: ValidationErrors<CircleDto> = {};
    if (isBlank(values.circleName)) {
      errors.circleName = "Bitte geben Sie eine Circle-Bezeichnung ein";
    }
    if (!values.circleExternalNumber) {
      errors.circleExternalNumber = "Bitte geben Sie eine Circle-ID ein";
    }
    return errors;
  };
  return (
    <Form
      onSubmit={saveCircle}
      initialValues={circle}
      validate={validateForm}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Paper
            style={{
              padding: theme.spacing(3),
              marginTop: theme.spacing(6),
            }}
          >
            <ImsPaperHead text="Wie lauten die Circle-Details?" />
            <Grid container spacing={4}>
              <Grid item container spacing={2}>
                <Grid item md={12}>
                  <b>Circle-ID</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<CircleDto>("circleExternalNumber")}
                    label="Circle-ID"
                    readOnly={Boolean(circle.circleNumber)}
                  />
                </Grid>

                <Grid item md={12}>
                  <b>Bezeichnung</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<CircleDto>("circleName")}
                    label="Circle-Bezeichnung"
                  />
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item sm={12}>
                    <FormCheckbox name={nameof<CircleDto>("defaultCircle")} label="Standard für Neukunden" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Speichern
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};
const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateCircle,
      thunkCreateCircle,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CircleForm);
