import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { UrlPathCampaignCreate } from "../../api/url";
import { canMaintainCampaigns } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import CampaignTable from "./CampaignTable";

export const CampaignPage = () => (
  <MenuLayout headline="Kampagnen">
    <Grid container spacing={2}>
      {canMaintainCampaigns() && (
        <Grid container item justifyContent="flex-end">
          <Button component={Link} to={UrlPathCampaignCreate} startIcon="+" variant="contained" color="primary">
            Neue Kampagne
          </Button>
        </Grid>
      )}
      <Grid item md={12}>
        <CampaignTable />
      </Grid>
    </Grid>
  </MenuLayout>
);
