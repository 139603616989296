export interface ReportsDto {
  metric: string[];
  country?: string;
  branch?: string[];
  from?: string;
  to?: string;
}

export interface TimeDto {
  start: string;
  end: string;
}

export enum ReportTranslation {
  "revenueTotal" = "Umsatz gesamt",
  "bonusPointsTotal" = "Bonuspunkte gesamt",
  "allInteractionsTotal" = "Interaktionen gesamt",
  "bonCountTotal" = "Bons gesamt",
  "customerWithPurchaseCountTotal" = "Kaufende Kunden",
}

export interface BranchTranslation {
  [key: string]: BranchTranslation2;
}

export interface BranchTranslation2 {
  label: string;
  value: string;
}
