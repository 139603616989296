export interface INotification {
  type: NotificationType;
  time: string;
  message: string;
  id?: string;
}

export enum NotificationType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}
