import { Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { DEFAULT_LIMIT } from "../../api/SnowflakeApi";
import { IStore } from "../../store/IStore";
import { CustomerStatusDto } from "../../store/models/dashboard/CustomerStatusDto";
import { KNISTR_GREEN, KNISTR_PLUM } from "../atoms/ImsMaterialTheme";
import { chartColors, formatAxisDate } from "./MainDashboardPage";

const CustomerStatusChart = (props: ThunkProps) => {
  const theme = useTheme();
  const { customerStatuses } = props;
  if (!customerStatuses) return null;
  return (
    <div style={{ width: "100%", height: 300 }}>
      <Typography
        variant="h4"
        style={{
          paddingLeft: theme.spacing(8),
          paddingBottom: theme.spacing(2),
        }}
      >
        Neuer Kundenstatus der vergangenen {((customerStatuses.offset || 0) + 1) * DEFAULT_LIMIT} Tage
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={customerStatuses.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {chartColors()}
          <CartesianGrid strokeDasharray={3} />
          <XAxis
            height={32}
            dataKey={(item: CustomerStatusDto) => formatAxisDate(item.DWH_INSERT_DATE)}
            tick={<MultilineTick />}
          />
          <YAxis type="number" allowDataOverflow />
          <Tooltip />
          <Legend wrapperStyle={{ paddingTop: theme.spacing(2) }} />
          <Bar
            dataKey={(value: CustomerStatusDto) => Number(value.CUS_STATUS_ACTIVE_CNT)}
            name="aktiv"
            stroke={KNISTR_GREEN}
            fill={KNISTR_GREEN + "A0"}
            barSize={Number(theme.spacing(4))}
          />
          <Bar
            dataKey={(value: CustomerStatusDto) => Number(value.CUS_STATUS_DISABLED_CNT)}
            name="gesperrt"
            stroke={KNISTR_PLUM}
            fill={KNISTR_PLUM + "A0"}
            barSize={Number(theme.spacing(4))}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const MultilineTick = (props: any) => {
  if (!props.payload.value) return null;
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text x={0} y={0} dy={12} fill="#666">
        {(props.payload.value as string).split("\n").map((item, index) => (
          <tspan key={index} textAnchor="middle" x="0" dy={index ? index * 16 : undefined}>
            {item}
          </tspan>
        ))}
      </text>
    </g>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerStatuses: state.dashboard.customerStatuses,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerStatusChart);
