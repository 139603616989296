export interface FieldConfigDto {
  client: ConfigClients;
  mandatoryFields: string[];
  optionalFields: string[];
  allowUnchanged: string[];
}
export enum ConfigTypes {
  ADMIN = "ADMIN",
  LOYALTY = "LOYALTY",
}
export enum ConfigClients {
  ADMIN = "reactadmin",
  LOYALTY = "reactloyalty",
}

export function getMandatoryFields(fieldConfigs: FieldConfigDto[]): string[] {
  return fieldConfigs.find((fieldConfig) => fieldConfig.client === ConfigClients.ADMIN)?.mandatoryFields || [];
}
export function getOptionalFields(fieldConfigs: FieldConfigDto[]) {
  return fieldConfigs.find((fieldConfig) => fieldConfig.client === ConfigClients.ADMIN)?.optionalFields || [];
}
export function getAllowUnchangedFields(fieldConfigs: FieldConfigDto[]): string[] {
  return fieldConfigs.find((fieldConfig) => fieldConfig.client === ConfigClients.ADMIN)?.allowUnchanged || [];
}
