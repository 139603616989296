import { Group } from "@mui/icons-material";
import { Grid, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { COLOR_BLACK, COLOR_GRAY_2, KNISTR_CYAN, KNISTR_GREEN, KNISTR_RED } from "../atoms/ImsMaterialTheme";
import { euroFormatter, formatNumber, germanSeparators } from "../atoms/Utils";

const formatPercent = (value: number): ReactNode => (
  <span
    style={{
      color: value < 0 ? KNISTR_RED : KNISTR_GREEN,
    }}
  >
    {value < 0 ? "-" : "+"} {germanSeparators(euroFormatter.format(Math.abs(value)))}
    {"%"}
  </span>
);

const CustomerActivityCount = (props: ThunkProps) => {
  const theme = useTheme();
  const { customerActivitiesYear } = props;

  const lastCount = Number(customerActivitiesYear?.data[0]?.CURRENT_ACTIVE_CUST_CNT || 0);
  const nextToLastCount = Number(customerActivitiesYear?.data[0]?.LAST_MONTH_ACTIVE_CUST_CNT || 0);
  const change = lastCount ? ((lastCount - nextToLastCount) / lastCount) * 100 : 0;

  return (
    <>
      <Grid
        item
        md
        style={{
          borderLeftWidth: "2px",
          borderLeftStyle: "solid",
          borderLeftColor: KNISTR_CYAN,
          paddingLeft: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>
          <Group fontSize="large" />
          <Typography style={{ paddingLeft: theme.spacing(1) }}>Aktive Kund:Innen</Typography>
        </Grid>
        <Grid item md={12} style={{ display: "flex", alignItems: "baseline" }}>
          <Typography
            style={{
              color: COLOR_BLACK,
              paddingLeft: theme.spacing(1),
              fontWeight: "bold",
              fontSize: "32px",
              paddingRight: theme.spacing(1),
            }}
          >
            {formatNumber(lastCount)}
          </Typography>
          <Typography variant="subtitle1" style={{ color: COLOR_GRAY_2, fontWeight: "bold" }}>
            Kunden
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" style={{ color: COLOR_GRAY_2, fontWeight: "bold" }}>
        Veränderung zum Vormonat: {formatPercent(change)}
      </Typography>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerActivitiesYear: state.dashboard.customerActivitiesYear,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerActivityCount);
