import { AnyAction } from "redux";
import {
  DELETE_IDENTIFICATION_TYPE,
  GET_ALL_IDENTIFICATION_TYPES,
  GET_CUSTOMER_IDENTIFICATIONS,
} from "../actions/IdentificationActions";
import { CustomerIdentificationDto } from "../models/identification/CustomerIdentificationDto";
import { IdentificationTypeDto } from "../models/identification/IdentificationTypeDto";

export interface IIdentificationReducerState {
  identificationTypes: IdentificationTypeDto[];
  identifications: CustomerIdentificationDto[];
}

export const identifications = (state: IIdentificationReducerState, action: AnyAction): IIdentificationReducerState => {
  switch (action.type) {
    case GET_ALL_IDENTIFICATION_TYPES:
      return {
        ...state,
        identificationTypes: action.payload,
      };
    case DELETE_IDENTIFICATION_TYPE:
      const deletedIdentificationType = action.payload as IdentificationTypeDto;
      return {
        ...state,
        identificationTypes: state.identificationTypes.filter(
          (identificationType) =>
            identificationType.identificationTypeNumber !== deletedIdentificationType.identificationTypeNumber
        ),
      };
    case GET_CUSTOMER_IDENTIFICATIONS:
      return {
        ...state,
        identifications: action.payload,
      };
    default:
      return state || { identificationTypes: [], identifications: [] };
  }
};
