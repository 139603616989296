import { FormOption } from "../../../components/atoms/FormOption";
import { COLOR_GRAY_4, KNISTR_GREEN, KNISTR_RED } from "../../../components/atoms/ImsMaterialTheme";

export interface CustomerStatusDto {
  status: CustomerStatus;
  note: string;
}

export enum CustomerStatus {
  NONE = "NONE",
  REGISTERED = "REGISTERED",
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  ARCHIVED = "ARCHIVED",
  MIGRATED = "MIGRATED",
  CONSENT_FAILED = "CONSENT_FAILED",
  CANCELLED = "CANCELLED",
  REVOKE_CANCELLATION = "REVOKE_CANCELLATION",
}

export function getCustomerStatusText(status: CustomerStatus): string {
  switch (status) {
    case CustomerStatus.NONE:
      return "Kein Status";
    case CustomerStatus.REGISTERED:
      return "Registriert";
    case CustomerStatus.ACTIVE:
      return "Aktiv";
    case CustomerStatus.DISABLED:
      return "Gesperrt";
    case CustomerStatus.ARCHIVED:
      return "Archiviert";
    case CustomerStatus.MIGRATED:
      return "Migriert";
    case CustomerStatus.CONSENT_FAILED:
      return "Einwilligung fehlt";
    case CustomerStatus.CANCELLED:
      return "Gekündigt";
    case CustomerStatus.REVOKE_CANCELLATION:
      return "Kündigung zurückgenommen";
  }
}

export function getCustomerStatusColor(status: CustomerStatus): string {
  switch (status) {
    case CustomerStatus.ACTIVE:
      return KNISTR_GREEN;
    case CustomerStatus.DISABLED:
      return KNISTR_RED;
    default:
      return COLOR_GRAY_4;
  }
}

export const customerStatusOptions: FormOption[] = [
  {
    value: CustomerStatus.ACTIVE,
    label: "Aktiv",
  },
  { value: CustomerStatus.DISABLED, label: "Gesperrt" },
  {
    value: CustomerStatus.NONE,
    label: "kein Status",
    disabled: true,
  },
  { value: CustomerStatus.REGISTERED, label: "Registriert", disabled: true },

  { value: CustomerStatus.ARCHIVED, label: "Archiviert", disabled: true },
  { value: CustomerStatus.MIGRATED, label: "Migriert", disabled: true },
  {
    value: CustomerStatus.CONSENT_FAILED,
    label: "Einwilligung fehlt",
    disabled: true,
  },
  {
    value: CustomerStatus.CANCELLED,
    label: "Gekündigt",
    disabled: true,
  },
];
