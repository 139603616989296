import { Box, Theme, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { MENU_WIDTH } from "../atoms/ImsMaterialTheme";
import NotificationList from "./NotificationList";

const NotificationBar = (props: ThunkProps) => {
  const theme = useTheme();
  const classes = styles(theme, props.isMenuOpen);

  const { notifications, isDialog } = props;
  if (!notifications?.length || isDialog) return null;
  else
    return (
      <Box component="div" sx={classes.notificationBarContainer}>
        <Box component="div" sx={classes.notificationBar}>
          <NotificationList />
        </Box>
      </Box>
    );
};

const styles = (theme: Theme, menuOpen: boolean) => ({
  notificationBarContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  notificationBar: {
    display: "flex",
    position: "fixed",
    flexDirection: "column",
    opacity: 0.9,
    width: "80%",
    zIndex: 1000,
    ...(menuOpen && {
      width: `calc(80% - ${MENU_WIDTH / 2}px)`,
    }),
  },
});

const mapStateToProps = (state: IStore) => ({
  notifications: state.notifications?.notifications,
  isDialog: state.notifications?.isDialog,
  isMenuOpen: state.menu?.open,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(NotificationBar);
