export enum QuestionType {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
}

export const questionTypeOptions = [
  {
    label: "Genau eine Antwort",
    value: QuestionType.SINGLE,
  },
  {
    label: "Mehrere Antworten",
    value: QuestionType.MULTIPLE,
  },
];
