import { Box, CssBaseline, Grid, Paper, Theme, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkPublicUserLogin, deleteToken } from "../../store/actions/LoginActions";
import { thunkDoubleOptIn, thunkDoubleOptOut } from "../../store/actions/LandingActions";
import { IStore } from "../../store/IStore";
import harborNewYearBackground from "../atoms/images/harbor-new-year-background.png";
import { KNISTR_BLUE } from "../atoms/ImsMaterialTheme";
import { useLocation } from "react-router-dom";
import NotificationBar from "../notification/NotificationBar";
import { thunkCreateErrorNotification } from "../../store/actions/NotificationActions";
import { useEffect, useState } from "react";
import { BackdropProcessing } from "./BackdropProcessing";

const LandingPage = (props: ThunkProps) => {
  const theme = useTheme();
  const classes = styles(theme);
  const location = useLocation();
  const [processing, setProcessing] = useState<boolean>(true);
  const [programName, setProgramName] = useState<string>();

  useEffect(() => {
    const login = async () => {
      return await props.thunkPublicUserLogin();
    };
    login()
      .then((accessToken) => {
        if (!accessToken) {
          return;
        }
        const params = new URLSearchParams(location.search);
        let customerNumber = params.get("customerNumber");
        let token = params.get("token");
        let type = params.get("type");

        if (customerNumber !== null && token !== null) {
          if (type === "doubleOptIn") {
            props.thunkDoubleOptIn(customerNumber, encodeURIComponent(token), accessToken.toString());
          } else if (type === "doubleOptOut") {
            props.thunkDoubleOptOut(customerNumber, encodeURIComponent(token), accessToken.toString());
          } else {
            //invalid type
            props.thunkCreateErrorNotification("Error", `invalid "type" parameter`);
          }
        }
      })
      .catch((e) => {
        props.thunkCreateErrorNotification("Error", e);
      })
      .finally(() => {
        const params = new URLSearchParams(location.search);
        let programName = params.get("programName") ?? "";
        setProgramName(programName);
        deleteToken();
        setProcessing(false);
      });
  }, [props, location.search]);

  return (
    <>
      <BackdropProcessing processing={processing}></BackdropProcessing>
      <NotificationBar />
      <CssBaseline />

      <Paper sx={classes.login}>
        <Grid container spacing={3} style={{ padding: theme.spacing(2) }}>
          <Grid item style={{ fontWeight: "bold" }}>
            <h2>Newsletter Anmeldung</h2>
          </Grid>
          <Grid item style={{ fontWeight: "bold" }}>
            <span>Vielen Dank für Ihre Anmeldung für das Newsletter </span>
            {programName && `von ${[programName]}`}
          </Grid>
        </Grid>
      </Paper>

      <Box component="div" sx={classes.blueBackground}>
        <Box component="div" sx={classes.pictureBackground}></Box>
      </Box>
    </>
  );
};

const styles = (theme: Theme) => ({
  blueBackground: {
    backgroundColor: KNISTR_BLUE,
  },
  pictureBackground: {
    width: "100%",
    minHeight: "100vh",
    maxWidth: "100%",
    backgroundImage: `url(${harborNewYearBackground})`,
    backgroundSize: "cover",
    opacity: 0.35,
  },
  login: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "476px",
    marginTop: "-200px",
    marginLeft: "-238px",
    zIndex: 1001,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkPublicUserLogin,
      deleteToken,
      thunkDoubleOptIn,
      thunkDoubleOptOut,
      thunkCreateErrorNotification,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(LandingPage);
