import { AnyAction } from "redux";
import {
  CUSTOMER_DISPLAY_CONFIG,
  CUSTOMER_FIELD_CONFIG,
  CUSTOMER_SEARCH,
  GET_CUSTOMER,
  GET_CUSTOMER_BONUS_POINTS,
  RECEIPT_DETAILS,
  UPDATE_CUSTOMER,
} from "../actions/CustomerCareActions";
import {
  DELETE_FAMILY_MEMBER,
  GET_FAMILY_BY_CUSTOMER_NUMBER,
  UPDATE_FAMILY_MEMBERSHIP,
} from "../actions/FamilyActions";
import { GET_CUSTOMER_HISTORY } from "../actions/HistoryActions";
import { GET_CUSTOMER_CUSTOM_FIELDS } from "../actions/IdentificationActions";
import { CustomerBonusPointsDto } from "../models/customer/CustomerBonusPointsDto";
import { CustomerCustomFieldDto } from "../models/customer/CustomerCustomFieldDto";
import { CustomerDto } from "../models/customer/CustomerDto";
import { ReceiptDetailsDto } from "../models/customer/ReceiptDetailsDto";
import { FamilyDto } from "../models/family/FamilyDto";
import { FamilyMembershipDto } from "../models/family/FamilyMembershipDto";
import { FieldConfigDto } from "../models/fieldConfig/FieldConfigDto";
import { ConfigDto } from "../models/config/ConfigDto";
import { CustomerHistoryDto } from "../models/history/HistoryDto";

export interface ICustomerCareReducerState {
  customers?: CustomerDto[];
  customer?: CustomerDto;
  customerHistory?: CustomerHistoryDto[];
  family?: FamilyDto;
  interactionCustomerNumber?: string;
  customerDisplayConfig?: ConfigDto[];
  customerFieldConfig?: FieldConfigDto[];
  receiptDetails?: ReceiptDetailsDto;
  customerBonusPoints?: CustomerBonusPointsDto;
  customerCustomFields?: CustomerCustomFieldDto[];
}

export const customerCare = (state: ICustomerCareReducerState, action: AnyAction): ICustomerCareReducerState => {
  switch (action.type) {
    case CUSTOMER_SEARCH:
      return {
        ...state,
        customers: action.payload,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case GET_CUSTOMER_HISTORY:
      return {
        ...state,
        customerHistory: action.payload,
      };
    case UPDATE_CUSTOMER:
      const updatedCustomer = action.payload as CustomerDto;
      return {
        ...state,
        customer: updatedCustomer,
        customers: state.customers?.map((customer) =>
          customer.customerNumber === updatedCustomer.customerNumber ? updatedCustomer : customer
        ),
      };
    case GET_FAMILY_BY_CUSTOMER_NUMBER:
      return {
        ...state,
        family: action.payload,
      };
    case DELETE_FAMILY_MEMBER:
      return {
        ...state,
        family: state.family && {
          ...state.family,
          familyMembers: state.family.familyMembers.filter((member) => member.customerNumber !== action.payload),
        },
      };
    case UPDATE_FAMILY_MEMBERSHIP:
      return {
        ...state,
        family: state.family && {
          ...state.family,
          familyMembers: state.family.familyMembers.map((member) => ({
            ...member,
            roleName: (action.payload as FamilyMembershipDto).roleName,
          })),
        },
      };
    case CUSTOMER_DISPLAY_CONFIG:
      return {
        ...state,
        customerDisplayConfig: action.payload,
      };
    case CUSTOMER_FIELD_CONFIG:
      return {
        ...state,
        customerFieldConfig: action.payload,
      };
    case RECEIPT_DETAILS:
      return {
        ...state,
        receiptDetails: action.payload,
      };
    case GET_CUSTOMER_BONUS_POINTS:
      return {
        ...state,
        customerBonusPoints: action.payload,
      };
    case GET_CUSTOMER_CUSTOM_FIELDS:
      return {
        ...state,
        customerCustomFields: action.payload,
      };
    default:
      return state || {};
  }
};
