import { Grid, Link, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { COLOR_GRAY_3 } from "../atoms/ImsMaterialTheme";
import { formatGermanDate, formatNumber } from "../atoms/Utils";
import { formatBonusPoints } from "./KeyFigures";
import { PaymentModel } from "./PaymentModel";

export function getTotalBonusPoints(bonusPayments: PaymentModel[]): number {
  return bonusPayments.length > 0 ? Number(bonusPayments[0].totalBonusPoints) : 0;
}

interface BonusProps extends ThunkProps {
  openDialog: () => void;
  bonusPayments: PaymentModel[];
}

const Bonus = (props: BonusProps) => {
  const theme = useTheme();
  if (!props.customerBonusPoints) return null;
  const lastThreeBonusPayments = props.bonusPayments.slice(0, 3);
  return (
    <Grid
      item
      md
      style={{
        borderRightWidth: "1px",
        borderRightStyle: "solid",
        borderRightColor: COLOR_GRAY_3,
      }}
      container
      direction="column"
      justifyContent="space-between"
    >
      <Grid container>
        <Grid
          container
          justifyContent="center"
          style={{
            padding: theme.spacing(3, 0),
          }}
        >
          <b>Bonuspunkte</b>
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          <Grid container item sm={6} justifyContent="center">
            <b>gesamt</b>
          </Grid>

          <Grid container item sm={6} justifyContent="center">
            <b>verfügbar</b>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          <Grid
            container
            item
            sm={6}
            justifyContent="center"
            style={{
              marginBottom: theme.spacing(1.5),
              fontSize: 42,
            }}
          >
            {formatNumber(props.customerBonusPoints.totalBonusPoints)}
          </Grid>

          <Grid
            container
            item
            sm={6}
            justifyContent="center"
            style={{
              marginBottom: theme.spacing(1.5),
              fontSize: 42,
            }}
          >
            {formatNumber(props.customerBonusPoints.availableBonusPoints)}
          </Grid>
        </Grid>

        {lastThreeBonusPayments.map((interaction) => (
          <Grid key={interaction.interactionNumber} container spacing={1} style={{ padding: theme.spacing(0, 3) }}>
            <Grid item md>
              {formatGermanDate(interaction.interactionTimestamp)}
            </Grid>
            <Grid container item md justifyContent="flex-end">
              {formatBonusPoints(interaction.bonusPoints)}
            </Grid>
          </Grid>
        ))}
      </Grid>
      {lastThreeBonusPayments.length > 0 && (
        <Grid
          container
          justifyContent="flex-end"
          style={{
            padding: theme.spacing(0, 2, 0, 0),
          }}
        >
          <Link style={{ cursor: "pointer" }} onClick={props.openDialog}>
            <Typography variant="button">mehr anzeigen</Typography>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const mapStateToProps = (state: IStore) => ({
  customerBonusPoints: state.customerCare.customerBonusPoints,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Bonus);
