import { Button, Grid, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import { AnyObject, Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathBonusPremium } from "../../api/url";
import { thunkCreateBonusPremium, thunkUpdateBonusPremium } from "../../store/actions/BonusPremiumActions";
import { IStore } from "../../store/IStore";
import {
  BonusPremiumCouponDetailsDto,
  BonusPremiumDto,
  BonusPremiumStatus,
} from "../../store/models/bonusPremium/BonusPremiumDto";
import { CouponAdvantageType } from "../../store/models/bonusPremium/CouponAdvantageType";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { PageViewMode } from "../../store/models/PageViewMode";
import { FormCheckbox } from "../atoms/FormCheckbox";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { getTomorrow, isBlank, isPositiveInteger, nameof, nameof2 } from "../atoms/Utils";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";

const advantageTypeOptions = [
  { value: CouponAdvantageType.EURO, label: "Geldbetrag" },
  { value: CouponAdvantageType.PERCENT, label: "Prozent" },
  { value: CouponAdvantageType.OFFER, label: "Präsent" },
];

const couponValidityTypeOptions = [
  {
    label: "Ablauf an einem bestimmten Datum",
    value: "invalidAt",
  },
  {
    label: "Ablauf nach einem bestimmten Zeitraum",
    value: "invalidPeriod",
  },
];

interface BonusPremiumFormProps extends ThunkProps {
  bonusPremium: BonusPremiumDto;
  operationalUnits: OperationalUnitDto[];
  viewMode: PageViewMode;
}

const BonusPremiumForm = (props: BonusPremiumFormProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);

  const saveBonusPremium = async (formModel: BonusPremiumFormModel) => {
    let success;
    const bonusPremium = mapFormModelToBonusPremium(formModel);
    if (formModel.premiumNumber) {
      success = await props.thunkUpdateBonusPremium(bonusPremium);
    } else {
      success = await props.thunkCreateBonusPremium(bonusPremium);
    }
    if (success) {
      setRedirect(true);
    }
  };

  const operationalUnitOptions = getOperationalUnitOptions(undefined, props.operationalUnits, theme, true, false);

  if (redirect) {
    return <Navigate to={UrlPathBonusPremium} />;
  }
  const formModel = mapBonusPremiumToFormModel(props.bonusPremium);

  const rootOperationalUnit = props.operationalUnits.find((operationalUnit) => !operationalUnit.parentUnitNumber);

  formModel.unitNumber = formModel.unitNumber || rootOperationalUnit?.unitNumber || "";

  const readOnly = props.viewMode === PageViewMode.VIEW;

  return (
    <>
      <Form
        onSubmit={saveBonusPremium}
        initialValues={formModel}
        validate={validateForm}
        render={({ handleSubmit, submitting, values }) => {
          const isInvalidAfterPeriod = (couponValidityType: string) => {
            if (couponValidityType === "invalidPeriod") {
              delete values.couponDetails.expiryDate;
              return true;
            } else {
              delete values.couponDetails.expiryMonths;
              return false;
            }
          };
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      type="text"
                      name={nameof<BonusPremiumFormModel>("premiumName")}
                      label="Name der Prämie"
                      variant="standard"
                      fullWidth={true}
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      type="textarea"
                      fullWidth={true}
                      name={nameof<BonusPremiumFormModel>("premiumDescription")}
                      label="Beschreibung (optional)"
                      variant="standard"
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text="Was sind die Prämien-Details?" />
                <Grid container item spacing={4}>
                  <Grid item md={12}>
                    <b>Name der Prämie</b>
                  </Grid>
                  <Grid item md={5} style={{ marginBottom: theme.spacing(2) }}>
                    <FormInput
                      type="text"
                      name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                        "couponDetails",
                        "advantageInfo"
                      )}
                      label="Anzeigename"
                      fullWidth={true}
                      variant="outlined"
                      tooltip="Diesen Coupontext sieht der Kunde"
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        value={values.couponDetails.advantageType}
                        name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                          "couponDetails",
                          "advantageType"
                        )}
                        label="Art der Prämie"
                        options={advantageTypeOptions}
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>

                  {values.couponDetails.advantageType === CouponAdvantageType.EURO && (
                    <Grid container item spacing={4}>
                      <Grid item md={12}>
                        <b>Geldbetrag in Euro</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                            "couponDetails",
                            "advantageValue"
                          )}
                          label="Euro"
                          fullWidth={true}
                          variant="outlined"
                          readOnly={readOnly}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {values.couponDetails.advantageType === CouponAdvantageType.PERCENT && (
                    <Grid container item spacing={4}>
                      <Grid item md={12}>
                        <b>Rabatt in Prozent</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                            "couponDetails",
                            "advantageValue"
                          )}
                          label="% Rabatt"
                          fullWidth={true}
                          variant="outlined"
                          readOnly={readOnly}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item spacing={4}>
                    <Grid item md={12}>
                      <b>Benötigte Punkte</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormInput
                        type="number"
                        name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                          "couponDetails",
                          "requiredBonusPoints"
                        )}
                        label="Anzahl Punkte"
                        fullWidth={true}
                        variant="outlined"
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={4}>
                    <Grid item md={12}>
                      <b>Kontigent für Coupons</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormInput
                        type="number"
                        name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                          "couponDetails",
                          "maxCoupons"
                        )}
                        label="Anzahl Coupons"
                        fullWidth={true}
                        variant="outlined"
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={6}>
                      <FormRadioButton
                        name={nameof<BonusPremiumFormModel>("couponValidityType")}
                        value={values.couponValidityType}
                        label="Gültigkeit der Prämie"
                        options={couponValidityTypeOptions}
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                  {isInvalidAfterPeriod(values.couponValidityType) ? (
                    <Grid container item>
                      <Grid item md={12} style={{ marginBottom: theme.spacing(2) }}>
                        Ablaufzeitraum wählen
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                            "couponDetails",
                            "expiryMonths"
                          )}
                          label="Monate"
                          readOnly={readOnly}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item>
                      <Grid item md={12}>
                        <b>Gültigkeitsdatum wählen</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormDate
                          name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                            "couponDetails",
                            "expiryDate"
                          )}
                          label="Datum"
                          prefix="Bis"
                          minDate={getTomorrow()}
                          readOnly={readOnly}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item>
                    <Grid item md={4}>
                      <b>Gültig in folgenden Filialen</b>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item md={4}>
                      <FormSelect
                        label="Filiale/-gruppe"
                        name={nameof<BonusPremiumFormModel>("unitNumber")}
                        options={operationalUnitOptions}
                        fullWidth={true}
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item spacing={2}>
                    <Grid item md={12}>
                      <b>Coupon ID</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormInput
                        type="text"
                        name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                          "couponDetails",
                          "generatorPrefix"
                        )}
                        label="Präfix"
                        fullWidth={true}
                        variant="outlined"
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                  marginBottom: theme.spacing(3),
                }}
              >
                <ImsPaperHead text="Wann sollen die Coupons ausgespielt werden?" />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={4}>
                      <FormDate
                        name={nameof<BonusPremiumFormModel>("startDate")}
                        label="Datum"
                        prefix="Von"
                        testId="dt-sd"
                        readOnly={readOnly}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormDate
                        name={nameof<BonusPremiumFormModel>("endDate")}
                        label="Datum"
                        prefix="Bis"
                        helperText="Optional"
                        testId="dt-ed"
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              {!values.premiumNumber && (
                <Grid container item justifyContent="flex-end">
                  <FormCheckbox
                    name={nameof<BonusPremiumFormModel>("active")}
                    label="Prämie direkt aktivieren"
                    tooltip="Die Prämie wird sofort aktiviert"
                    readOnly={readOnly}
                  />
                </Grid>
              )}
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Abbrechen
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  data-testid="button-save"
                  variant="contained"
                  disabled={submitting || readOnly}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {values.premiumNumber ? "Prämie speichern" : "Prämie anlegen"}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </>
  );
};

interface BonusPremiumFormModel extends BonusPremiumDto {
  couponValidityType: "invalidAt" | "invalidPeriod";
  active: boolean;
}

const mapBonusPremiumToFormModel = (premium: BonusPremiumDto): BonusPremiumFormModel => {
  return {
    ...premium,
    couponValidityType:
      premium.couponDetails.expiryMonths && premium.couponDetails.expiryMonths > 0 ? "invalidPeriod" : "invalidAt",
    active: premium.premiumStatus === "ENABLED",
  };
};

const mapFormModelToBonusPremium = (premiumForm: BonusPremiumFormModel): BonusPremiumDto => {
  const premium = {
    ...premiumForm,
  };

  premium.couponDetails.generatorNumber = "DEFAULT-CODE-GENERATOR";
  premium.premiumStatus = premiumForm.active ? BonusPremiumStatus.ENABLED : BonusPremiumStatus.DISABLED;
  return premium;
};

const validateForm = (values: BonusPremiumFormModel) => {
  const errors: AnyObject = {};
  if (isBlank(values.premiumName)) {
    errors.premiumName = "Bitte geben Sie einen Namen für die Bonusprämie ein.";
  }

  if (isBlank(values.unitNumber)) {
    errors.unitNumber = "Pflichtfeld.";
  }

  if (values.startDate && values.endDate && values.endDate < values.startDate) {
    errors.endDate = "Das Bis-Datum darf nicht vor dem Von-Datum liegen.";
  }

  return validateCouponDetails(values, errors);
};

function validateCouponDetails(values: BonusPremiumFormModel, errors: AnyObject) {
  errors.couponDetails = {};
  if (isBlank(values.couponDetails.advantageInfo)) {
    errors.couponDetails.advantageInfo = "Bitte geben Sie den Anzeigenamen ein.";
  }
  if (values.couponDetails.advantageType !== CouponAdvantageType.OFFER && !values.couponDetails.advantageValue) {
    errors.couponDetails.advantageValue = "Bitte geben Sie einen Rabattbetrag ein.";
  }
  if (values.couponValidityType === "invalidAt" && !values.couponDetails.expiryDate) {
    errors.couponDetails.expiryDate = "Pflichtfeld";
  }
  if (values.couponValidityType === "invalidPeriod") {
    if (!values.couponDetails.expiryMonths) {
      errors.couponDetails.expiryMonths = "Pflichtfeld";
    } else if (!isPositiveInteger(values.couponDetails.expiryMonths)) {
      errors.couponDetails.expiryMonths = "nur ganze Zahlen > 0 erlaubt";
    }
  }
  if (isBlank(values.couponDetails.generatorPrefix)) {
    errors.couponDetails.generatorPrefix = "Pflichtfeld";
  } else if (values.couponDetails.generatorPrefix.length > 6) {
    errors.couponDetails.generatorPrefix = "max. 6 Zeichen";
  }

  if (values.couponDetails.expiryDate && !values.endDate) {
    errors.endDate = "Pflichtfeld, wenn die Prämie ein bestimmtes Ablaufdatum hat";
  }

  if (values.couponDetails.expiryDate && values.endDate && values.couponDetails.expiryDate < values.endDate) {
    errors.couponDetails.expiryDate = "Das Ablaufdatum darf nicht vor dem Bis-Datum liegen";
  }
  return errors;
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCreateBonusPremium,
      thunkUpdateBonusPremium,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusPremiumForm);
