import { Box, Theme, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";
import { COLOR_GRAY_2 } from "../atoms/ImsMaterialTheme";
import { connect, ConnectedProps } from "react-redux";
import { IStore } from "../../store/IStore";

interface TextDividerProps extends PropsWithChildren<{}> {
  visible?: boolean;
}

const TextDivider = (props: ThunkProps) => {
  const theme = useTheme();
  const classes = styles(theme, props.isMenuOpen);
  if (props.visible === false) return null;
  if (!props.isMenuOpen) {
    // The menu is collapsed => hide text
    return <Box sx={classes.textDivider}></Box>;
  }
  return <Box sx={classes.textDivider}>{props.children}</Box>;
};

const styles = (theme: Theme, isMenuOpen: boolean) => ({
  textDivider: {
    color: COLOR_GRAY_2,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    "&::before,&::after": {
      display: "block",
      content: "' '",
      background: COLOR_GRAY_2,
      width: "100%",
      height: "1px",
    },
    "&:before": {
      marginRight: isMenuOpen ? theme.spacing(2) : 0,
    },
    "&:after": {
      marginLeft: isMenuOpen ? theme.spacing(2) : 0,
    },
  },
});

const mapStateToProps = (state: IStore, ownProps: TextDividerProps) => ({
  isMenuOpen: state.menu?.open,
  ...ownProps,
});

const connector = connect(mapStateToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(TextDivider);
