import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { thunkReplaceIdentifications } from "../../store/actions/ReplacementCardActions";
import { CustomerIdentificationDto } from "../../store/models/identification/CustomerIdentificationDto";
import NotificationList from "../notification/NotificationList";
import { nameof } from "../atoms/Utils";
import { FormInput } from "../atoms/FormInput";
import { ReplacementIdentifactionDto } from "../../store/models/identification/ReplacementIdentifactionDto";

interface ReplaceIdentificationsDialogProps {
  setReplacementDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReplaceIdentificationsDialog = ({
  setReplacementDialogVisible,
  ...props
}: ReplaceIdentificationsDialogProps & ThunkProps) => {
  const theme = useTheme();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [errorText, setErrorText] = useState<string>("");
  const submit = (replaceIdentification: ReplacementIdentifactionDto) => {
    if (!props.customer || selectedCheckboxes.length === 0) {
      setErrorText("Bitte selektieren Sie eine Pass Id");
      return;
    }

    // Filtering only for selected Checkbox
    const selectedIdentifications = props.identifications.filter((identification) =>
      selectedCheckboxes.includes(identification.externalIdentificationNumber)
    );
    const replaceIdentificationDto: ReplacementIdentifactionDto = {
      customerIdentificationDto: selectedIdentifications,
      note: replaceIdentification.note,
    };
    props.thunkReplaceIdentifications(props.customer.customerNumber, replaceIdentificationDto);
    setReplacementDialogVisible(false);
  };

  const handleCheckboxChange = (externalIdentificationNumber: string) => {
    setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(externalIdentificationNumber)) {
        return prevSelected.filter((id) => id !== externalIdentificationNumber);
      } else {
        setErrorText("");
        return [...prevSelected, externalIdentificationNumber];
      }
    });
  };

  return (
    <Dialog onClose={() => setReplacementDialogVisible(false)} open={true}>
      <NotificationList isDialog />
      <DialogTitle>
        <b>Ersatzkarte wirklich anfordern?</b>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          render={({ handleSubmit, submitting }) => {
            return (
              <form key="AdjustmentForm" onSubmit={handleSubmit}>
                <Grid container item spacing={2} direction="column">
                  <Grid item>
                    <>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {props.identifications.map((identification) => (
                          <label key={identification.externalIdentificationNumber}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={nameof<CustomerIdentificationDto>("externalIdentificationNumber")}
                                    checked={selectedCheckboxes.includes(identification.externalIdentificationNumber)}
                                    onChange={() => handleCheckboxChange(identification.externalIdentificationNumber)}
                                  />
                                }
                                label={identification.externalIdentificationNumber}
                              />
                            </FormGroup>
                          </label>
                        ))}
                        <FormHelperText
                          error
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {errorText}
                        </FormHelperText>
                      </div>
                    </>
                  </Grid>
                  <Grid item>
                    <FormInput
                      type="textarea"
                      name={nameof<ReplacementIdentifactionDto>("note")}
                      label="Notiz"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  style={{
                    paddingTop: theme.spacing(4),
                  }}
                >
                  <Grid item>
                    <Button
                      color="secondary"
                      type="button"
                      variant="contained"
                      onClick={() => setReplacementDialogVisible(false)}
                    >
                      Zurück
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" disabled={submitting}>
                      Ersatzkarte anfordern
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
  identifications: state.identifications.identifications,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkReplaceIdentifications }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(ReplaceIdentificationsDialog);
