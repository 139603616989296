export enum CustomerType {
  INDIVIDUAL = "INDIVIDUAL",
  CORPORATE = "CORPORATE",
  UNKNOWN = "UNKNOWN",
}

export const customerTypeOptions = [
  {
    label: "Privat",
    value: CustomerType.INDIVIDUAL,
  },
  {
    label: "Gewerblich",
    value: CustomerType.CORPORATE,
  },
];
