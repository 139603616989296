import { UserStatus } from "../../../components/user/UserStatus";

export enum UserRole {
  SYSTEM_ADMINISTRATOR = "SYSTEM_ADMINISTRATOR",
  USER_ADMINISTRATOR = "USER_ADMINISTRATOR",
  PROGRAM_ADMINISTRATOR = "PROGRAM_ADMINISTRATOR",
  PROGRAM_ANALYST = "PROGRAM_ANALYST",
  CUSTOMER_SERVICE_AGENT = "CUSTOMER_SERVICE_AGENT",
  SALES_PERSON = "SALES_PERSON",
}

export interface UserDto {
  firstName: string;
  lastName: string;
  userNumber?: string;
  mailAddress?: string;
  password?: string;
  userName?: string;
  unitNumber?: string;
  userRoles: UserRole[];
  lastAccess?: string;
  status: UserStatus;
}
