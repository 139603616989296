import { AnyAction } from "redux";
import { GET_CASHBACK, UPDATE_CASHBACK } from "../actions/CashbackActions";
import { CashbackDto } from "../models/cashback/CashbackDto";

export interface ICashbackReducerState {
  cashback: CashbackDto;
}

export const cashback = (state: ICashbackReducerState, action: AnyAction): ICashbackReducerState => {
  switch (action.type) {
    case GET_CASHBACK:
    case UPDATE_CASHBACK:
      return {
        ...state,
        cashback: action.payload,
      };
    default:
      return state || {};
  }
};
