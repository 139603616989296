import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";

export const thunkDoubleOptIn =
  (customerNumber: string, token: string, accessToken: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.doubleOptIn(customerNumber, token, accessToken);

      dispatch(thunkCreateSuccessNotification("Double-Opt-In successfully!"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Can not opt in", e));
      return false;
    }
  };

export const thunkDoubleOptOut =
  (customerNumber: string, token: string, accessToken: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.doubleOptOut(customerNumber, token, accessToken);

      dispatch(thunkCreateSuccessNotification("Double-Opt-Out successfully!"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Can not opt out", e));
      return false;
    }
  };
