import { Field } from "react-final-form";
import { BlockPicker, ColorResult } from "react-color";
import { FormControl, FormHelperText, FormLabel, Tooltip, Typography } from "@mui/material";
import { Help } from "@mui/icons-material";

interface FormColorProps {
  name: string;
  label: string;
  fullWidth?: boolean;
  tooltipText: string;
  dataTestId: string;
}

const FormColor = ({ name, label, fullWidth, tooltipText, dataTestId }: FormColorProps) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormControl fullWidth={fullWidth} error={meta.touched && meta.error}>
          <FormLabel>
            {tooltipText ? (
              <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <Typography variant="body1" component="b">
                  {label}
                </Typography>
                <Tooltip data-testid={dataTestId} title={tooltipText} placement="right-start">
                  <Help color="primary" style={{ marginLeft: 4 }} />
                </Tooltip>
              </div>
            ) : (
              <Typography variant="body1" component="b">
                {label}
              </Typography>
            )}
          </FormLabel>
          <BlockPicker color={input.value} onChange={(color: ColorResult) => input.onChange(color.hex)} />
          {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default FormColor;
