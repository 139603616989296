import { Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { DEFAULT_LIMIT } from "../../api/SnowflakeApi";
import { IStore } from "../../store/IStore";
import { CustomerActivityDto } from "../../store/models/dashboard/CustomerActivityDto";
import {
  KNISTR_BLUE,
  KNISTR_CYAN,
  KNISTR_GREEN,
  KNISTR_ORANGE,
  KNISTR_PLUM,
  KNISTR_RED,
} from "../atoms/ImsMaterialTheme";
import { nameof } from "../atoms/Utils";

const CustomerActivityChart = (props: ThunkProps) => {
  const theme = useTheme();
  const { customerActivities } = props;
  if (!customerActivities) return null;
  return (
    <div style={{ width: "100%", height: 300 }}>
      <Typography
        variant="h4"
        style={{
          paddingLeft: theme.spacing(8),
          paddingBottom: theme.spacing(2),
        }}
      >
        Kundenaktivität der vergangenen {((customerActivities.offset || 0) + 1) * DEFAULT_LIMIT} Monate
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={customerActivities.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={nameof<CustomerActivityDto>("monthLabel")} />
          <YAxis />
          <Tooltip />
          <Area
            dataKey={nameof<CustomerActivityDto>("'0-30'")}
            type="monotone"
            name="0-30 Tage"
            stackId="1"
            stroke={KNISTR_BLUE}
            strokeWidth={3}
            fill={KNISTR_BLUE + "A0"}
          />
          <Area
            dataKey={nameof<CustomerActivityDto>("'31-90'")}
            type="monotone"
            name="31-90 Tage"
            stackId="1"
            stroke={KNISTR_PLUM}
            strokeWidth={3}
            fill={KNISTR_PLUM + "A0"}
          />
          <Area
            dataKey={nameof<CustomerActivityDto>("'91-180'")}
            type="monotone"
            name="91-180 Tage"
            stackId="1"
            stroke={KNISTR_RED}
            strokeWidth={3}
            fill={KNISTR_RED + "A0"}
          />
          <Area
            dataKey={nameof<CustomerActivityDto>("'181-360'")}
            type="monotone"
            name="181-360 Tage"
            stackId="1"
            stroke={KNISTR_ORANGE}
            strokeWidth={3}
            fill={KNISTR_ORANGE + "A0"}
          />
          <Area
            dataKey={nameof<CustomerActivityDto>("'>720'")}
            type="monotone"
            name=">720 Tage"
            stackId="1"
            stroke={KNISTR_CYAN}
            strokeWidth={3}
            fill={KNISTR_CYAN + "A0"}
          />
          <Area
            dataKey={nameof<CustomerActivityDto>("'not yet after registration'")}
            type="monotone"
            name="keine Aktivität"
            stackId="1"
            stroke={KNISTR_GREEN}
            strokeWidth={3}
            fill={KNISTR_GREEN + "A0"}
          />
          <Legend />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerActivities: state.dashboard.customerActivities,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerActivityChart);
