import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkDeleteFlowDetail } from "../../store/actions/FlowActions";
import { canMaintainFlows } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { FlowDetailsDto, FlowDirectionTrans } from "../../store/models/flow/FlowDto";
import ImsConfirmDialog from "../atoms/ImsConfirmDialog";

interface FlowTableMenuProps {
  flow: FlowDetailsDto;
  flowGroupNumber: string;
  flowDetailsNumber: string | undefined;
  getNameFromNumber: (number?: string) => string | undefined;
  setEditFlowDetails: React.Dispatch<React.SetStateAction<FlowDetailsDto>>;
  setFlowDetailsDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

function FlowTableMenu(props: FlowTableMenuProps & ThunkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  if (!canMaintainFlows()) return null;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };
  const handleEdit = (event: React.MouseEvent<HTMLElement>) => {
    props.setFlowDetailsDialogVisible(true);
    props.setEditFlowDetails({ ...props.flow });
    handleClose(event);
  };
  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    setConfirmVisible(true);
    handleClose(event);
  };

  return (
    <div>
      {confirmVisible && (
        <ImsConfirmDialog
          article="den"
          word="Flow"
          verb="löschen"
          hint={`${FlowDirectionTrans[props.flow.flowDirection]} - ${props.getNameFromNumber(
            props.flow.origin
          )} - ${props.getNameFromNumber(props.flow.target)}`}
          onConfirm={() => props.thunkDeleteFlowDetail(props.flow, props.flowGroupNumber)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleEdit}>Bearbeiten</MenuItem>
        <MenuItem
          onClick={(e) => {
            handleDelete(e);
          }}
        >
          Löschen
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkDeleteFlowDetail }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(FlowTableMenu);
