import { AnyAction } from "redux";
import { GET_ALL_EIN_GENERATOR_TEMPLATES } from "../actions/EinGeneratorTemplateAction";
import { EinGeneratorTemplateDto } from "../models/eingeneratortemplate/EinGeneratorTemplateDto";

export interface IEinGeneratorTemplateReducerState {
  einGeneratorTemplates: EinGeneratorTemplateDto[];
}

export const einGeneratorTemplates = (
  state: IEinGeneratorTemplateReducerState,
  action: AnyAction
): IEinGeneratorTemplateReducerState => {
  switch (action.type) {
    case GET_ALL_EIN_GENERATOR_TEMPLATES:
      return {
        ...state,
        einGeneratorTemplates: action.payload,
      };
    default:
      return state || { einGeneratorTemplates: [] };
  }
};
