import { AnyAction } from "redux";

import {
  CHANGE_CAROUSELS,
  CREATE_CAROUSEL,
  DELETE_CAROUSEL,
  GET_ALL_CAROUSELS,
  GET_CAROUSEL_BY_NUMBER,
  UPDATE_CAROUSEL,
} from "../actions/CarouselActions";
import { CarouselDto } from "../models/appcontent/CarouselDto";

export interface ICarouselReducerState {
  carousels: CarouselDto[];
}

export const carousels = (state: ICarouselReducerState, action: AnyAction): ICarouselReducerState => {
  switch (action.type) {
    case GET_ALL_CAROUSELS:
      return {
        ...state,
        carousels: action.payload,
      };
    case CREATE_CAROUSEL:
      return {
        ...state,
        carousels: [...state.carousels, action.payload],
      };
    case UPDATE_CAROUSEL:
      const updatedCarousel = action.payload as CarouselDto;
      return {
        ...state,
        carousels: state.carousels.map((carousel) =>
          carousel.carouselNumber === updatedCarousel.carouselNumber ? updatedCarousel : carousel
        ),
      };
    case GET_CAROUSEL_BY_NUMBER:
      const getCarousel = action.payload as CarouselDto;
      return {
        ...state,
        carousels: state.carousels.map((carousel) =>
          carousel.carouselNumber === getCarousel.carouselNumber ? getCarousel : carousel
        ),
      };
    case DELETE_CAROUSEL:
      const deletedAppContent = action.payload as CarouselDto;
      return {
        ...state,
        carousels: state.carousels.filter((carousel) => carousel.carouselNumber !== deletedAppContent.carouselNumber),
      };
    case CHANGE_CAROUSELS:
      return {
        ...state,
        carousels: action.payload,
      };
    default:
      return state || { carousels: [] };
  }
};
