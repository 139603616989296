import { AppBar, Grid, Paper, Tab, Tabs, useTheme } from "@mui/material";
import { useState } from "react";
import { canReadFlows } from "../../store/actions/LoginActions";
import { FlowType } from "../../store/models/flow/FlowDto";
import MenuLayout from "../app/MenuLayout";
import FlowFormPage from "./FlowFormPage";

// Tab names
const CIRCLE = "Circle";
const IDENTIFICATION_TYPE = "Wallet";

const Flows = () => {
  const theme = useTheme();
  const [tab, setTab] = useState("Circle");

  return (
    <MenuLayout headline={"Flows | " + tab}>
      <Grid
        container
        justifyContent="flex-end"
        style={{
          padding: theme.spacing(2),
          marginTop: theme.spacing(2),
        }}
      ></Grid>

      <Paper>
        <AppBar position="static">
          <Tabs value={tab} onChange={(_event, newValue) => setTab(newValue)}>
            {canReadFlows() && <Tab label={CIRCLE} value={CIRCLE} />}
            {canReadFlows() && <Tab label={IDENTIFICATION_TYPE} value={IDENTIFICATION_TYPE} />}
          </Tabs>
        </AppBar>
        <Grid
          style={{
            padding: theme.spacing(4),
          }}
        >
          {getSelectedTable(tab)}
        </Grid>
      </Paper>
    </MenuLayout>
  );
};

function getSelectedTable(selectedTab: string) {
  switch (selectedTab) {
    case CIRCLE:
      return <FlowFormPage flowGroup={selectedTab.toUpperCase()} flowType={FlowType.CIRCLE} />;
    case IDENTIFICATION_TYPE:
      return <FlowFormPage flowGroup={selectedTab.toUpperCase()} flowType={FlowType.IDENTIFICATION} />;
  }
}

export default Flows;
