import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, FormControl, SelectChangeEvent, Box } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

const languageMap: { [key: string]: string } = {
    en: "English",
    de: "Deutsch",
    // Add more languages as needed
  };

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    i18n.changeLanguage(event.target.value);
  };

  const renderValue = (value: string) => {
    return (
      <Box display="flex" alignItems="center">
        <LanguageIcon style={{ marginRight: 8 }} />
        {languageMap[value]}
      </Box>
    );
  };

  return (
    <FormControl variant="standard">
      <Select
        labelId="language-select-label"
        id="language-select"
        value={i18n.language}
        onChange={handleChange}
        renderValue={renderValue}
        disableUnderline
        autoWidth
        style={{ border: "none" }}
      >
        {Object.entries(languageMap).map(([code, name]) => (
          <MenuItem key={code} value={code}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
