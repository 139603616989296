import { Button, Grid, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../../atoms/ImsTable";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { Add } from "@mui/icons-material";
import CarouselMenu from "./AppContentCarouselMenu";
import { canMaintainAppContent, canReadAppContent } from "../../../store/actions/LoginActions";
import { CarouselDto } from "../../../store/models/appcontent/CarouselDto";
import { useMountEffect } from "../../atoms/Utils";
import {thunkChangeCarousel, thunkGetAllCarousels} from "../../../store/actions/CarouselActions";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { UrlPathCarouselCreate } from "../../../api/url";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AppContentCarouselView = (props: ThunkProps) => {

  const { t } = useTranslation();

  useMountEffect(() => {
    if (!props.carouselList.length) {
      canReadAppContent() && props.thunkGetAllCarousels();
    }
  });
  const theme = useTheme();
  let [updatedRows, setUpdatedRows] = useState<ImsTableRow<CarouselDto>[]>([]);
  const { carouselList } = props;

  const getChangeOrderValues = (actualRow: CarouselDto, nextRow: CarouselDto): CarouselDto[] => {
    const actualOrderValue = actualRow.order;
    actualRow.order = nextRow.order;
    nextRow.order = actualOrderValue;
    return [actualRow, nextRow];
  };

  const handleOrderChange = async (rows: ImsTableRow<CarouselDto>[], carouselRow: CarouselDto, direction: number) => {
    const currentIndex = rows.findIndex((row) => row.value.carouselNumber === carouselRow.carouselNumber);
    const swapIndex = currentIndex + direction;
    if (swapIndex < 0 || swapIndex >= rows.length) return;
    const nextRow = rows[swapIndex].value;
    const changedOrderValueRows = getChangeOrderValues(carouselRow, nextRow);
    let success = await props.thunkChangeCarousel(changedOrderValueRows);
    if (success) {
      setUpdatedRows(updatedRows);
    }
  };

  const headCells: ImsHeadCell<CarouselDto>[] = [
    { val: (val) => val.title, node: t("appContentCarousel.tableHeadlineTitle") },
    { val: (val) => val.title, node: t("appContentCarousel.tableHeadlineSubtitle") },
    { val: (val) => val, node: t("appContentCarousel.tableHeadlineOrder") },
  ];

  const rows: ImsTableRow<CarouselDto>[] = carouselList.map((row) => ({
    value: row,
    nodes: [],
  }));

  rows.forEach((row, index, array) => {
    const isFirst = index === 0;
    const isLast = index === array.length - 1;
    row.nodes = [
      row.value.title,
      row.value.subtitle,
      <>
        {!isLast && (
            <Button onClick={() => handleOrderChange(array, row.value, 1)}>
              <ArrowDownwardIcon data-testid={"downward-icon"} />
            </Button>
        )}
        {!isFirst && (
            <Button onClick={() => handleOrderChange(array, row.value, -1)}>
              <ArrowUpwardIcon data-testid={"upward-icon"} />
            </Button>
        )}
      </>,
      <CarouselMenu carousel={row.value} key={"CarouselMenu" + index} />,
    ];
  });
  return (
    <Grid container>
      {canMaintainAppContent() && (
        <Grid container direction="column" alignItems="flex-end">
          <Button
            component={Link}
            to={UrlPathCarouselCreate}
            startIcon={<Add />}
            variant="contained"
            color="primary"
            style={{ marginBottom: theme.spacing(4) }}
          >
            {t("appContentCarousel.buttonCreate")}
          </Button>
        </Grid>
      )}
      <ImsTable hasMenu={true} headCells={headCells} rows={rows} disableSorting={true} />
    </Grid>
  );
};
const mapStateToProps = (state: IStore) => ({
  carouselList: state.carousels.carousels,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetAllCarousels,
      thunkChangeCarousel,
    },
    dispatch
  );
const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(AppContentCarouselView);
