import { Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import { KNISTR_BLUE, KNISTR_CYAN } from "./ImsMaterialTheme";

interface ImsPaperHeadProps {
  text: string;
  style?: React.CSSProperties;
}

const ImsPaperHead = (props: ImsPaperHeadProps) => {
  const theme = useTheme();
  const classes = styles(theme);
  return (
    <Typography style={props.style} sx={classes.paperHead} variant="h2">
      {props.text}
    </Typography>
  );
};

const styles = (theme: Theme) => ({
  paperHead: {
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(-6),
    borderRadius: theme.spacing(1),
    color: KNISTR_BLUE,
    backgroundColor: KNISTR_CYAN,
    float: "left",
    marginBottom: theme.spacing(1),
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.5)",
  },
});

export default ImsPaperHead;
