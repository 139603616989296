import { Button, Dialog, DialogContent, DialogTitle, Grid, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { BooleanThunkAction, IStore } from "../../store/IStore";
import NotificationList from "../notification/NotificationList";
import { CashbackRateRuleDto } from "../../store/models/cashback/CashbackRateRuleDto";
import { FormInput } from "../atoms/FormInput";
import { ValidationErrors, nameof } from "../atoms/Utils";

export enum RuleRateDialogMode {
  DELETE = "DELETE",
  EDIT = "EDIT",
  NEW = "NEW",
  HIDDEN = "HIDDEN",
}

interface CashbackRateRuleDialogProps {
  cashbackRateRule?: CashbackRateRuleDto;
  mode: RuleRateDialogMode;
  onCloseDialog: () => void;
  updateCashbackRateRule: (cashbackRateRule: CashbackRateRuleDto) => Promise<boolean | BooleanThunkAction>;
  deleteCashbackRateRule: (cashbackRateRule: CashbackRateRuleDto) => Promise<boolean | BooleanThunkAction>;
}

const CashbackRateRuleDialog = ({ ...props }: CashbackRateRuleDialogProps & ThunkProps) => {
  const theme = useTheme();
  const submit = async (cashbackRateRule: CashbackRateRuleDto) => {
    let success;

    if (RuleRateDialogMode.DELETE === props.mode) {
      success = await props.deleteCashbackRateRule(cashbackRateRule);
    } else {
      success = await props.updateCashbackRateRule(cashbackRateRule);
    }

    if (success) {
      props.onCloseDialog();
    }
  };

  const validateForm = (values: CashbackRateRuleDto) => {
    const errors: ValidationErrors<CashbackRateRuleDto> = {};
    if (isNaN(values.cashbackRatePercent)) {
      errors.cashbackRatePercent = "Pflichtfeld";
    }
    if (isNaN(values.minimumEarnAmount)) {
      errors.minimumEarnAmount = "Pflichtfeld";
    }
    if (!isNaN(values.minimumEarnAmount) && !isNaN(values.maximumEarnAmount)) {
      let min = parseFloat(values.minimumEarnAmount.toString());
      let max = parseFloat(values.maximumEarnAmount.toString());
      if (min > max) {
        errors.minimumEarnAmount = "Min must be less than max";
        errors.maximumEarnAmount = "Min must be less than max";
      }
    }
    return errors;
  };

  const getDialogTitle = () => {
    switch (props.mode) {
      case RuleRateDialogMode.NEW:
        return "Neue Regel";
      case RuleRateDialogMode.EDIT:
        return "Bearbeiten Regel";
      case RuleRateDialogMode.DELETE:
        return "Löschen Regel";
      default:
        return "";
    }
  };
  const getSubmitButtonTitle = () => {
    switch (props.mode) {
      case RuleRateDialogMode.NEW:
        return "Regel anlegen";
      case RuleRateDialogMode.EDIT:
        return "Regel speichern";
      case RuleRateDialogMode.DELETE:
        return "Regel löschen";
      default:
        return "";
    }
  };

  const isReadOnly = RuleRateDialogMode.DELETE === props.mode;
  const initialValues = { ...props.cashbackRateRule };
  return (
    <Dialog onClose={() => props.onCloseDialog()} open={true}>
      <NotificationList isDialog />
      <DialogTitle>
        <b>{getDialogTitle()}</b>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={initialValues}
          validate={validateForm}
          render={({ handleSubmit, submitting }) => {
            return (
              <form key="AdjustmentForm" onSubmit={handleSubmit}>
                <Grid container item spacing={2} mt={1}>
                  <Grid item sm={12} mb={2}>
                    <FormInput
                      readOnly={isReadOnly}
                      type="number"
                      name={nameof<CashbackRateRuleDto>("cashbackRatePercent")}
                      label="Cashback-Rate in %"
                      fullWidth={true}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={12} mb={2}>
                    <FormInput
                      readOnly={isReadOnly}
                      type="number"
                      name={nameof<CashbackRateRuleDto>("minimumEarnAmount")}
                      label="Minimale Summe"
                      fullWidth={true}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={12} mb={2}>
                    <FormInput
                      readOnly={isReadOnly}
                      type="number"
                      name={nameof<CashbackRateRuleDto>("maximumEarnAmount")}
                      label="Maximale Summe"
                      fullWidth={true}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {props.mode === RuleRateDialogMode.DELETE && (
                  <Grid>
                    <strong>Möchten Sie den Regel wirklich löschen?</strong>
                  </Grid>
                )}
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  style={{
                    paddingTop: theme.spacing(4),
                  }}
                >
                  <Grid item>
                    <Button color="secondary" type="button" variant="contained" onClick={() => props.onCloseDialog()}>
                      Zurück
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={submitting}
                      color={props.mode === RuleRateDialogMode.DELETE ? "error" : "primary"}
                    >
                      {getSubmitButtonTitle()}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CashbackRateRuleDialog);
