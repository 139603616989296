import { Launch } from "@mui/icons-material";
import { Button, Grid, Link, Paper, Theme, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerDetails, UrlPathFamily } from "../../api/url";
import { thunkGetFamilyByCustomerNumber } from "../../store/actions/FamilyActions";
import { canMaintainFamilies } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import {
  FamilyMembershipDto,
  FamilyMembershipRole,
  memberRoleOptions,
} from "../../store/models/family/FamilyMembershipDto";
import { GrayLine } from "../atoms/GrayLine";
import { COLOR_GRAY_4 } from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";

export function getFamilyMemberName(member: FamilyMembershipDto) {
  if (member.customerDto) {
    if (member.customerDto.firstName) {
      return member.customerDto.firstName + " " + member.customerDto.lastName;
    }
    return member.customerDto.mailAddress;
  }
  return "Unbekannter Nutzer";
}

function hiddenMembers(hiddenMembers: number) {
  return hiddenMembers === 1 ? "+ 1 weiteres Mitglied" : "+ " + hiddenMembers + " weitere Mitglieder";
}

const Family = (props: ThunkProps) => {
  const theme = useTheme();
  const visibleFamilyMembers = 3;
  if (!props.customerNumber) return null;
  if (!props.family || !props.family.familyMembers.some((member) => member.customerNumber === props.customerNumber)) {
    props.thunkGetFamilyByCustomerNumber(props.customerNumber);
    return null;
  }
  // show selected member on top
  props.family.familyMembers.sort((a, _b) => (a.customerNumber === props.customerNumber ? -1 : 1));
  return (
    <Grid height={"100%"}>
      <Paper
        style={{
          padding: theme.spacing(3),
          height: "100%",
        }}
      >
        <ImsPaperHead text="Familiengruppe" />
        <Grid container>
          {props.family.familyMembers.slice(0, visibleFamilyMembers).map((member, index) => (
            <div
              style={{
                width: "100%",
                fontWeight: member.customerNumber === props.customerNumber ? "bold" : undefined,
              }}
              key={member.customerNumber}
            >
              <Grid container alignItems="center" style={{ padding: theme.spacing(1, 1, 0, 1) }}>
                {getFamilyMemberName(member)}
                {member.customerDto && member.customerNumber !== props.customerNumber && (
                  <Link component={RouterLink} to={UrlPathCustomerDetails.replace(":id", member.customerNumber)}>
                    <Launch
                      style={{
                        marginLeft: theme.spacing(1),
                        marginTop: "3px",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                )}
              </Grid>
              {memberRole(member.roleName, theme)}
              {props.family && index < props.family.familyMembers.length - 1 && index < visibleFamilyMembers - 1 && (
                <GrayLine />
              )}
            </div>
          ))}
          {props.family.familyMembers.length > visibleFamilyMembers && (
            <Typography
              variant="caption"
              style={{
                padding: theme.spacing(1, 1, 0, 1),
              }}
            >
              {hiddenMembers(props.family.familyMembers.length - visibleFamilyMembers)}
            </Typography>
          )}
          {canMaintainFamilies() && (
            <Grid container justifyContent="flex-end">
              <Link component={RouterLink} to={UrlPathFamily.replace(":id", props.customerNumber)}>
                <Button color="primary" type="button" variant="contained" style={{ marginRight: theme.spacing(3) }}>
                  Bearbeiten
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

const memberRole = (memberRole: FamilyMembershipRole, theme: Theme) => (
  <span style={{ color: COLOR_GRAY_4, padding: theme.spacing(0, 1, 0, 1) }}>
    {memberRoleOptions.find((memberRoleOption) => memberRoleOption.value === memberRole)?.label}
  </span>
);

const mapStateToProps = (state: IStore) => ({
  customerNumber: state.customerCare.customer?.customerNumber,
  family: state.customerCare.family,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetFamilyByCustomerNumber }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Family);
