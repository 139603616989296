import { AppBar, Grid, Paper, Tab, Tabs, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlPathAppContentCarousel, UrlPathAppContentLinks, UrlPathAppContentSplash } from "../../api/url";
import { canReadAppContent } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import AppContentListView from "./links/AppContentListView";
import UnauthorizedPage from "../app/UnauthorizePage";
import AppContentSplashView from "./splash/AppContentSplashView";
import AppContentCarouselView from "./carousel/AppContentCarouselView";
import { useTranslation } from "react-i18next";


const AppContentPage = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // Tab names
  const LINKS = t("appContentPage.tableHeadlineLinks");
  const CAROUSEL = t("appContentPage.tableHeadlineCarousel");
  const SPLASH = t("appContentPage.tableHeadlineStartScreen");

  const getSelectedTab = (pathname: string) => {
    switch (pathname) {
      case UrlPathAppContentLinks:
        return LINKS;
      case UrlPathAppContentCarousel:
        return CAROUSEL;
      case UrlPathAppContentSplash:
        return SPLASH;
    }
    return "";
  };

  const getSelectedTable = (selectedTab: string) => {
    switch (selectedTab) {
      case LINKS:
        return <AppContentListView />;
      case CAROUSEL:
        return <AppContentCarouselView />;
      case SPLASH:
        return <AppContentSplashView />;
    }
  };

  const getUrlByTab = (selectedTab: string) => {
    switch (selectedTab) {
      case LINKS:
        return UrlPathAppContentLinks;
      case CAROUSEL:
        return UrlPathAppContentCarousel;
      case SPLASH:
        return UrlPathAppContentSplash;
    }
    return "";
  };

  const selectedTab = getSelectedTab(location.pathname);

  if (!canReadAppContent()) {
    return <UnauthorizedPage />;
  }


  return (
    <MenuLayout headline={t("appContentPage.pageTitle") + selectedTab}>
      <Paper>
        <AppBar position="static">
          <Tabs value={selectedTab} onChange={(_event, newValue) => navigate(getUrlByTab(newValue))}>
            <Tab label={LINKS} value={LINKS} />
            <Tab label={CAROUSEL} value={CAROUSEL} />
            <Tab label={SPLASH} value={SPLASH} />
          </Tabs>
        </AppBar>
        <Grid
          style={{
            padding: theme.spacing(4),
          }}
        >
          {getSelectedTable(selectedTab)}
        </Grid>
      </Paper>
    </MenuLayout>
  );
};


export default AppContentPage;
