import { Grid, Link, Paper, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { thunkFindCoupons } from "../../store/actions/CouponActions";
import { IStore } from "../../store/IStore";
import ImsPaperHead from "../atoms/ImsPaperHead";
import CouponDialog from "./CouponDialog";
import CouponTable from "./CouponTable";

const Coupons = (props: ThunkProps) => {
  const theme = useTheme();
  const { coupons, customer } = props;

  const [couponDialogVisible, setCouponDialogVisible] = useState(false);

  if (customer?.customerNumber && !coupons) {
    props.thunkFindCoupons(customer.customerNumber);
    return null;
  }
  if (!coupons?.length) return null;

  return (
    <Grid item md={12}>
      {couponDialogVisible && <CouponDialog setCouponDialogVisible={setCouponDialogVisible} />}
      <Paper
        style={{
          padding: theme.spacing(3, 0, 3, 0),
          margin: theme.spacing(6, 0, 0, 0),
        }}
      >
        <ImsPaperHead text="Coupons" style={{ marginLeft: theme.spacing(3) }} />
        <Grid
          item
          md={12}
          style={{
            padding: theme.spacing(0),
          }}
        >
          <CouponTable maxCouponTableSize={3} />
        </Grid>
        <Grid container justifyContent="flex-end" style={{ padding: theme.spacing(3, 3, 0, 0) }}>
          <Link
            onClick={() => {
              setCouponDialogVisible(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="button">mehr anzeigen</Typography>
          </Link>
        </Grid>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state: IStore) => ({
  coupons: state.coupons?.coupons,
  customer: state.customerCare.customer,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ thunkFindCoupons }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Coupons);
