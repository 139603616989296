import { Button, Grid, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../../atoms/ImsTable";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { Add } from "@mui/icons-material";
import { UrlPathAppContentCreate } from "../../../api/url";
import { AppLinkDto } from "../../../store/models/appcontent/AppLinkDto";
import { thunkChangeAppLink, thunkGetAllAppLinks } from "../../../store/actions/AppLinkActions";
import AppContentListMenu from "./AppContentListMenu";
import { useState } from "react";
import { canMaintainAppContent, canReadAppContent } from "../../../store/actions/LoginActions";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useMountEffect } from "../../atoms/Utils";
import { useTranslation } from "react-i18next";

const AppContentLinkListView = (props: ThunkProps) => {

  const { t } = useTranslation();

  useMountEffect(() => {
    if (!props.appLinkList.length) {
      canReadAppContent() && props.thunkGetAllAppLinks();
    }
  });
  const theme = useTheme();
  let [updatedRows, setUpdatedRows] = useState<ImsTableRow<AppLinkDto>[]>([]);
  const { appLinkList } = props;
  const getChangeOrderValues = (actualRow: AppLinkDto, nextRow: AppLinkDto): AppLinkDto[] => {
    const actualOrderValue = actualRow.order;
    actualRow.order = nextRow.order;
    nextRow.order = actualOrderValue;
    return [actualRow, nextRow];
  };
  const handleOrderChange = async (rows: ImsTableRow<AppLinkDto>[], appLinkRow: AppLinkDto, direction: number) => {
    const currentIndex = rows.findIndex((row) => row.value.appLinkNumber === appLinkRow.appLinkNumber);
    const swapIndex = currentIndex + direction;
    if (swapIndex < 0 || swapIndex >= rows.length) return;
    const nextRow = rows[swapIndex].value;
    const changedOrderValueRows = getChangeOrderValues(appLinkRow, nextRow);
    let success = await props.thunkChangeAppLink(changedOrderValueRows);
    if (success) {
      setUpdatedRows(updatedRows);
    }
  };
  const headCells: ImsHeadCell<AppLinkDto>[] = [
    { val: (val) => val.url, node: t("appContentPage.tableHeadlineUrl") },
    { val: (val) => val.text, node: t("appContentPage.tableHeadlineText") },
    { val: (val) => val.position, node: t("appContentPage.tableHeadlinePosition") },
    { val: (val) => val, node: t("appContentPage.tableHeadlineOrder") },
  ];
  const groupedRows: { [key: string]: ImsTableRow<AppLinkDto>[] } = appLinkList.reduce(
    (acc, row) => {
      if (!acc[row.position]) acc[row.position] = [];
      acc[row.position].push({ value: row, nodes: [] });
      return acc;
    },
    {} as { [key: string]: ImsTableRow<AppLinkDto>[] }
  );
  const rows: ImsTableRow<AppLinkDto>[] = [];
  Object.keys(groupedRows).forEach((position) => {
    groupedRows[position].forEach((row, index, array) => {
      const isFirst = index === 0;
      const isLast = index === array.length - 1;
      row.nodes = [
        row.value.url,
        row.value.text,
        row.value.position,

        <>
          {!isLast && (
            <Button onClick={() => handleOrderChange(array, row.value, 1)}>
              <ArrowDownwardIcon data-testid={"downward-icon"} />
            </Button>
          )}

          {!isFirst && (
            <Button onClick={() => handleOrderChange(array, row.value, -1)}>
              <ArrowUpwardIcon data-testid={"upward-icon"} />
            </Button>
          )}
        </>,
        <AppContentListMenu appLink={row.value} key={"AppContentListMenu" + index} />,
      ];
      rows.push(row);
    });
  });
  return (
    <Grid container>
      {canMaintainAppContent() && (
        <Grid container direction="column" alignItems="flex-end">
          <Button
            component={Link}
            to={UrlPathAppContentCreate}
            startIcon={<Add />}
            variant="contained"
            color="primary"
            style={{ marginBottom: theme.spacing(4) }}
          >
            {t("appContentPage.buttonCreate")}
          </Button>
        </Grid>
      )}
      <ImsTable hasMenu={true} headCells={headCells} rows={rows} disableSorting={true} />
    </Grid>
  );
};
const mapStateToProps = (state: IStore) => ({
  appLinkList: state.appLinks.links,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetAllAppLinks,
      thunkChangeAppLink,
    },
    dispatch
  );
const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(AppContentLinkListView);
