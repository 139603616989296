import { FormHelperText, List, ListItemButton, TablePagination, Theme, useTheme } from "@mui/material";
import React, { useMemo, Dispatch, SetStateAction, useState } from "react";
import { Field } from "react-final-form";
import { FilterDto } from "../../store/models/bonusRule/BonusRuleDto";
import { COLOR_GRAY_4, KNISTR_RED } from "../atoms/ImsMaterialTheme";
import { nameof } from "../atoms/Utils";

interface FilterValueListProps {
  selectedFilterValues: string[];
  filterValues: string[];
  setSelectedFilterValues: Dispatch<SetStateAction<string[]>>;
  filterSearchValue: string;
}

export const FilterValueList = React.memo((props: FilterValueListProps) => {
  const { selectedFilterValues } = props;
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const classes = styles(theme);
  const selectFilterValue = (filterValue: string) => {
    props.setSelectedFilterValues((values) => {
      if (!values.includes(filterValue)) return [...values, filterValue];
      return values.filter((value) => value !== filterValue);
    });
  };
  const getFilterSearch: string[] = useMemo(() => {
    return props.filterValues.filter((value) => !props.filterSearchValue || value.includes(props.filterSearchValue));
  }, [props.filterValues, props.filterSearchValue]);
  return (
    <Field name={nameof<FilterDto>("ruleFilterValues")}>
      {({ meta }) => {
        const hasErrors = Boolean(meta.error && meta.touched);
        return (
          <>
            <List
              style={{
                border: "1px solid",
                borderColor: hasErrors ? KNISTR_RED : COLOR_GRAY_4,
                borderRadius: 8,
                height: 300,
                overflow: "auto",
              }}
            >
              {getFilterSearch.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((filterValue) => (
                <ListItemButton
                  key={filterValue}
                  selected={selectedFilterValues.includes(filterValue)}
                  onClick={() => {
                    selectFilterValue(filterValue);
                  }}
                >
                  {filterValue}
                </ListItemButton>
              ))}
            </List>
            {props.filterValues.length > 0 && (
              <TablePagination
                component="div"
                count={getFilterSearch.length}
                page={page}
                showFirstButton
                showLastButton
                onPageChange={(event, val) => setPage(val)}
                rowsPerPage={itemsPerPage}
                sx={classes.pagination}
                size="small"
                align="center"
                onRowsPerPageChange={(event) => {
                  setItemsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            )}

            {hasErrors && <FormHelperText style={{ color: KNISTR_RED }}>{meta.error}</FormHelperText>}
          </>
        );
      }}
    </Field>
  );
});

const styles = (theme: Theme) => ({
  pagination: {
    padding: 0,
    "& .MuiToolbar-root": {
      padding: 0,
    },
  },
});
