import { Button, Grid, Paper, useTheme } from "@mui/material";
import React, { OptionHTMLAttributes, useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerDetails } from "../../api/url";
import { thunkClearCustomer, thunkGetCustomer } from "../../store/actions/CustomerCareActions";
import { thunkGetCustomerPreferences, thunkUpdateCustomerPreferences } from "../../store/actions/PreferenceActions";
import { IStore } from "../../store/IStore";
import { AnswerOptionDto } from "../../store/models/preferences/AnswerOptionDto";
import { CustomerPreferenceMap } from "../../store/models/preferences/CustomerPreferenceDto";
import { QuestionType } from "../../store/models/preferences/QuestionType";
import MenuLayout from "../app/MenuLayout";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";

function buildOptions(options: AnswerOptionDto[], multiple: boolean): OptionHTMLAttributes<HTMLOptionElement>[] {
  const htmlOptions = options.map((option) => ({
    label: option.label,
    value: option.optionNumber,
  }));
  if (multiple) return htmlOptions;
  return [
    {
      label: "Bitte auswählen",
      value: "",
    },
    ...htmlOptions,
  ];
}

const CustomerCarePreferencesPage = (props: ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const { id } = useParams<"id">();

  const { customerPreferences, customerNumber } = props;
  if (!id) return null;
  if (!customerNumber && id) {
    props.thunkClearCustomer();
    props.thunkGetCustomer(id);
    return null;
  }
  if (!customerNumber) return null;
  if (redirect) {
    return <Navigate to={UrlPathCustomerDetails.replace(":id", id)} />;
  }
  if (!customerPreferences) {
    props.thunkGetCustomerPreferences(customerNumber);
    return null;
  }

  const preferenceMap: CustomerPreferenceMap = {};
  customerPreferences.questions.forEach((question) => {
    const options = question.options.filter((option) => option.selected).map((option) => option.optionNumber);
    preferenceMap[question.questionNumber] = question.type === QuestionType.MULTIPLE ? options : options[0];
  });
  return (
    <MenuLayout headline="Präferenzfragen">
      <Form
        onSubmit={(preferenceMap) => {
          props.thunkUpdateCustomerPreferences(customerNumber, preferenceMap);
        }}
        initialValues={preferenceMap}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  margin: theme.spacing(3, 0, 3, 0),
                }}
              >
                <ImsPaperHead text="Fragen" style={{ marginBottom: theme.spacing(4) }} />
                <Grid container spacing={4}>
                  {customerPreferences.questions.map((question) => (
                    <React.Fragment key={question.questionNumber}>
                      <Grid item md={6}>
                        <b>{question.label}</b>
                      </Grid>
                      <Grid item md={6}>
                        <FormSelect
                          multiple={question.type === QuestionType.MULTIPLE}
                          name={question.questionNumber}
                          fullWidth={true}
                          label=""
                          options={buildOptions(question.options, question.type === QuestionType.MULTIPLE)}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Paper>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Abbrechen
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Speichern
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerPreferences: state.preferences.customerPreferences,
  customerNumber: state.customerCare.customer?.customerNumber,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkClearCustomer,
      thunkGetCustomer,
      thunkGetCustomerPreferences,
      thunkUpdateCustomerPreferences,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerCarePreferencesPage);
