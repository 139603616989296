import { Button, Dialog, DialogContent, DialogTitle, Grid, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkInviteFamilyMember } from "../../store/actions/FamilyActions";
import { IStore } from "../../store/IStore";
import { InvitationDto, InvitationType } from "../../store/models/InvitationDto";
import { FormInput } from "../atoms/FormInput";
import { isBlank, nameof, ValidationErrors } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";

interface FamilyInvitationProps {
  closeInvitation: () => void;
}

const FamilyInvitation = (props: FamilyInvitationProps & ThunkProps) => {
  const theme = useTheme();
  const { closeInvitation } = props;

  const familyInvitation = async (familyInvitation: InvitationDto) => {
    familyInvitation.invitationType = InvitationType.INVITE_TO_FAMILY;
    await props.thunkInviteFamilyMember(familyInvitation);
    closeInvitation();
  };
  const idParam = useParams<"id">();
  if (!idParam) return null;
  const validateForm = (values: InvitationDto) => {
    const errors: ValidationErrors<InvitationDto> = {};
    if (isBlank(values.mailAddress)) {
      errors.mailAddress = "Pflichtfeld";
    }
    return errors;
  };
  return (
    <Form
      onSubmit={familyInvitation}
      initialValues={{
        customerNumber: idParam.id,
      }}
      validate={validateForm}
      render={({ handleSubmit, submitting }) => (
        <Dialog onClose={() => closeInvitation()} open={true}>
          <NotificationList isDialog />
          <DialogTitle>Mitglied einladen</DialogTitle>
          <DialogContent style={{ paddingBottom: theme.spacing(4) }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<InvitationDto>("mailAddress")}
                    label="E-Mail"
                  />
                </Grid>
                <Grid container item justifyContent="flex-end">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => closeInvitation()}
                    style={{ marginRight: theme.spacing(2) }}
                  >
                    Abbrechen
                  </Button>
                  <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                    Speichern
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  customerNumber: state.customerCare.customer?.customerNumber,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkInviteFamilyMember,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(FamilyInvitation);
