import { Button, Grid, Paper, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { UrlPathBonus } from "../../api/url";
import {
  thunkAddBonusRule,
  thunkAddBonusRuleWithFile,
  thunkUpdateBonusRule,
} from "../../store/actions/BonusRuleActions";
import { IStore } from "../../store/IStore";
import {
  BonusRuleDto,
  FilterDto,
  RuleFilterApplyType,
  RuleFilterCustomerPropertyType,
} from "../../store/models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../../store/models/bonusRule/BonusRuleStatus";
import { BonusRuleType } from "../../store/models/bonusRule/BonusRuleType";
import { BonusRuleValueType } from "../../store/models/bonusRule/BonusRuleValueType";
import {
  CustomerInteractionType,
  formatCustomerInteractionType,
  isCustomInteractionType,
} from "../../store/models/bonusRule/CustomerInteractionType";
import { RuleRoundingMode } from "../../store/models/bonusRule/RuleRoundingMode";
import { ConfigGroupKey } from "../../store/models/config/ConfigGroupKey";
import { PageViewMode } from "../../store/models/PageViewMode";
import { FormDateTime } from "../atoms/FormDateTime";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { isBlank, nameof, ValidationErrors } from "../atoms/Utils";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";
import Filter from "./Filter";
import { getCurrentDateAsString } from "../atoms/Utils";
import { PurchaseTotalCondition } from "../../store/models/bonusRule/PurchaseTotalCondition";
import { BackdropProcessing } from "../app/BackdropProcessing";
import { useState } from "react";
import { FrequencyMode } from "../../store/models/bonusRule/FrequencyMode";

interface BonusRuleFormModel extends BonusRuleDto {
  customInteraction?: string;
  bonusCircleNumbers?: string[];
  bonusCircleGroupNumber?: string;
  valueTypeOrRoundingMode?: BonusRuleValueType | RuleRoundingMode;
}

interface BonusRuleFormProps {
  bonusRule: BonusRuleFormModel;
  viewMode: PageViewMode;
}

export const frequencyOptions = [
  { value: "", label: "---" },
  { value: FrequencyMode.DAY, label: "Tag" },
  { value: FrequencyMode.WEEK, label: "Woche" },
  { value: FrequencyMode.MONTH, label: "Monat" },
];

export const typeOptions = [
  { value: BonusRuleType.TRANSACTION, label: "Einkauf" },
  { value: BonusRuleType.INTERACTION, label: "Nutzeraktivität" },
];

const valueTypeOrRoundingModeOptions = [
  { value: BonusRuleValueType.FIX, label: "Fix" },
  { value: RuleRoundingMode.UP, label: "auf vollen Euro aufrunden" },
  { value: RuleRoundingMode.DOWN, label: "auf vollen Euro abrunden" },
  { value: RuleRoundingMode.MERCANTILE, label: "kaufmännisches Runden" },
  { value: BonusRuleValueType.PER_CENT, label: "pro Cent, ohne Aufrunden" },
];

const purchaseTotalConditionOptions = [
  { value: "", label: "---" },
  { value: PurchaseTotalCondition.GREATER, label: "größer als" },
  { value: PurchaseTotalCondition.LESS, label: "kleiner als" },
  { value: PurchaseTotalCondition.EQUAL, label: "gleich" },
];

export const interactionTypeOptions = [
  {
    value: CustomerInteractionType.REGISTER,
    label: formatCustomerInteractionType(CustomerInteractionType.REGISTER),
  },
  {
    value: CustomerInteractionType.ADD_ADDRESS,
    label: "Hinzufügen der Adresse",
  },
  {
    value: CustomerInteractionType.FAMILY_INVITATION,
    label: formatCustomerInteractionType(CustomerInteractionType.FAMILY_INVITATION),
  },
  {
    value: CustomerInteractionType.FRIEND_INVITATION,
    label: formatCustomerInteractionType(CustomerInteractionType.FRIEND_INVITATION),
  },
  {
    value: CustomerInteractionType.ADD_PREFERENCES,
    label: formatCustomerInteractionType(CustomerInteractionType.ADD_PREFERENCES),
  },
  {
    value: CustomerInteractionType.EMAIL_CORRESPONDENCE_ALLOWED,
    label: formatCustomerInteractionType(CustomerInteractionType.EMAIL_CORRESPONDENCE_ALLOWED) + " gegeben",
  },
  {
    value: CustomerInteractionType.CUSTOM,
    label: "Eigene Aktivität (ID erforderlich)",
  },
];

const validateForm = (values: BonusRuleFormModel) => {
  const errors: ValidationErrors<BonusRuleFormModel> = {};
  if (isBlank(values.ruleName)) {
    errors.ruleName = "Pflichtfeld";
  }
  if (isBlank(values.value)) {
    errors.value = "Pflichtfeld";
  }
  if (!values.validFrom) {
    errors.validFrom = "Pflichtfeld";
  }
  if (values.type === BonusRuleType.TRANSACTION && !values.unitNumbers?.length) {
    errors.unitNumbers = "Pflichtfeld";
  }
  if (!values.bonusCircleNumbers?.length) {
    errors.bonusCircleNumbers = "Pflichtfeld";
  }
  if (values.validFrom && values.validTill && values.validTill < values.validFrom) {
    errors.validTill = "Enddatum darf nicht vor dem Startdatum liegen.";
  }
  if (values.type === BonusRuleType.INTERACTION && !values.customerInteractionType) {
    errors.customerInteractionType = "Pflichtfeld";
  }
  if (values.customerInteractionType === CustomerInteractionType.CUSTOM && !values.customInteraction) {
    errors.customInteraction = "Pflichtfeld";
  }

  return errors;
};

const BonusRuleForm = (props: BonusRuleFormProps & ThunkProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState<boolean>(false);
  const [newRuleNumberInitial] = useState<string>(`NEW-RULE-${uuid()}`);

  const saveRule = async (ruleModel: BonusRuleFormModel) => {
    setProcessing(true);
    const rule = prepareRuleForSave(ruleModel);

    if (rule.ruleNumber) {
      await updateRule(rule);
    } else {
      await createRule(rule);
    }
    setProcessing(false);
  };

  const prepareRuleForSave = (ruleModel: BonusRuleFormModel) => {
    let rule = _.cloneDeep(ruleModel);
    delete rule.customInteraction;
    rule.status = BonusRuleStatus.DISABLED;

    setBonusCircleFilter(rule);
    setCustomerInteractionType(rule, ruleModel);
    setUnitNumber(rule);
    setValueTypeAndRoundingMode(rule);

    return rule;
  };

  const setBonusCircleFilter = (rule: BonusRuleFormModel) => {
    if (rule.bonusCircleNumbers?.length) {
      const bonusCircleRuleIndex = props.bonusRule.ruleFilters.findIndex(
        (filter) => filter.ruleFilterPropertyName === RuleFilterCustomerPropertyType.CIRCLE_NUMBER
      );

      if (bonusCircleRuleIndex !== -1) {
        rule.ruleFilters[bonusCircleRuleIndex].ruleFilterValues = rule.bonusCircleNumbers;
      } else {
        rule.ruleFilters.push(createNewBonusCircleFilter(rule.bonusCircleNumbers, rule.bonusCircleGroupNumber!));
      }

      delete rule.bonusCircleNumbers;
    }
  };

  const createNewBonusCircleFilter = (bonusCircleNumbers: string[], bonusCircleGroupNumber: string): FilterDto => {
    return {
      ruleFilterName: "CIRCLE-FILTER-" + uuid(),
      ruleFilterType: ConfigGroupKey.CUSTOMER_PROPERTY,
      ruleFilterPropertyName: RuleFilterCustomerPropertyType.CIRCLE_NUMBER,
      ruleFilterApplyType: RuleFilterApplyType.INCLUDE,
      ruleFilterValues: bonusCircleNumbers,
      ruleFilterPropertyGroupNumber: bonusCircleGroupNumber,
    };
  };

  const setCustomerInteractionType = (rule: BonusRuleFormModel, ruleModel: BonusRuleFormModel) => {
    if (rule.customerInteractionType === CustomerInteractionType.CUSTOM) {
      rule.customerInteractionType = ruleModel.customInteraction;
    }
  };

  const setUnitNumber = (rule: BonusRuleFormModel) => {
    if (rule.type === BonusRuleType.INTERACTION) {
      rule.unitNumbers = [
        props.operationalUnits.find((operationalUnit) => !operationalUnit.parentUnitNumber)!.unitNumber,
      ];
    }
  };

  const setValueTypeAndRoundingMode = (rule: BonusRuleFormModel) => {
    if (rule.valueTypeOrRoundingMode === BonusRuleValueType.FIX) {
      rule.valueType = BonusRuleValueType.FIX;
      rule.ruleRoundingMode = RuleRoundingMode.MERCANTILE;
    } else if (rule.valueTypeOrRoundingMode === BonusRuleValueType.PER_CENT) {
      rule.valueType = BonusRuleValueType.PER_CENT;
      rule.ruleRoundingMode = RuleRoundingMode.MERCANTILE;
    } else {
      rule.valueType = BonusRuleValueType.FACTOR;
      rule.ruleRoundingMode = rule.valueTypeOrRoundingMode as RuleRoundingMode;
    }

    delete rule.valueTypeOrRoundingMode;
  };

  const updateRule = async (rule: BonusRuleFormModel) => {
    rule.ruleFilters.forEach((filter) => {
      if (filter.ruleFilterValues && filter.ruleFilterPropertyName !== RuleFilterCustomerPropertyType.CIRCLE_NUMBER) {
        filter.ruleFilterValues = [];
      }
    });

    const success = await props.thunkUpdateBonusRule(rule);
    if (success) {
      navigate(UrlPathBonus);
    }
  };

  const createRule = async (rule: BonusRuleFormModel) => {
    const formData = new FormData();
    rule.ruleFilters.forEach((filter) => {
      if (filter.ruleFilterValues) {
        const csvBlob = new Blob([...filter.ruleFilterValues.join("\n")], {
          type: "text/csv",
        });
        if (!filter.ruleFilterNumber) {
          filter.ruleFilterNumber = uuid();
        }
        formData.append("files", csvBlob, `${filter.ruleFilterNumber}.csv`);
        filter.ruleFilterValues = [];
      }
    });
    rule.ruleNumber = newRuleNumberInitial;
    formData.append("data", JSON.stringify(rule));
    const success = await props.thunkAddBonusRuleWithFile(formData);
    if (success) {
      navigate(UrlPathBonus);
    }
  };

  const circleOptions = props.circles.map((circle) => ({
    value: circle.circleNumber,
    label: circle.circleName,
  }));

  const { validFrom, status } = props.bonusRule;
  const isRunning = validFrom && validFrom < getCurrentDateAsString();
  const isViewMode = props.viewMode === PageViewMode.VIEW;
  const isEnabled = status === BonusRuleStatus.ENABLED;

  const readOnly = isViewMode || isEnabled || isRunning === true;

  props.bonusRule.ruleFilters.forEach((filter) => {
    filter.ruleFilterValues = filter.ruleFilterValues || [];
  });

  const bonusCircleRule = props.bonusRule.ruleFilters.find(
    (filter) => filter.ruleFilterPropertyName === RuleFilterCustomerPropertyType.CIRCLE_NUMBER
  );

  props.bonusRule.bonusCircleNumbers = bonusCircleRule?.ruleFilterValues || [];
  props.bonusRule.bonusCircleGroupNumber = bonusCircleRule?.ruleFilterPropertyGroupNumber;

  if (
    props.bonusRule.valueType === BonusRuleValueType.FIX ||
    props.bonusRule.valueType === BonusRuleValueType.PER_CENT
  ) {
    props.bonusRule.valueTypeOrRoundingMode = props.bonusRule.valueType;
  } else {
    props.bonusRule.valueTypeOrRoundingMode = props.bonusRule.ruleRoundingMode;
  }

  if (isCustomInteractionType(props.bonusRule.customerInteractionType)) {
    props.bonusRule.customInteraction = props.bonusRule.customerInteractionType;
    props.bonusRule.customerInteractionType = CustomerInteractionType.CUSTOM;
  }

  return (
    <>
      <BackdropProcessing processing={processing}></BackdropProcessing>
      <Form
        onSubmit={saveRule}
        initialValues={props.bonusRule}
        validate={validateForm}
        render={({ handleSubmit, submitting, values }) => {
          if (values.type === BonusRuleType.INTERACTION) {
            values.valueTypeOrRoundingMode = BonusRuleValueType.FIX;
          } else {
            delete values.customerInteractionType;
          }
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      readOnly={readOnly}
                      type="text"
                      name={nameof<BonusRuleFormModel>("ruleName")}
                      label="Name"
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(14),
                }}
              >
                <ImsPaperHead text="Bonusregeldetails" />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        readOnly={readOnly}
                        label="Bonusregelart"
                        name={nameof<BonusRuleFormModel>("type")}
                        value={values.type}
                        options={typeOptions}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={12} style={{ marginBottom: theme.spacing(3) }}>
                      <b>Punktevergabe</b>
                    </Grid>
                    {values.type === BonusRuleType.TRANSACTION && (
                      <Grid item md={3}>
                        <FormSelect
                          readOnly={readOnly}
                          options={valueTypeOrRoundingModeOptions}
                          name={nameof<BonusRuleFormModel>("valueTypeOrRoundingMode")}
                          label="Punkte pro Transaktion"
                          fullWidth
                        />
                      </Grid>
                    )}
                    {values.type === BonusRuleType.INTERACTION && (
                      <Grid item md={3}>
                        <FormSelect
                          readOnly={readOnly}
                          name={nameof<BonusRuleFormModel>("customerInteractionType")}
                          label="Nutzeraktivität"
                          options={interactionTypeOptions}
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item container md={1} justifyContent="center" alignItems="center">
                      ergibt
                    </Grid>
                    <Grid item md={3}>
                      <FormInput
                        readOnly={readOnly}
                        type="number"
                        name={nameof<BonusRuleFormModel>("value")}
                        fullWidth
                        label="Anzahl Punkte"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={5} />
                    {values.customerInteractionType === CustomerInteractionType.CUSTOM && (
                      <>
                        <Grid item md={3} style={{ marginTop: theme.spacing(3) }}>
                          <FormInput
                            readOnly={readOnly}
                            type="text"
                            name={nameof<BonusRuleFormModel>("customInteraction")}
                            label="ID der Aktivität"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={9} />
                      </>
                    )}
                    <Grid item md={12} style={{ marginBottom: theme.spacing(3) }} />
                    <Grid item md={3}>
                      <FormInput
                        readOnly={readOnly}
                        type="number"
                        name={nameof<BonusRuleFormModel>("daysToDelay")}
                        label="Verzögerte Gutschrift in Tagen"
                        variant="outlined"
                        fullWidth
                        helperText="Optional"
                      />
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={3}>
                      <FormInput
                        readOnly={readOnly}
                        type="number"
                        name={nameof<BonusRuleFormModel>("budget")}
                        label="Gesamtes Punktebudget"
                        helperText="Optional"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={5} />
                    <Grid item md={12} style={{ marginBottom: theme.spacing(3) }} />
                    {values.type === BonusRuleType.TRANSACTION && (
                      <>
                        <Grid item md={3}>
                          <FormSelect
                            readOnly={readOnly}
                            options={purchaseTotalConditionOptions}
                            name={nameof<BonusRuleFormModel>("transactionSumType")}
                            label="Transaktionswert"
                            fullWidth
                            helperText="Optional"
                          />
                        </Grid>
                        <Grid item md={1} />
                        <Grid item md={3}>
                          <FormInput
                            readOnly={readOnly}
                            type="number"
                            name={nameof<BonusRuleFormModel>("transactionSumValue")}
                            label="Summe der Transaktion"
                            variant="outlined"
                            fullWidth
                            helperText="Optional"
                          />
                        </Grid>
                        <Grid item md={5} />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(14),
                }}
              >
                <ImsPaperHead text="Bonusregellaufzeit" />
                <Grid container spacing={4}>
                  <Grid item md={4}>
                    <FormDateTime
                      readOnly={readOnly}
                      name={nameof<BonusRuleFormModel>("validFrom")}
                      label="Startdatum"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <FormDateTime
                      readOnly={isViewMode}
                      name={nameof<BonusRuleFormModel>("validTill")}
                      label="Enddatum"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}></Grid>
                  <Grid item md={12}>
                    <b>Ausführungsfrequenz</b>
                  </Grid>
                  <Grid item md={3}>
                    <FormInput
                      readOnly={readOnly}
                      type="number"
                      name={nameof<BonusRuleFormModel>("frequency")}
                      label="Ausführung pro Nutzer"
                      helperText="Optional"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={1} />
                  <Grid item md={3}>
                    <FormSelect
                      readOnly={readOnly}
                      options={frequencyOptions}
                      name={nameof<BonusRuleFormModel>("frequencyMode")}
                      label="Pro"
                      fullWidth
                      // helperText="Optional"
                    />
                  </Grid>
                  <Grid item md={1} />
                  <Grid item md={3}>
                    <FormInput
                      readOnly={readOnly}
                      type="number"
                      name={nameof<BonusRuleFormModel>("frequencyMax")}
                      label="Maximal pro Bonusregel"
                      helperText="Optional"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={1} />
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text="Bonusregelfilter" />
                <Grid container spacing={4}>
                  <Grid container spacing={2} item>
                    {values.type === BonusRuleType.TRANSACTION && (
                      <>
                        <Grid item md={2}>
                          Filiale/-gruppe
                        </Grid>
                        <Grid item md={6}>
                          <FormSelect
                            readOnly={isViewMode}
                            name={nameof<BonusRuleFormModel>("unitNumbers")}
                            label="Filiale/-gruppe"
                            fullWidth
                            multiple
                            hierarchyLogic
                            initialValues={props.bonusRule ? props.bonusRule.unitNumbers : []}
                            options={getOperationalUnitOptions(undefined, props.operationalUnits, theme, true, false)}
                          />
                        </Grid>
                        <Grid item md={4} />
                      </>
                    )}

                    <Grid item md={2}>
                      Circles
                    </Grid>
                    <Grid item md={6}>
                      <FormSelect
                        readOnly={isViewMode}
                        fullWidth
                        multiple
                        name={nameof<BonusRuleFormModel>("bonusCircleNumbers")}
                        label="Circles"
                        options={circleOptions}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text="Artikelfilter" />
                <Grid container spacing={4}>
                  {values.type === BonusRuleType.TRANSACTION && <Filter bonusRule={values} viewMode={isViewMode} />}
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text="Bonusregelbeschreibung" />
                <Grid container spacing={4}>
                  <Grid container spacing={2} item>
                    <Grid item md={12}>
                      <FormInput
                        readOnly={readOnly}
                        multiline
                        type="textarea"
                        name={nameof<BonusRuleFormModel>("promotionText")}
                        label="Beschreibung"
                        helperText="Dieser Text erscheint in Endkunden-Frontends"
                        variant="outlined"
                        fullWidth
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => navigate(UrlPathBonus)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Abbrechen
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isViewMode || submitting}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Bonusregel {values.ruleNumber ? "speichern" : "anlegen"}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  circles: state.circles.circles,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkAddBonusRule, thunkAddBonusRuleWithFile, thunkUpdateBonusRule }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusRuleForm);
