import { AnyAction } from "redux";
import { INTERACTION_SEARCH } from "../actions/InteractionActions";
import { InteractionSearchResponseDto } from "../models/interactionSearch/InteractionSearchResponseDto";

export interface IInteractionSearchReducerState {
  interactions: InteractionSearchResponseDto[];
}

export const interactionSearch = (
  state: IInteractionSearchReducerState,
  action: AnyAction
): IInteractionSearchReducerState => {
  switch (action.type) {
    case INTERACTION_SEARCH:
      return {
        ...state,
        interactions: action.payload,
      };
    default:
      return state || {};
  }
};
