import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Field } from "react-final-form";
import { FormOption } from "./FormOption";
import { COLOR_BLACK, KNISTR_RED } from "./ImsMaterialTheme";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import { isFieldNeeded } from "./FieldValidation";

interface FormRadioButtonProps {
  value?: any;
  name: string;
  label?: string;
  description?: string;
  options: FormOption[];
  readOnly?: boolean;
  row?: boolean;
  fieldConfig?: FieldConfigDto[];
}

export const FormRadioButton = (props: FormRadioButtonProps) => {
  if (props.fieldConfig && !isFieldNeeded(props.name, props.fieldConfig)) return null;

  return (
    <Field name={props.name} type="radio" value={props.value}>
      {({ input, meta }) => {
        const hasErrors = meta.error && meta.touched;
        return (
          <FormControl component="fieldset" error={hasErrors}>
            {props.label && (
              <FormLabel
                component="legend"
                style={{
                  fontWeight: "bold",
                  paddingBottom: 16,
                  color: hasErrors ? KNISTR_RED : COLOR_BLACK,
                }}
              >
                {props.label}
                {props.description && <p style={{ fontWeight: "normal", marginBottom: 0 }}>{props.description}</p>}
              </FormLabel>
            )}
            <RadioGroup aria-label={props.label} defaultValue={props.value} row={props.row}>
              {props.options.map(({ label, value, disabled }) => (
                <FormControlLabel
                  disabled={props.readOnly || disabled}
                  key={value}
                  value={value}
                  control={<Radio color="primary" />}
                  label={label}
                  defaultChecked={value === input.value}
                  onChange={input.onChange}
                />
              ))}
            </RadioGroup>
            {hasErrors && <FormHelperText>{meta.error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};
