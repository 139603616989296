import { Button, Grid, Paper, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppLinkDto, useAppLinkPositionOptions } from "../../../store/models/appcontent/AppLinkDto";
import { IStore } from "../../../store/IStore";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkCreateAppContent, thunkUpdateAppContent } from "../../../store/actions/AppLinkActions";
import { connect, ConnectedProps } from "react-redux";
import { Form } from "react-final-form";
import { FormInput } from "../../atoms/FormInput";
import { nameof } from "../../atoms/Utils";
import ImsPaperHead from "../../atoms/ImsPaperHead";
import { FormSelect } from "../../atoms/FormSelect";
import { UrlPathAppContentLinks } from "../../../api/url";
import { useTranslation } from "react-i18next";

interface AppLinkFormProps {
  appLink: AppLinkDto;
}

const AppContentLinkListDetail = (props: AppLinkFormProps & ThunkProps) => {
  const theme = useTheme();
  const { appLink } = props;
  const options = useAppLinkPositionOptions();
  const { t } = useTranslation();
  const saveAppLink = async (appLinkToSave: AppLinkDto) => {
    let success;
    if (appLinkToSave.appLinkNumber) {
      success = await props.thunkUpdateAppContent(appLinkToSave);
    } else {
      success = await props.thunkCreateAppContent(appLinkToSave);
    }
    if (success) {
      goToListView();
    }
  };

  const navigate = useNavigate();

  const goToListView = () => {
    navigate(UrlPathAppContentLinks);
  };

  const allFieldsFilled = (values: any) => {
    return !(!values["url"] || !values["text"] || !values["position"]);
  };

  return (
    <Form
      onSubmit={saveAppLink}
      initialValues={appLink}
      render={({ handleSubmit, values }: { handleSubmit: any; values: any }) => (
        <form onSubmit={handleSubmit}>
          <Paper
            style={{
              padding: theme.spacing(3),
              marginTop: theme.spacing(6),
            }}
          >
            <ImsPaperHead text={t("appContentLinkCreate.headline")} />
            <Grid container spacing={4}>
              <Grid item container spacing={2}>
                <Grid item md={12}>
                  <b>{t("appContentLinkCreate.label")}</b>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item sm={4}>
                    <FormInput
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      name={nameof<AppLinkDto>("url")}
                      label={t("appContentLinkCreate.inputPlaceholderUrl")}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item sm={4}>
                    <FormInput
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      name={nameof<AppLinkDto>("text")}
                      label={t("appContentLinkCreate.inputPlaceholderText")}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item sm={4}>
                    <FormSelect
                      name={"position"}
                      fullWidth={true}
                      label={t("appContentLinkCreate.inputPlaceholderPosition")}
                      options={options}
                      testId="app-link-position"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={goToListView}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              {t("appContentLinkCreate.buttonCancel")}
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
              disabled={!allFieldsFilled(values)}
            >
              {t("appContentLinkCreate.buttonSave")}
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  applinks: state.appLinks.links,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCreateAppContent,
      thunkUpdateAppContent,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(AppContentLinkListDetail);
