import { AnyAction } from "redux";
import {
  CHANGE_APP_LINKS,
  CREATE_APP_LINK,
  DELETE_APP_LINK,
  GET_ALL_APP_LINKS,
  UPDATE_APP_LINK,
} from "../actions/AppLinkActions";
import { AppLinkDto } from "../models/appcontent/AppLinkDto";

export interface IAppContentReducerState {
  links: AppLinkDto[];
}

const sortLinks = (links: AppLinkDto[]): AppLinkDto[] => {
  return links.sort((a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  });
};
export const appLinks = (state: IAppContentReducerState, action: AnyAction): IAppContentReducerState => {
  const appLinkDto = action.payload as AppLinkDto;
  switch (action.type) {
    case GET_ALL_APP_LINKS:
    case CHANGE_APP_LINKS:
      return {
        ...state,
        links: sortLinks(action.payload),
      };
    case CREATE_APP_LINK:
      return {
        ...state,
        links: sortLinks([...state.links, action.payload]),
      };
    case UPDATE_APP_LINK: {
      return {
        ...state,
        links: sortLinks(
          state.links.map((appLink) => (appLink.appLinkNumber === appLinkDto.appLinkNumber ? appLinkDto : appLink))
        ),
      };
    }

    case DELETE_APP_LINK:
      return {
        ...state,
        links: sortLinks(state.links.filter((appContent) => appContent.appLinkNumber !== appLinkDto.appLinkNumber)),
      };
    default:
      return state || { links: [] };
  }
};
